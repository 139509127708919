import * as React from "react";

function LandingLeadIcon(props) {
  return (
    <svg
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_661_113)">
        <g clip-path="url(#clip1_661_113)">
          <path
            d="M39.2167 12.2325H26.8267L33.0217 6.03748L39.2167 12.2325ZM26.9055 50.8462H39.138L33.0217 56.9625L26.9055 50.8462ZM16.0117 16.4587L4.19922 46.5412H9.02922L11.4442 40.11H24.858L27.273 46.5412H32.103L20.3167 16.4587H16.0117ZM13.0455 35.805L18.138 22.2075L23.2305 35.805H13.0455ZM41.2905 42.3675H57.3555V46.5412H34.9642V43.155L50.5042 20.685H35.0692V16.485H56.8567V19.7925L41.2905 42.3675Z"
            fill="#795CB8"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_661_113">
          <rect width="63" height="63" fill="white" />
        </clipPath>
        <clipPath id="clip1_661_113">
          <rect width="63" height="63" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LandingLeadIcon;
