import { Box, Typography, Button, TextField } from "@mui/material";
import React from "react";
import SendIcon from "src/Images/SendIcon";
import { isMobile as deviceMobile } from "react-device-detect";

const MobileInput = ({
  messageInput,
  setMessageInput,
  sendMessage,
  keyboardOpen,
  keyboardHeight,
  isMobile,
}) => {
  const textFieldRef = React.useRef(null);
  return (
    <Box
      style={{
        marginBottom: keyboardOpen
          ? keyboardHeight - (isMobile ? 0 : 10)
          : isMobile
          ? 10
          : 0,
      }}
      sx={{
        width: "100%",
        height: 40,
        mt: 1,
        // mb: keyboardOpen ? 47 : 2,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          width: "100%",
          backgroundColor: "#F4F6FF",
          height: 40,
          paddingLeft: 15,
          paddingRight: 15,
          display: "flex",
          borderRadius: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          inputRef={textFieldRef}
          variant="standard"
          value={messageInput}
          onChange={(event) => setMessageInput(event.target.value)}
          sx={{
            width: "100%",
            fontSize: 14,
            "aria-label": "search google maps",
          }}
          inputProps={inputProps}
          InputProps={{
            disableUnderline: true, // <== added this
          }}
          placeholder="Write a new message..."
        />
      </Box>
      <Box width={10} />
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          sendMessage();
        }}
      >
        <SendIcon color={messageInput ? "#795CB8" : "#F1F1F1"} />
      </Box>
    </Box>
  );
};

const extendedInputProps = {
  style: {
    textAlign: "top",
    height: 266,
    fontSize: 14,
    color: "black",
    fontFamily: "Roboto",
  },
};

const inputProps = {
  style: {
    textAlign: "top",
    height: 50,
    fontSize: 14,
    color: "black",
    fontFamily: "Roboto",
  },
};

const DesktopInput = ({
  messageInput,
  setMessageInput,
  sendMessage,
  extended,
  setExtended,
}) => (
  <Box
    mt={2}
    style={{
      backgroundColor: "#F4F6FF",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      width: "100%",
      height: extended ? 300 : 50,
      borderRadius: extended ? 15 : 50,
      borderWidth: 1,
      paddingLeft: 20,
      position: "relative",
    }}
  >
    {extended ? (
      <TextField
        multiline
        variant="standard"
        value={messageInput}
        onChange={(event) => setMessageInput(event.target.value)}
        onKeyDown={(e) => {
          if (!extended) {
            if (e.key === "Enter") sendMessage();
          }
        }}
        sx={{
          width: "100%",
          fontSize: 14,
          "aria-label": "search google maps",
        }}
        inputProps={extendedInputProps}
        InputProps={{
          disableUnderline: true, // <== added this
        }}
        placeholder="Write a new message..."
      />
    ) : (
      <TextField
        variant="standard"
        value={messageInput}
        onChange={(event) => setMessageInput(event.target.value)}
        onKeyDown={(e) => {
          if (!extended) {
            if (e.key === "Enter") sendMessage();
          }
        }}
        sx={{
          width: "100%",
          fontSize: 14,
          "aria-label": "search google maps",
        }}
        inputProps={inputProps}
        InputProps={{
          disableUnderline: true, // <== added this
        }}
        placeholder="Write a new message..."
      />
    )}
    {/* <TextField
      multiline
      value={messageInput}
      onChange={(event) => setMessageInput(event.target.value)}
      inputProps={inputProps}
      onKeyDown={(e) => {
        if (!extended) {
          if (e.key === "Enter") sendMessage();
        }
      }}
      sx={{
        width: "100%",
        fontSize: 14,
        "aria-label": "search google maps",
      }}
      placeholder="Write a new message..."
    /> */}
    <Box sx={{ position: "absolute", bottom: 10, right: 50 }}>
      <Button
        onClick={() => setExtended(!extended)}
        border={1}
        borderColor="#CCC"
        style={{
          border: "1px solid #CCCCCC",
          borderRadius: 10,
          height: 25,
          pr: 2,
          pl: 2,
        }}
      >
        <Typography fontFamily="Outfit" fontWeight={600} fontSize={12}>
          {extended ? "Minimize" : "Expand"}
        </Typography>
      </Button>
    </Box>
    <Box
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        right: 10,
        width: 25,
      }}
      onClick={sendMessage}
    >
      <SendIcon color={messageInput ? "#795CB8" : "#BBB"} />
    </Box>
  </Box>
);

const MessageInput = ({
  isMobile,
  messageInput,
  setMessageInput,
  sendMessage,
  keyboardOpen,
  extended,
  setExtended,
  inputProps,
  keyboardHeight,
}) => {
  return (
    <div>
      {deviceMobile ? (
        <MobileInput
          messageInput={messageInput}
          setMessageInput={setMessageInput}
          sendMessage={sendMessage}
          keyboardOpen={keyboardOpen}
          keyboardHeight={keyboardHeight}
          isMobile={isMobile}
        />
      ) : (
        <DesktopInput
          messageInput={messageInput}
          setMessageInput={setMessageInput}
          sendMessage={sendMessage}
          extended={extended}
          setExtended={setExtended}
          inputProps={inputProps}
        />
      )}
    </div>
  );
};
export default MessageInput;
