import * as React from "react";

function ReviewIcon(props) {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2222 1.81333H10.5067C10.1333 0.7616 9.15556 0 8 0C6.84444 0 5.86667 0.7616 5.49333 1.81333H1.77778C0.8 1.81333 0 2.62933 0 3.62667V16.32C0 17.3173 0.8 18.1333 1.77778 18.1333H14.2222C15.2 18.1333 16 17.3173 16 16.32V3.62667C16 2.62933 15.2 1.81333 14.2222 1.81333ZM8 1.81333C8.48889 1.81333 8.88889 2.22133 8.88889 2.72C8.88889 3.21867 8.48889 3.62667 8 3.62667C7.51111 3.62667 7.11111 3.21867 7.11111 2.72C7.11111 2.22133 7.51111 1.81333 8 1.81333ZM9.77778 14.5067H3.55556V12.6933H9.77778V14.5067ZM12.4444 10.88H3.55556V9.06667H12.4444V10.88ZM12.4444 7.25333H3.55556V5.44H12.4444V7.25333Z"
        fill={props.color}
      />
    </svg>
  );
}

export default ReviewIcon;
