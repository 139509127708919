import React, { useEffect, useState } from "react";
import "./App.css";
import { Box, ThemeProvider, createTheme, Collapse } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { Auth, API, graphqlOperation } from "aws-amplify";
import "typeface-inter";
import TestingOffer from "./TestingOffer";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import AdvertiserSpace from "./AdvertiserSpace";
import NavigationBar from "./components/NavigationBar";
import { getUser, listNegotiations } from "./graphql/queries";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { deleteNegotiation } from "./graphql/mutations";
import { isMobile as deviceMobile } from "react-device-detect";

const theme = createTheme({
  overflow: "hidden",
  typography: {
    fontFamily: "inter",
  },
});

const TestingSpace = ({ isMobile }) => {
  const history = useHistory();
  const location = useLocation();

  const [currentUser, setCurrentUser] = useState(null);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [sideBarItemSelected, setSideBar] = useState("New Leads");
  const [showSideBar, setShowSideBar] = useState(false);

  useEffect(() => {
    setShowSideBar(false);
  }, [sideBarItemSelected]);

  useEffect(() => {
    const randomFunc = async () => {
      const u = await Auth.currentAuthenticatedUser();
      API.graphql({
        query: listNegotiations,
        variables: { filter: { brand: { eq: u.attributes.sub } } },
      }).then((response) => {
        console.log(response);
      });
    };
    const redirect = async () => {
      console.log(window.location.origin);
      const queryString = new URLSearchParams(location.search);
      const param1 = queryString.get("cs");
      if (param1) {
        if (currentUser != null) {
          if (currentUser?.isBrand) {
            history.replace(window.location.pathname);
            return;
          }
          history.replace(window.location.pathname);
          // window.history.replaceState(null, "", newUrl);
          setSideBarItemSelected("Creator Space");
        }
      }
    };
    randomFunc();
    redirect();
    console.log(currentUser);
    if (currentUser) {
      if (!currentUser.isBrand) {
        if (currentUser.stripeId == null) {
          console.log(currentUser);
          // updateUserStripeId();
        }
      }
    }
  }, [currentUser]);

  const setSideBarItemSelected = (item) => {
    setSideBar(item);
    setCurrentChannel(null);
  };

  useEffect(() => {
    const checkCurrentUser = async () => {
      try {
        console.log("Checking user");
        const user = await Auth.currentAuthenticatedUser();

        API.graphql({
          query: getUser,
          variables: { id: user.attributes.sub },
        })
          .then((response) => {
            const returnUser = response.data.getUser;
            console.log(returnUser);
            setCurrentUser(returnUser);
            if (returnUser === null) {
              history.push("/onboarding");
            }
          })
          .catch((error) => {
            history.push("/onboarding");
          });

        return true;
      } catch (_error) {
        doLogout();
        history.push("/login");
      }
      return false;
    };

    checkCurrentUser();
    if (history.location.state?.sideBar) {
      setSideBarItemSelected(history.location.state.sideBar);
    }
  }, [sideBarItemSelected, history.location.state?.sideBar, history]);

  const doSelectChannel = (channel) => {
    setCurrentChannel(null);
    setCurrentChannel(channel);
  };

  const EndNegotiation = async (id) => {
    const input = {
      id: id,
    };

    try {
      const response = await API.graphql(
        graphqlOperation(deleteNegotiation, { input })
      );
      console.log(response.data.deleteNegotiation);
      setCurrentChannel(null);
    } catch (error) {
      console.log(error);
    }
  };

  // Logout function
  const doLogout = async () => {
    // Logout
    try {
      var currentUser = await Auth.currentAuthenticatedUser();
      if (currentUser) {
        try {
          setCurrentUser(null);

          window.localStorage.clear();
          console.log("clearing local storage");
          await Auth.signOut();
          history.push("/login");

          // Force useEffect execution to redirect back to Login
          return true;
        } catch (error) {
          alert(error);
          return false;
        }
      }
    } catch {
      history.push("/login");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        style={{
          height: "100%",
          minHeight: 650,
          minWidth: isMobile ? "100%" : 550,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flex: 1,
          overflow: "hidden",
        }}
      >
        {(true || showSideBar) && (
          <Collapse
            in={
              !deviceMobile ||
              (!isMobile && deviceMobile && currentChannel == null) ||
              showSideBar
            }
            orientation={"horizontal"}
          >
            <NavigationBar
              isMobile={isMobile}
              sideBarItemSelected={sideBarItemSelected}
              setSideBarItemSelected={setSideBarItemSelected}
              setCurrentChannel={setCurrentChannel}
              currentUser={currentUser}
              doLogout={doLogout}
            />
          </Collapse>
        )}
        <Box
          style={{
            flex: 1,
            height: "100%",
            overflowY: "scroll",
            padding: isMobile ? 15 : 30,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            touchAction: "none",
          }}
        >
          {showSideBar && isMobile && (
            <Box
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                zIndex: 5,
              }}
              onClick={(e) => {
                e.preventDefault();
                setShowSideBar(false);
              }}
            />
          )}
          <Box
            display={"flex"}
            flexDirection={"column"}
            position={"relative"}
            style={{
              width: "100%",
              height: "100%",
              touchAction: "none",
            }}
          >
            <AnimateSharedLayout>
              <AnimatePresence exitBeforeEnter>
                {currentChannel ? (
                  <motion.div
                    key="home"
                    initial={{ x: 300 }}
                    animate={{ x: 0 }}
                    exit={{ x: "100%" }}
                    style={{
                      display: "flex",
                      flex: 1,
                    }}
                    transition={{ ease: "easeIn", duration: 0.2 }}
                  >
                    <TestingOffer
                      isMobile={isMobile}
                      currentUser={currentUser}
                      currentOffer={currentChannel}
                      setCurrentChannel={setCurrentChannel}
                      EndNegotiation={EndNegotiation}
                    />
                  </motion.div>
                ) : sideBarItemSelected === "Settings" ? (
                  <Settings
                    isMobile={isMobile}
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                    showSideBar={showSideBar}
                    setShowSideBar={setShowSideBar}
                  />
                ) : sideBarItemSelected === "Creator Space" ? (
                  <AdvertiserSpace
                    currentUser={currentUser}
                    isMobile={isMobile}
                    setShowSideBar={setShowSideBar}
                    showSideBar={showSideBar}
                  />
                ) : (
                  <Dashboard
                    showSideBar={showSideBar}
                    setShowSideBar={setShowSideBar}
                    setSideBarItemSelected={setSideBarItemSelected}
                    sideBarItemSelected={sideBarItemSelected}
                    currentUser={currentUser}
                    isMobile={isMobile}
                    advertiser={"I am a Customer"}
                    doSelectChannel={doSelectChannel}
                    EndNegotiation={EndNegotiation}
                  />
                )}
              </AnimatePresence>
            </AnimateSharedLayout>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
export default TestingSpace;
