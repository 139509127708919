import { Box } from "@mui/material";
import LogoSVG from "src/Images/Logo";
import LogoNoTextSVG from "src/Images/LogoNoText";
import backarrow from "src/Images/backarrow.png";
import Youtube from "react-youtube";

const LearnMoreScreen = ({ setLocation, isMobile }) => {
  const videoId = "vfbN-4FgrW8";
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: isMobile ? "100vh" : "100%",
        width: isMobile ? "100vw" : "100%",
      }}
    >
      <Box
        style={{
          backgroundColor: "#e9ecf0",
          width: "100%",
          height: "7%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            position: "absolute",
            width: "100%",
            height: "18%",
            overflow: "hidden",
          }}
        >
          <LogoNoTextSVG
            width={500}
            height={500}
            viewBox={"50 535 476 227"}
            style={{ position: "absolute" }}
          />
        </Box>
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box width={18} />
          <Box
            onClick={() => setLocation("Home")}
            style={{
              cursor: "pointer",
              zIndex: 2,
              height: 35,
              width: 35,
              borderRadius: 1000,
              backgroundColor: "#FCFCFC",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img style={{ height: 25, width: 25 }} src={backarrow} alt="" />
          </Box>
          <Box flex={1} />
          <LogoSVG width={150} height={50} />
        </Box>
      </Box>
      <Box height={35} />
      <Box
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Youtube videoId={videoId} />
      </Box>
      <Box height={35} />
      <Box height={"7%"} />
    </Box>
  );
};
export default LearnMoreScreen;
