import { Box, Typography, TextField, InputAdornment } from "@mui/material";
import UserInfo from "./UserInfo";
import { Search } from "@mui/icons-material";
import MenuIcon from "../Images/menu.png";

const MobileTopBar = ({
  showSideBar,
  toggleSideBar,
  sideBarItemSelected,
  expandSearch,
  setExpandSearch,
  searchTerm,
  handleChanges,
  companyFieldProps,
}) => (
  <>
    <img
      onClick={toggleSideBar}
      style={{
        width: 30,
        height: 30,
      }}
      src={MenuIcon ?? ""}
      alt=""
      loading="lazy"
    />
    <Box width={30} />
    {!expandSearch && (
      <Typography
        style={{
          cursor: "default",
          fontWeight: 600,
          fontSize: 20,
          fontFamily: "Outfit",
          color: "#000",
          lineHeight: "110%",
          paddingRight: 10,
          paddingLeft: 10,
          width: 600,
        }}
      >
        {sideBarItemSelected}
      </Typography>
    )}
    {!expandSearch && (
      <Search
        onClick={() => setExpandSearch(true)}
        style={{ color: "#4D4D4D", width: 30, height: 30 }}
      />
    )}
    {expandSearch && (
      <TextField
        autoFocus
        onBlur={() => setExpandSearch(false)}
        value={searchTerm}
        inputProps={companyFieldProps}
        onChange={handleChanges}
        InputProps={{
          style: { borderRadius: 10, backgroundColor: "#FBFBFB" },
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    )}
  </>
);

const TopBar = ({
  isMobile,
  showSideBar,
  toggleSideBar,
  sideBarItemSelected,
  expandSearch,
  setExpandSearch,
  searchTerm,
  handleChanges,
  companyFieldProps,
  currentUser,
  profilePic,
  setSideBarItemSelected,
}) => (
  <Box
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }}
  >
    {isMobile ? (
      <MobileTopBar
        showSideBar={showSideBar}
        toggleSideBar={toggleSideBar}
        sideBarItemSelected={sideBarItemSelected}
        expandSearch={expandSearch}
        setExpandSearch={setExpandSearch}
        searchTerm={searchTerm}
        handleChanges={handleChanges}
        companyFieldProps={companyFieldProps}
      />
    ) : (
      <>
        <TextField
          autoFocus
          onBlur={() => setExpandSearch(false)}
          value={searchTerm}
          inputProps={companyFieldProps}
          onChange={handleChanges}
          InputProps={{
            style: { borderRadius: 10, backgroundColor: "#FBFBFB" },
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Box style={{ flex: 1, minWidth: 20 }} />
        <UserInfo
          username={currentUser?.username}
          userType={currentUser?.isBrand ? "Brand" : "Creator"}
          onClick={() => setSideBarItemSelected("Settings")}
          profilePic={profilePic}
        />
      </>
    )}
  </Box>
);

export default TopBar;
