import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { Login } from "./Login";
import { useState, useEffect } from "react";
import TestingSpace from "./TestingSpace";
import FormTemplate from "./Form";
import Onboarding from "./Screens/Onboarding";
import ResetPassword from "./Screens/ResetPassword";
import LandingPage from "./Screens/LandingPage";
import { Device } from "@capacitor/device";

import { Amplify, Auth, Hub } from "aws-amplify";
// import { withAuthenticator } from "@aws-amplify/ui-react";
import awsExports from "./aws-exports";
import StripeSuccess from "./Screens/StripeSuccess";
import Echo from "./SafeAreaPlugin";
Amplify.configure(awsExports);

document.body.style.overflow = "hidden";

window.addEventListener("scroll", (e) => {
  e.preventDefault();
  window.scrollTo(0, 0);
});

function App() {
  const history = useHistory();

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [signedIn, setSignedIn] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [topPadding, setTopPadding] = useState(0);

  useEffect(() => {
    const fetchDeviceInfo = async () => {
      try {
        const { top, bottom, left, right } = await Echo.getSafeAreaInsets();
        setTopPadding(top);
      } catch (e) {
        console.log(e);
      }

      const info = await Device.getInfo();
      setIsIOS(info.platform === "ios");
    };

    fetchDeviceInfo();
  }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  Hub.listen("auth", (data) => {
    const { payload } = data;
    if (payload.event === "signIn") {
      console.log("User has signed in!", payload.data);
    }
  });

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    checkSignedIn();
  });

  const checkSignedIn = async () => {
    console.log("checking signed in");
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log(user);
      console.log(user);
      setSignedIn(true);
    } catch (error) {
      console.log(error);
      setSignedIn(false);
    }
  };

  const logInUser = async () => {
    console.log("activate");
    setSignedIn(true);
  };

  const isMobile = width <= 768;

  return (
    // This app has two different screens, so we use react-router-dom
    // to manage basic routing
    <Router>
      <div
        className="App"
        style={{
          paddingTop: topPadding,
          paddingBottom: isIOS ? "env(safe-area-inset-bottom, 0px)" : "0px",
          overflow: "hidden",
          width: "100vw",
          height: height,
          position: "fixed",
        }}
      >
        <Switch>
          <Route path="/f">
            <FormTemplate isMobile={isMobile} />
          </Route>
          <Route path="/success">
            <StripeSuccess />
          </Route>
          <Route path="/reset">
            <ResetPassword isMobile={isMobile} />
          </Route>
          <Route path="/login">
            <Login isMobile={isMobile} loginUser={logInUser} />
          </Route>
          <Route path="/onboarding">
            <Onboarding isMobile={isMobile} height={height} />
          </Route>
          <Route path="/">
            {signedIn ? (
              <TestingSpace isMobile={isMobile} />
            ) : (
              <LandingPage isMobile={isMobile} />
            )}
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
