import { Box, Typography } from "@mui/material";

const Message = ({ mine, time, username, text }) => (
  <Box
    border={mine ? 0 : 0}
    style={{
      display: "flex",
      flexDirection: "column",
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 5,
      paddingBottom: 5,
      borderColor: "#CCC",
      backgroundColor: mine ? "#1A3A63" : "#F6F6F6",
      borderRadius: 25,
      maxWidth: "80%",
    }}
  >
    <Box display="inline-flex" alignItems="baseline">
      {!mine && (
        <Typography fontSize={14} fontWeight={600} fontFamily={"Roboto"}>
          {username}
        </Typography>
      )}
      {!mine && <Box width={12} />}
      {/* <Typography
        fontSize={12}
        fontFamily={"Outfit"}
        color={mine ? "#fff" : "#AFAFAF"}
      >
        {time}
      </Typography> */}
    </Box>
    <Box height={5} />
    <Typography
      component="pre"
      style={{ whiteSpace: "pre-wrap" }}
      fontWeight={400}
      fontFamily={"Roboto"}
      color={mine ? "#FFF" : "#4D4D4D"}
    >
      {text}
    </Typography>
  </Box>
);
export default Message;
