import { useState, useEffect, useRef } from "react";
import "./App.css";
import { Spin } from "antd";
import { Auth, API, graphqlOperation, Storage } from "aws-amplify";
import { Box, Typography } from "@mui/material";
import { madeUpdate, updateLastUpdated } from "./Network/Queries";
import moment from "moment";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import ChatBubble from "./components/ChatBubble/ChatBubble";
import MessageInput from "./components/MessageInput";
import { LoadingButton } from "@mui/lab";
import { createMessage } from "./graphql/mutations";
import {
  getUser,
  listMessages,
  listNegotiations,
  messagesByDate,
} from "./graphql/queries";
import { updateNegotiation } from "./graphql/mutations";
import * as subscriptions from "./graphql/subscriptions";

moment.relativeTimeThreshold("s", 60);
moment.relativeTimeThreshold("m", 60);
moment.relativeTimeThreshold("h", 24);
moment.relativeTimeThreshold("d", 31);
moment.relativeTimeThreshold("M", 12);

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

export const MessageList = (props) => {
  // State variable to hold input values and flags
  const [messageInput, setMessageInput] = useState("");
  const messagesEndRef = useRef(null);
  const [profilePic, setProfilePic] = useState(null);
  const [extended, setExtended] = useState(false);
  const [temporaryMessages, setTemporaryMessages] = useState([]);
  const keyboardOpen = useDetectKeyboardOpen();
  const [resolvedPointers, setResolvedResults] = useState([]);
  const [otherUsername, setOtherUsername] = useState("");
  const [limitValue, setLimitValue] = useState(5);
  const [extraMessages, setExtraMessages] = useState([]);
  const [justATest, setJustATest] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [results, setResults] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const sub = API.graphql(
      graphqlOperation(subscriptions.onCreateMessage)
    ).subscribe({
      next: ({ provider, value }) => getMessages(),
      error: (error) => console.warn(error),
    });

    return () => {
      console.log("unsubscribe");
      // Unsubscribe when the component is unmounted
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (keyboardOpen) {
      setKeyboardHeight(
        Math.abs(window.innerHeight - document.documentElement.clientHeight)
      );
    } else {
      setKeyboardHeight(0);
    }
  }, [keyboardOpen]);

  const findProfilePic = async () => {
    const uu = props.currentUser.isBrand
      ? props.currentChannel?.creator
      : props.currentChannel?.brand;

    const response = await API.graphql({
      query: getUser,
      variables: { id: uu },
    });

    const profilePic = response.data.getUser.profilePic;

    if (profilePic != null) {
      const url = await Storage.get(profilePic.key, { key: profilePic.bucket });
      setProfilePic(url);
    }

    // if (props.currentUser.isBrand) {
    //   const u = await props.currentChannel?.get("Advertiser");
    //   const file = await u.get("ProfilePic");
    //   if (file) setProfilePic(file._url);
    //   setOtherUsername(u.get("username"));
    // } else {
    //   const u = await props.currentChannel?.get("owner");
    //   const file = await u.get("ProfilePic");
    //   if (file) setProfilePic(file._url);
    //   setOtherUsername(u.get("username"));
    // }
  };

  const inputProps = {
    style: {
      textAlign: "top",
      height: extended ? 266 : 50,
      fontSize: 14,
      color: "#858585",
      fontFamily: "Roboto",
    },
  };

  useEffect(() => {
    if (props.currentUser) {
      findProfilePic();
    }
  });

  useEffect(() => {
    const findUser = async () => {
      const u = await Auth.currentAuthenticatedUser();
      console.log(u.username);
    };
    findUser();
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Create parse query for live querying using useParseQuery hook
  // Note that Parse.Object coming from props need to be changed into pointers
  // to be able to be used by Parse, since React changes the object structure
  // when passing down parameters to child components
  // const parseQuery = new Parse.Query("Message");
  // // Get messages that involve both nicknames
  // parseQuery.equalTo("channel", props.currentChannel.toPointer());
  // // Set results ordering
  // parseQuery.descending("createdAt");
  // // Include nickname fields, to enable name getting on list
  // parseQuery.includeAll();

  // parseQuery.limit(25);

  // // Declare hook and variables to hold hook responses
  // const { isLoading, results } = useParseQuery(parseQuery, {
  //   enableLocalDatastore: false, // Enables cache in local datastore (default: true)
  //   enableLiveQuery: true, // Enables live query for real-time update (default: true)
  // });

  useEffect(() => {
    if (extraMessages.length == 0 && !loadingMessages) scrollToBottom();
  });

  const loadMoreResults = () => {
    setLoadingMessages(true);

    API.graphql({
      query: messagesByDate,
      variables: {
        type: "Message",
        sortDirection: "DESC",
        limit: 10,
        filter: { negotiationId: { eq: props.currentChannel.id } },
        nextToken: nextToken,
      },
    }).then(async (response) => {
      console.log(response);
      setNextToken(response.data.messagesByDate.nextToken);
      const vals = response.data.messagesByDate.items;
      await resolvePointers(
        vals.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
        setJustATest,
        true
      );
    });

    // console.log("WUUNEFUNEF");
    // const parseQuery = new Parse.Query("Message");
    // // Get messages that involve both nicknames
    // parseQuery.equalTo("channel", props.currentChannel.toPointer());
    // // Set results ordering
    // parseQuery.descending("createdAt");
    // // Include nickname fields, to enable name getting on list
    // parseQuery.includeAll();

    // parseQuery.limit(5).skip(25 + extraMessages.length * 5);

    // parseQuery.find().then(async (objects) => {
    //   console.log("there are object " + objects.length);
    //   await resolvePointers(objects, setJustATest, true);
    // }, 0);
  };

  const areDatesWithin30Minutes = (date1, date2) => {
    const momentDate1 = moment(date1);
    const momentDate2 = moment(date2);

    const differenceInMinutes = Math.abs(
      momentDate1.diff(momentDate2, "minutes")
    );
    return differenceInMinutes <= 30;
  };

  useEffect(() => {
    if (justATest != null) {
      console.log("lets try");
      setExtraMessages([justATest, ...extraMessages]);
      setJustATest(null);
      setLoadingMessages(false);
    }
  }, [justATest]);

  const resolvePointers = async (toResolve, setList, resolveFromExtra) => {
    const u = await Auth.currentAuthenticatedUser();
    const resolved = await Promise.all(
      toResolve.map(async (result, idx) => {
        var message = await result.content;
        var mine = u.username === result.userId;

        const date1 = moment(result.createdAt);

        if (idx !== 0) {
        }

        console.log(toResolve.length);

        var showDateLine =
          idx === 0
            ? true
            : areDatesWithin30Minutes(date1, toResolve[idx - 1].createdAt)
            ? false
            : true;

        console.log("showline " + showDateLine + result.content);

        if (resolveFromExtra && idx === 0) {
          if (extraMessages.length >= 1) {
            if (areDatesWithin30Minutes(date1, extraMessages[0][0].date1)) {
              extraMessages[0][0].showDateLine = false;
            }
          } else {
            if (
              areDatesWithin30Minutes(
                date1,
                results[results.length - 1].createdAt
              )
            ) {
              resolvedPointers[0].showDateLine = false;
              console.log("here " + results[0]);
            }
          }
        }

        return {
          showDateLine: showDateLine,
          date1: date1,
          mine: mine,
          messageText: message,
        };
      })
    );
    setList(resolved);
    console.log(resolved);
  };

  const getMessages = async () => {
    if (props.currentUser.isBrand) {
      const input = {
        id: props.currentChannel.id,
        brandLastSeen: moment(moment.now()),
      };

      const updateResponse = await API.graphql(
        graphqlOperation(updateNegotiation, { input })
      );
    } else {
      const input = {
        id: props.currentChannel.id,
        creatorLastSeen: moment(moment.now()),
      };

      const updateResponse = await API.graphql(
        graphqlOperation(updateNegotiation, { input })
      );
    }

    console.log("call");
    API.graphql({
      query: messagesByDate,
      variables: {
        type: "Message",
        sortDirection: "DESC",
        limit: 25,
        filter: { negotiationId: { eq: props.currentChannel.id } },
      },
    }).then((response) => {
      console.log(response);
      setNextToken(response.data.messagesByDate.nextToken);
      const vals = response.data.messagesByDate.items;
      setResults(
        vals.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      );
    });
  };

  useEffect(() => {
    getMessages();
  }, []);

  useEffect(() => {
    console.log("extra " + extraMessages);

    if (results.length > 0) {
      console.log(results);
      resolvePointers(results, setResolvedResults, false);
    }
  }, [results]);

  const RenderMoreMessage = ({ result }) => {
    console.log(result);
    if (result == null) return <></>;
    return (
      <>
        {result.length > 0 ? (
          result.map((resultItem, idxx) => {
            console.log(props.currentUser);
            if (props.currentUser) {
              console.log("item " + idxx);
              return (
                <ChatBubble
                  key={idxx}
                  otherUsername={otherUsername}
                  showDateLine={resultItem.showDateLine}
                  currentUser={props.currentUser}
                  date1={resultItem.date1}
                  messageText={resultItem.messageText}
                  profilePic={profilePic}
                  mine={resultItem.mine}
                />
              );
            }
            // }
          })
        ) : (
          <></>
        )}
      </>
    );
  };

  // Message sender function
  const sendMessage = async () => {
    setLimitValue(limitValue + 1);
    if (messageInput === "") return;

    const u = await Auth.currentAuthenticatedUser();

    try {
      const newMessage = {
        showDateLine: false,
        date1: moment(moment.now()),
        mine: true,
        messageText: messageInput,
      };

      const messageText = messageInput;
      const updatedMessages = [...resolvedPointers, newMessage];
      setResolvedResults(updatedMessages);
      console.log(temporaryMessages);
      // Create new Message object, set parameters and save it
      // const Message = new Parse.Object("Message");
      // Message.set("text", messageText);

      const inputData = {
        input: {
          negotiationId: props.currentChannel.id,
          userId: u.username,
          content: messageText,
          type: "Message",
        },
      };

      // Clear input
      setMessageInput("");

      const upInput = {
        id: props.currentChannel.id,
        lastUpdate: moment(moment.now()),
      };

      const updateResponse = await API.graphql({
        query: updateNegotiation,
        variables: { input: upInput },
      });

      console.log(updateResponse);

      API.graphql({
        query: createMessage,
        variables: inputData,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });

      // Message.set("channel", props.currentChannel.toPointer());
      // Message.set("user", props.currentUser.toPointer());
      // Message.set("ownerID", props.currentUser.id);

      // if (props.currentChannel.get("Advertiser").id === props.currentUser.id) {
      //   const Offer = new Parse.Object("Channel");
      //   Offer.set("objectId", props.currentChannel.id);
      //   Offer.set("AdvertiserFlag", true);
      //   try {
      //     await Offer.save();
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }
      // await Message.save();

      // props.updateUserViewed();
      // madeUpdate(props.currentChannel.id);
      // updateLastUpdated(props.currentChannel.id, "Channel");
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        flex: 1,
        height: "100%",
        flexDirection: "column",
      }}
    >
      {/* {isLoading && <Spin />} */}
      {/* Messages */}
      <Box
        sx={{ boxShadow: "none" }}
        style={{
          height: 0,
          flexGrow: 1,
          overflowY: "auto",
          paddingRight: props.isMobile ? 0 : 10,
          paddingLeft: props.isMobile ? 0 : 10,
        }}
      >
        {results && (
          <ul
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            {nextToken && results.length > 20 && (
              <LoadingButton
                loading={loadingMessages}
                onClick={() => loadMoreResults()}
                style={{
                  width: "100%",
                  height: 30,
                  backgroundColor: "#EEE",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 10,
                  cursor: "pointer",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "#4D4D4D",
                  }}
                >
                  {loadingMessages ? "" : "Load more"}
                </Typography>
              </LoadingButton>
            )}

            {extraMessages.map((result) => {
              return <RenderMoreMessage result={result} />;
            })}

            {resolvedPointers.length > 0 ? (
              resolvedPointers
                .sort((a, b) => a.date1 - b.date1)
                .map((result, idx) => {
                  // var otherUser = result.get("user");
                  // console.log(otherUser);
                  // console.log(otherUser.__type);
                  // if (otherUser.__type == "Pointer") {
                  //   return <>We know its here</>;
                  // } else if (
                  //   !props.currentUser ||
                  //   !otherUser?.get("username")
                  // ) {
                  //   return <></>;
                  // } else {
                  //   const mine =
                  //     props.currentUser?.getUsername() ===
                  //     otherUser?.get("username");

                  //   const date1 = moment(result.createdAt);

                  //   const showDateLine =
                  //     idx === 0
                  //       ? true
                  //       : date1.isSame(
                  //           moment(results[idx - 1].createdAt),
                  //           "day"
                  //         )
                  //       ? false
                  //       : true;
                  if (props.currentUser) {
                    return (
                      <ChatBubble
                        key={idx}
                        otherUsername={otherUsername}
                        showDateLine={result.showDateLine}
                        currentUser={props.currentUser}
                        date1={result.date1}
                        messageText={result.messageText}
                        profilePic={profilePic}
                        mine={result.mine}
                      />
                    );
                  }
                  // }
                })
            ) : (
              <li></li>
            )}
            {temporaryMessages.length > 0 &&
              temporaryMessages.map((tempMessage, idx) => {
                if (!props.currentUser) {
                  console.log("We are showing a temp message");
                  return (
                    <ChatBubble
                      key={idx}
                      otherUsername={otherUsername}
                      showDateLine={false}
                      currentUser={props.currentUser}
                      date1={moment(moment.now())}
                      messageText={tempMessage}
                      profilePic={""}
                      mine={true}
                    />
                  );
                }
              })}
          </ul>
        )}
        <div ref={messagesEndRef} />
      </Box>
      {/* Actions */}
      <MessageInput
        isMobile={props.isMobile}
        messageInput={messageInput}
        setMessageInput={setMessageInput}
        sendMessage={sendMessage}
        keyboardOpen={keyboardOpen}
        keyboardHeight={keyboardHeight}
        extended={extended}
        setExtended={setExtended}
        inputProps={inputProps}
      />
      <style>{`
        /* Hide scrollbar for all elements with the default WebKit scrollbar style */
        ::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </Box>
  );
};
