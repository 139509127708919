import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import { updateNegotiation } from "src/graphql/mutations";
import { Auth } from "aws-amplify";
import PaymentIcon from "src/Images/PaymentIcon";
import DeliverbleIcon from "src/Images/DeliverableIcon";

const ReviewSignContract = ({ setNavigation, id, currentOffer, isMobile }) => {
  const [textFields] = useState(currentOffer.requirements);
  const [additionalNotes] = useState(currentOffer.additionalNotes);
  const [payment] = useState(currentOffer.payment);
  const [currentUser, setCurrentUser] = useState(null);
  const [openResponse, setOpenResponse] = useState(false);

  const acceptOffer = async () => {
    const input = {
      id: currentOffer.id,
      contractState: "Accepted",
      lastUpdate: moment(moment.now()),
    };

    API.graphql(graphqlOperation(updateNegotiation, { input: input }))
      .then((response) => {
        console.log(response);
        setNavigation("main");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const declineOffer = async () => {
    const input = {
      id: currentOffer.id,
      contractState: "Editing",
      lastUpdate: moment(moment.now()),
    };

    API.graphql(graphqlOperation(updateNegotiation, { input: input }))
      .then((response) => {
        console.log(response);
        setNavigation("main");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const positiveAction = async () => {
    if (disabledButton()) {
      return;
    }
    if (!openResponse) {
      setOpenResponse(true);
    } else {
      acceptOffer();
    }
  };

  const negativeAction = async () => {
    if (!openResponse) {
      setNavigation("main");
    } else {
      declineOffer();
    }
  };

  const disabledButton = () => {
    if (currentUser == null) {
      return true;
    }
    if (currentOffer.creator === currentUser.attributes.sub) {
      return true;
    }
    if (currentOffer.brand === currentUser.attributes.sub) {
      return false;
    }
    return true;
  };

  const positiveText = () => {
    if (currentUser == null) {
      return "";
    }
    if (currentOffer.creator === currentUser.attributes.sub) {
      return "Awaiting Brand";
    }
    if (openResponse) {
      return "Accept";
    } else {
      return "Respond";
    }
  };

  useEffect(() => {
    const getCurrentUser = async () => {
      const curr = await Auth.currentAuthenticatedUser();
      setCurrentUser(curr);
    };
    getCurrentUser();
  }, []);

  useEffect(() => {
    console.log(textFields);
  }, [textFields]);

  if (isMobile || true) {
    return (
      <Box
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          height: "100%",
          paddingLeft: "2.5%",
          paddingRight: "2.5%",
          justifyContent: "center",
          paddingTop: 22,
          paddingBottom: 35,
        }}
      >
        <Box
          onClick={() => setOpenResponse(false)}
          style={{
            display: "flex",
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        />
        <Box
          style={{
            width: "100%",
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "scroll",
          }}
        >
          <Box style={{ width: "100%" }}>
            <Box
              style={{
                height: 25,
                borderRadius: 15,
                backgroundColor: "#D1D8E0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box width={10} />
              <DeliverbleIcon height={12} width={11} color={"#1A3A63"} />
              <Box width={6} />
              <Typography
                style={{
                  fontSize: 12,
                  fontFamily: "Inter",
                  fontWeight: 600,
                  color: "#1A3A63",
                }}
              >
                Deliverables
              </Typography>
            </Box>
            <Box height={23} />

            <Box style={{ flexDirection: "column", width: "100%" }}>
              {textFields.map((value, index) => {
                const textValue = value.match(/text=(.*?)(?:,|\})/)[1];
                return (
                  <Box key={index}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flex: 1,
                        height: 40,
                        width: "100%",
                        borderRadius: 15,
                        borderColor: "#1A3A63",
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingTop: 11,
                        paddingBottom: 11,
                        backgroundColor: "#F3F5F7",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 14,
                          fontFamily: "Outfit",
                          fontWeight: 600,
                          color: "#1A3A63",
                        }}
                      >
                        {textValue}
                      </Typography>
                    </Box>
                    <Box height={10} />
                  </Box>
                );
              })}
            </Box>

            <Box height={36} />
            <Box style={{ display: "flex", width: "100%" }}>
              <Box
                style={{
                  height: 25,
                  borderRadius: 15,
                  width: "45%",
                  backgroundColor: "#D1D8E0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box width={10} />
                <PaymentIcon height={8.3} width={12} color={"#1A3A63"} />
                <Box width={6} />
                <Typography
                  style={{
                    fontSize: 12,
                    fontFamily: "Inter",
                    fontWeight: 600,
                    color: "#1A3A63",
                  }}
                >
                  Payment
                </Typography>
              </Box>
              <Box flex={1} />
              <Box
                style={{
                  height: 25,
                  borderRadius: 15,
                  width: "45%",
                  backgroundColor: "#D1D8E0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontSize: 12,
                    fontFamily: "Inter",
                    fontWeight: 600,
                    color: "#1A3A63",
                  }}
                >
                  Fulfillment Date
                </Typography>
              </Box>
            </Box>

            <Box height={15} />
            <Box style={{ display: "flex", width: "100%" }}>
              <Box
                style={{
                  height: 25,
                  borderRadius: 15,
                  width: "45%",
                  backgroundColor: "#F1F1F1",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontSize: 12,
                    fontFamily: "Inter",
                    fontWeight: 600,
                    color: "#1A3A63",
                  }}
                >
                  ${payment}
                </Typography>
              </Box>
              <Box flex={1} />
              <Box
                style={{
                  height: 25,
                  borderRadius: 15,
                  width: "45%",
                  backgroundColor: "#F1F1F1",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontSize: 12,
                    fontFamily: "Inter",
                    fontWeight: 600,
                    color: "#1A3A63",
                  }}
                >
                  {moment(currentOffer.date).format("DD/MM/YY")}
                </Typography>
              </Box>
            </Box>

            <Box height={36} />

            <Box
              style={{
                height: 25,
                borderRadius: 15,
                backgroundColor: "#D1D8E0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box width={10} />
              <DeliverbleIcon height={12} width={11} color={"#1A3A63"} />
              <Box width={6} />
              <Typography
                style={{
                  fontSize: 12,
                  fontFamily: "Inter",
                  fontWeight: 600,
                  color: "#1A3A63",
                }}
              >
                Additional Notes
              </Typography>
            </Box>

            <Box height={16} />

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
                width: "100%",
                borderRadius: 15,
                borderColor: "#1A3A63",
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 11,
                paddingBottom: 11,
                backgroundColor: "#F3F5F7",
              }}
            >
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: "Outfit",
                  fontWeight: 600,
                  color: "#1A3A63",
                }}
              >
                {additionalNotes}
              </Typography>
            </Box>

            <Box height={48} />
          </Box>
        </Box>
        <Box height={40} />
        <Box style={{ display: "flex", height: 35, width: "100%" }}>
          <Box
            onClick={() => negativeAction()}
            border={openResponse ? 1 : 0}
            style={{
              zIndex: 4,
              width: "45%",
              height: 35,
              borderRadius: 15,
              borderColor: "#1A3A63",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#D1D8E0",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "Outfit",
                fontWeight: 500,
                color: "#1A3A63",
              }}
            >
              {openResponse ? "Decline" : "Back"}
            </Typography>
          </Box>
          <Box flex={1} />
          {currentOffer.contractState !== "Accepted" && (
            <Box
              onClick={() => positiveAction()}
              border={openResponse ? 1 : 0}
              style={{
                borderColor: "#795CB8",
                zIndex: 4,
                width: "45%",
                height: 35,
                borderRadius: 15,
                backgroundColor: disabledButton() ? "#F1EAFF" : "#E2D4FF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: "Outfit",
                  fontWeight: 500,
                  color: disabledButton() ? "#B6A3DC" : "#795CB8",
                }}
              >
                {positiveText()}
              </Typography>
            </Box>
          )}
          {/* {currentOffer.brand === currentUser?.username && <Box width={10} />}
          {currentOffer.brand === currentUser?.username && (
            <Box
              onClick={() => acceptOffer()}
              style={{
                width: "45%",
                height: 35,
                borderRadius: 10,
                backgroundColor: "#1A3A63",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: "Outfit",
                  fontWeight: 500,
                  color: "#FFF",
                }}
              >
                Accept Offer
              </Typography>
            </Box>
          )} */}
        </Box>
      </Box>
    );
  } else {
  }
};
export default ReviewSignContract;
