import { useEffect, useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import "../../styles/ButtonRipple.css";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { updateUser } from "src/graphql/mutations";
import { isMobile as deviceMobile } from "react-device-detect";

const inputProps = {
  style: {
    textAlign: "top",
    height: 150,
    fontFamily: "Outfit",
  },
};

const MyInfo = ({ currentUser, isMobile, editBio, bio }) => {
  const history = useHistory();

  const [savedBio, setSavedBio] = useState(bio ?? "");
  const [message, setMessage] = useState(bio ?? "");
  const [saveButton, setSaveButton] = useState("SAVED");
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  useEffect(() => {
    console.log("message is " + message);
    if (savedBio !== message) {
      setSaveButton("SAVE");
    } else {
      setSaveButton("SAVED");
    }
  }, [message, savedBio]);

  const updateBio = async () => {
    if (deviceMobile) {
      editBio(true);
      return;
    }
    setIsSaveLoading(true);

    const u = await Auth.currentAuthenticatedUser();
    console.log(u.attributes.sub);
    console.log(message);

    const input = {
      id: u.attributes.sub,
      bio: message,
    };

    API.graphql(graphqlOperation(updateUser, { input: input }))
      .then((response) => {
        setIsSaveLoading(false);
        setSavedBio(message);
        setSaveButton("SAVED");
      })
      .catch((error) => {
        console.log(error);
        setIsSaveLoading(false);
      });
  };

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            fontFamily={"Outfit"}
            fontSize={18}
            fontWeight={600}
            color={"#4D4D4D"}
          >
            My Bio
          </Typography>
          <Box height={3} />
          <Typography
            color={"#AAA"}
            fontFamily={"Outfit"}
            fontSize={14}
            fontWeight={500}
          >
            Let people know about you. Who are you? Is there certain information
            you would like in an offer? This bio will be displayed on your form
            to brands.
          </Typography>
        </Box>
      </Box>

      <TextField
        multiline
        disabled={deviceMobile}
        defaultValue={savedBio}
        onChange={(event) => setMessage(event.target.value)}
        inputProps={inputProps}
        style={{
          borderRadius: 5,
          marginTop: 16,
          width: "100%",
          fontFamily: "Outfit",
          backgroundColor: "#FFF",
          overflow: "hidden",
        }}
      />

      <Box display={"flex"} flexDirection={"row"} width={"100%"} marginTop={2}>
        <Box flex={1} />
        <LoadingButton
          loading={isSaveLoading}
          disabled={saveButton === "SAVED" && !deviceMobile}
          onClick={() => updateBio()}
          border={2}
          variant={"outlined"}
          style={{
            fontFamily: "Outfit",
            fontWeight: 600,
            color: saveButton === "SAVED" ? "#1A3A63" : "white",
            borderWidth: isMobile ? 1 : 2,
            cursor: "pointer",
            height: isMobile ? 35 : 45,
            backgroundColor: saveButton === "SAVED" ? "white" : "#1A3A63",
            width: isMobile ? "100%" : 150,
            borderColor: "#1A3A63",
            borderRadius: 15,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "none",
          }}
        >
          {isSaveLoading ? "" : deviceMobile ? "Edit Bio" : saveButton}
        </LoadingButton>
      </Box>
      <Box
        width={"100%"}
        height={2}
        style={{ marginTop: isMobile ? 20 : 35, backgroundColor: "#EEE" }}
      />
      <Box
        style={{
          marginTop: isMobile ? 20 : 35,
          marginBottom: isMobile ? 20 : 35,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          width: "100%",
        }}
      >
        <Box>
          <Typography
            fontFamily={"Outfit"}
            fontSize={18}
            fontWeight={600}
            color={"#4D4D4D"}
          >
            proffera.com/f/{currentUser?.username}
          </Typography>
          <Box height={3} />
          <Typography
            color={"#AAA"}
            fontFamily={"Outfit"}
            fontSize={14}
            fontWeight={500}
          >
            Share this link so people can contact you.
          </Typography>
        </Box>
        <Box flex={1} />
        {isMobile && <Box height={20} />}
        <Box
          onClick={async () => {
            history.push(`/f/${currentUser?.username}`);
          }}
          style={{
            cursor: "pointer",
            height: isMobile ? 35 : 45,
            width: isMobile ? "100%" : 150,
            borderColor: "#1A3A63",
            backgroundColor: "#E2D4FF",
            borderRadius: 15,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            fontWeight={600}
            fontFamily={"Outfit"}
            fontSize={16}
            color={"#795CB8"}
          >
            View my page
          </Typography>
        </Box>
      </Box>

      <Box height={30} />
    </Box>
  );
};
export default MyInfo;
