import * as React from "react";

function LandingThumbIcon(props) {
  return (
    <svg
      width="68"
      height="62"
      viewBox="0 0 68 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 62H12.3636V24.8H0V62ZM68 27.9C68 24.49 65.2182 21.7 61.8182 21.7H42.3145L45.2509 7.533L45.3436 6.541C45.3436 5.27 44.8182 4.092 43.9836 3.255L40.7073 0L20.3691 20.429C19.2255 21.545 18.5455 23.095 18.5455 24.8V55.8C18.5455 59.21 21.3273 62 24.7273 62H52.5455C55.1109 62 57.3055 60.45 58.2327 58.218L67.5673 36.363C67.8455 35.65 68 34.906 68 34.1V27.9Z"
        fill="#795CB8"
      />
    </svg>
  );
}

export default LandingThumbIcon;
