import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";

import LogoNoTextColor from "src/Images/LogoNoTextColor";
import ProfferaNoSubtext from "src/Images/ProfferaNoSubtext";
import NegotiateDeal from "src/components/EndToEndSlides/NegotiateDeal";
import UpdateForm from "src/Images/updateForm.png";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import BackgroundLogo from "src/Images/BackgroundLogo.svg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import SmallLandingHeader from "src/Images/SmallLandingHeader.png";
import CreateContract from "src/components/EndToEndSlides/CreateContract";
import HandlePayment from "src/components/EndToEndSlides/HandlePayment";
import LandingContractIcon from "src/Images/LandingContractIcon";
import LandingNotificationIcon from "src/Images/LandingNotificationIcon";
import LandingLeadIcon from "src/Images/LandingLeadIcon";
import LandingCloseIcon from "src/Images/LandingCloseIcon";
import LandingSwapIcon from "src/Images/SwapIcon";
import LandingPaymentIcon from "src/Images/SwapIcon copy";

const fadeIn = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};

const delayFadeIn = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 2,
    },
  },
};

const LandingPage = ({ isMobile }) => {
  const history = useHistory();
  const isSmallScreen = useMediaQuery("(max-width:1440px)");
  const headerFontSize = isMobile ? "25px" : isSmallScreen ? "4.5vw" : "73px";
  const subHeaderSize = isMobile ? "20px" : isSmallScreen ? "3vw" : "50px";
  const tertiaryHeaderSize = isMobile
    ? "13px"
    : isSmallScreen
    ? "1.35vw"
    : "22px";
  const tertiaryHeaderWidth = isSmallScreen ? "44.1vw" : "710px";
  const purpleGradientHeight = isSmallScreen ? "31vw" : "450px";
  const firstImageWidth = isSmallScreen ? "63.7vw" : "915px";
  const heightOfPurple = isSmallScreen ? "160" : "880px";
  const formAreaWidth = isSmallScreen ? "60.6vw" : "1276px";

  const [inViewRefTitle, inViewTitle] = useInView({
    triggerOnce: true, // This will ensure animations only trigger once
    threshold: 0.1, // Percentage of element that is visible before triggering
  });

  const [inViewRefTitleImage, inViewTitleImage] = useInView({
    triggerOnce: true, // This will ensure animations only trigger once
    threshold: 0.1, // Percentage of element that is visible before triggering
  });

  const [inViewRefOneClick, inViewOneClick] = useInView({
    triggerOnce: true, // This will ensure animations only trigger once
    threshold: 0.1, // Percentage of element that is visible before triggering
  });
  const [inViewRefFormInfo, inViewFormInfo] = useInView({
    triggerOnce: true, // This will ensure animations only trigger once
    threshold: 0.1, // Percentage of element that is visible before triggering
  });
  const [inViewRefEndToEnd, inViewEndToEnd] = useInView({
    triggerOnce: true, // This will ensure animations only trigger once
    threshold: 0.1, // Percentage of element that is visible before triggering
  });

  const [inViewRefActionButtons, inViewActionButtons] = useInView({
    triggerOnce: true, // This will ensure animations only trigger once
    threshold: 0.1, // Percentage of element that is visible before triggering
  });

  const particlesInit = async (main) => {
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    console.log("execute");
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const ParticlesComponent = React.memo(({}) => {
    return (
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          particles: {
            number: {
              value: 30,
              density: {
                enable: true,
                value_area: 1200,
              },
            },
            color: {
              value: "#ffffff",
            },
            shape: {
              type: "image",
              image: {
                src: BackgroundLogo,
              },
            },
            opacity: {
              value: 0.3,
              random: true,
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 150,
              random: true,
              anim: {
                enable: false,
                speed: 4,
                size_min: 0.3,
                sync: false,
              },
            },
            line_linked: {
              enable: false,
            },
            move: {
              enable: true,
              speed: 1,
              direction: "none",
              random: true,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
        }}
      />
    );
  });

  return (
    <Box
      sx={{
        overflowX: "hidden",
        overflowY: "scroll",
      }}
      style={{
        flex: 1,
        height: "100%",
        width: "100%",
        padding: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          flex: 1,
          width: "100%",
          maxWidth: 1440,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box style={{ height: isMobile ? 3 : 30 }} />
        {/* Top Bar */}
        <Box
          style={{
            display: "flex",
            height: 50,
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box width={isMobile ? 20 : 40} />
          <Box
            style={{
              zIndex: 2,
              height: isMobile ? 30 : 45,
              width: isMobile ? 30 : 45,
              backgroundColor: isMobile ? "#FCFCFC" : "#DED9ED",
              borderRadius: 1000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LogoNoTextColor width={31} height={31} />
          </Box>
          <Box width={10} />
          <ProfferaNoSubtext height={27} width={isMobile ? 90 : 117} />
          <Box flex={1} />
          <Box
            sx={{
              "&:hover": {
                backgroundColor: "#F6F7F9",
              },
            }}
            onClick={() => history.push("/login")}
            style={{
              cursor: "pointer",
              width: isMobile ? 65 : 95,
              height: 40,
              borderRadius: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#1A3A63",
                fontFamily: "Outfit",
                fontWeight: 500,
                fontSize: isMobile ? 14 : 16,
              }}
            >
              Login
            </Typography>
          </Box>
          <Box width={isMobile ? 10 : 20} />
          <Box
            sx={{
              backgroundColor: "#E2D4FF",
              color: "#795CB8",
              "&:hover": {
                backgroundColor: "#795CB8",
                color: "white",
              },
            }}
            onClick={() => history.push("/login")}
            style={{
              cursor: "pointer",
              width: isMobile ? 90 : 135,
              height: isMobile ? 25 : 40,
              borderRadius: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "Outfit",
                fontWeight: 500,
                fontSize: isMobile ? 14 : 16,
              }}
            >
              Signup
            </Typography>
          </Box>
          <Box width={isMobile ? 20 : 40} />
        </Box>

        <Box height={30} />

        {/* Section 1 */}
        <motion.div
          key={"somethingelse"}
          ref={inViewRefTitle}
          animate={inViewTitle ? "visible" : "hidden"}
          variants={fadeIn}
        >
          <Box
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                color: "#795CB8",
                fontFamily: "Nunito",
                fontSize: headerFontSize,
                fontWeight: 700,
                lineHeight: 1,
                textAlign: "center",
              }}
            >
              Streamline. Connect. Empower.
            </Typography>
            {isMobile && <Box height={5} />}
            <Typography
              style={{
                color: "#1A3A63",
                fontFamily: "Nunito",
                fontSize: subHeaderSize,
                fontWeight: 700,
                textAlign: "center",
                lineHeight: isMobile ? 1 : 1.2,
              }}
            >
              {(isMobile ? "" : "Your ") + "All-in-One Brand-Creator Solution."}
            </Typography>
            <Box height={10} />
            <Typography
              style={{
                minWidth: 350,
                width: tertiaryHeaderWidth,
                fontFamily: "Nunito",
                fontWeight: 500,
                lineHeight: 1,
                fontSize: tertiaryHeaderSize,
                color: "#000",
                textAlign: "center",
              }}
            >
              The best way to manage brand deals as a creator. View all your
              offers in one place, negotiate deals, and get paid, all within our
              simple and easy to use interface.
            </Typography>
            {/* <Box
            style={{ display: "flex", flexDirection: "column", marginLeft: 29 }}
          >
            <Box height={72} />
            <Box
              onClick={() => history.push("/login")}
              style={{
                cursor: "pointer",
                width: 350,
                height: 45,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                backgroundColor: "#795CB8",
              }}
            >
              <Typography
                style={{ fontFamily: "Outfit", fontSize: 16, color: "#FFF" }}
              >
                Get started!
              </Typography>
            </Box>
          </Box>
          <Box flex={1} />
          {/* <img
            style={{ display: "flex", maxWidth: 850, marginBottom: 0 }}
            src={LandingImage3x}
            alt=""
          /> */}
          </Box>
        </motion.div>

        <Box height={40} />

        <Box
          style={{
            width: "100%",
            display: "flex",
            position: "relative",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              zIndex: -1,
              position: "absolute",
              minHeight: 560,
              height: heightOfPurple,
              width: "100%",
            }}
          >
            <ParticlesComponent />
          </Box>
          <motion.div
            key={"somethingelse"}
            ref={inViewRefTitleImage}
            animate={inViewTitleImage ? "visible" : "hidden"}
            variants={delayFadeIn}
          >
            <Box
              style={{
                zIndex: 2,
                minWidth: 450,
                width: firstImageWidth,
                borderRadius: 15,
                borderColor: "#795CB8",
                overflow: "hidden",
              }}
            >
              <img
                src={SmallLandingHeader}
                alt={""}
                width={"100%"}
                height={"100%"}
              />
            </Box>
          </motion.div>
          <Box
            style={{
              position: "absolute",
              zIndex: -2,
              bottom: isMobile ? 0 : 50,
              width: "100%",
              minimumHeight: 250,
              height: purpleGradientHeight,
              borderRadius: 30,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              backgroundImage: "linear-gradient(#E2D4FF, white)",
            }}
          />
        </Box>

        <Box height={300} />
        <motion.div
          key={"somethingelse"}
          ref={inViewRefOneClick}
          initial="hidden"
          animate={inViewOneClick ? "visible" : "hidden"}
          variants={fadeIn}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                padding: 5,
                fontFamily: "nunito",
                fontSize: 24,
                fontWeight: 700,
                color: "#795CB8",
                lineHeight: 1,
              }}
            >
              One click connections
            </Typography>
            <Typography
              style={{
                lineHeight: 1,
                textAlign: "center",
                padding: 5,
                fontFamily: "nunito",
                fontSize: subHeaderSize,
                fontWeight: 700,
                color: "#1A3A63",
                width: isMobile ? 350 : "auto",
              }}
            >
              Allow brands to connect via your personal url
            </Typography>
            <Box height={isMobile ? 50 : 64} />
            <Box
              border={1}
              style={{
                width: isMobile ? 350 : 762,
                height: isMobile ? 50 : 70,
                backgroundColor: "#FBFAFD",
                borderRadius: 20,
                borderColor: "#795CB8",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box width={16} />
              <Box
                style={{
                  height: isMobile ? 30 : 50,
                  borderRadius: isMobile ? 10 : 20,
                  width: isMobile ? 130 : 219,
                  backgroundColor: "#1A3A63",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    color: "white",
                    fontFamily: "Nunito",
                    fontSize: isMobile ? 16 : 20,
                    fontWeight: 700,
                  }}
                >
                  proffera.com/f/
                </Typography>
              </Box>
              <Box width={15} />
              <Typography
                style={{
                  color: "#CCC",
                  fontFamily: "Nunito",
                  fontSize: isMobile ? 16 : 20,
                  fontWeight: 700,
                }}
              >
                yourusernamehere
              </Typography>
            </Box>
          </Box>
        </motion.div>
        <Box height={300} />
        <motion.div
          key={"something"}
          ref={inViewRefFormInfo}
          initial="hidden"
          animate={inViewFormInfo ? "visible" : "hidden"}
          variants={fadeIn}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              textAlign: "center",
              padding: 5,
              fontFamily: "nunito",
              fontSize: 24,
              fontWeight: 700,
              color: "#795CB8",
              lineHeight: 1,
            }}
          >
            The information you need
          </Typography>
          <Typography
            style={{
              lineHeight: 1,
              textAlign: "center",
              padding: 5,
              fontFamily: "nunito",
              fontSize: subHeaderSize,
              fontWeight: 700,
              color: "#1A3A63",
              width: isMobile ? 350 : "auto",
            }}
          >
            Use forms to quickly collect, view and sort your leads
          </Typography>
          <Box height={isMobile ? 50 : 64} />
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: formAreaWidth,
              flexDirection: "column",
              minWidth: 360,
            }}
          >
            <Box style={{ maxWidth: 400, width: "70%" }}>
              <img src={UpdateForm} alt={""} width={"100%"} />
            </Box>
          </Box>
          <Box height={100} />
        </motion.div>
        <Box height={300} />
        <motion.div
          key={"somethingelse"}
          ref={inViewRefEndToEnd}
          initial="hidden"
          animate={inViewEndToEnd ? "visible" : "hidden"}
          variants={fadeIn}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                padding: 5,
                fontFamily: "nunito",
                fontSize: 24,
                fontWeight: 700,
                color: "#795CB8",
                lineHeight: 1,
              }}
            >
              End to End Solution
            </Typography>
            <Typography
              style={{
                lineHeight: 1,
                textAlign: "center",
                padding: 5,
                fontFamily: "nunito",
                fontSize: subHeaderSize,
                fontWeight: 700,
                color: "#1A3A63",
                width: isMobile ? 350 : "auto",
                maxWidth: 1000,
              }}
            >
              Create Contracts, Handle Payments and Track Deliverables
            </Typography>
            {isMobile && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: 100,
                }}
              >
                <LandingContractIcon />
                <Box height={6} />
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "nunito",
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#795CB8",
                    lineHeight: 1,
                  }}
                >
                  Engage with Brands
                </Typography>
                <Box height={14} />
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "nunito",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#57616F",
                    lineHeight: 1,
                    width: 300,
                  }}
                >
                  Exchange messages with brands to secure business.
                </Typography>
                <Box height={100} />
                <LandingNotificationIcon />
                <Box height={10} />
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "nunito",
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#795CB8",
                    lineHeight: 1,
                  }}
                >
                  Push Notifications
                </Typography>
                <Box height={14} />
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "nunito",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#57616F",
                    lineHeight: 1,
                    width: 300,
                  }}
                >
                  Receive notifications to track the progress of your leads
                </Typography>
                <Box height={100} />
                <LandingLeadIcon />
                <Box height={6} />
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "nunito",
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#795CB8",
                    lineHeight: 1,
                  }}
                >
                  Sort Leads
                </Typography>
                <Box height={14} />
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "nunito",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#57616F",
                    lineHeight: 1,
                    width: 250,
                  }}
                >
                  View all your leads in one place.
                </Typography>
                <Box height={100} />
                <LandingCloseIcon />
                <Box height={10} />
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "nunito",
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#795CB8",
                    lineHeight: 1,
                  }}
                >
                  Create & Close
                </Typography>
                <Box height={14} />
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "nunito",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#57616F",
                    lineHeight: 1,
                    width: 300,
                  }}
                >
                  Seamlessly transition from discussions to closing
                </Typography>
                <Box height={100} />
                <LandingSwapIcon />
                <Box height={15} />
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "nunito",
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#795CB8",
                    lineHeight: 1,
                  }}
                >
                  Negotiate Contracts
                </Typography>
                <Box height={14} />
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "nunito",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#57616F",
                    lineHeight: 1,
                    width: 300,
                  }}
                >
                  Clearly and simply layout contracts to send to brands
                </Typography>
                <Box height={100} />
                <LandingPaymentIcon />
                <Box height={14} />
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "nunito",
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#795CB8",
                    lineHeight: 1,
                  }}
                >
                  Secure Payments
                </Typography>
                <Box height={14} />
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "nunito",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#57616F",
                    lineHeight: 1,
                    width: 300,
                  }}
                >
                  Safely and securely make payments with Stripe
                </Typography>
              </Box>
            )}
            {!isMobile && <Box height={80} />}
            {!isMobile && <NegotiateDeal />}
            {!isMobile && (
              <Box
                style={{
                  marginTop: 80,
                  marginBottom: 80,
                  width: "95vw",
                  height: 12,
                  borderRadius: 10,
                  backgroundColor: "#D9D9D9",
                  position: "relative",
                }}
              ></Box>
            )}
            {!isMobile && <CreateContract />}
            {!isMobile && (
              <Box
                style={{
                  marginTop: 80,
                  marginBottom: 80,
                  width: "95vw",
                  height: 12,
                  borderRadius: 10,
                  backgroundColor: "#D9D9D9",
                  position: "relative",
                }}
              ></Box>
            )}
            {!isMobile && <HandlePayment />}
          </Box>
        </motion.div>
        <Box height={200} />

        <Box height={isMobile ? 100 : 200} />
        <motion.div
          key={"something"}
          ref={inViewRefActionButtons}
          initial="hidden"
          animate={inViewActionButtons ? "visible" : "hidden"}
          variants={fadeIn}
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#E2D4FF",
              color: "#795CB8",
              "&:hover": {
                backgroundColor: "#795CB8",
                color: "white",
              },
            }}
            style={{
              height: isMobile ? 50 : 60,
              width: isMobile ? "90%" : "80%",
              borderRadius: isMobile ? 20 : 25,
              borderWidth: 0,
              fontFamily: "Outfit",
              fontWeight: 500,
              fontSize: isMobile ? 18 : 22,
              textTransform: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="button"
            onClick={() => history.push("/login")}
          >
            {"Get Started"}
          </Box>
          <Box height={30} />
          <Box
            sx={{
              borderWidth: 0,
              "&:hover": {
                borderWidth: 1,
              },
            }}
            style={{
              height: 40,
              width: isMobile ? "90%" : "50%",
              borderRadius: 20,
              backgroundColor: "#F6F7F9",
              borderColor: "#1A3A63",
              color: "#1A3A63",
              fontFamily: "Outfit",
              fontWeight: 500,
              fontSize: isMobile ? 15 : 18,
              textTransform: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="button"
            onClick={() => console.log("")}
          >
            {"Learn More"}
          </Box>
        </motion.div>
        <Box height={isMobile ? 50 : 200} />
      </Box>
      <style>{`
        /* Hide scrollbar for all elements with the default WebKit scrollbar style */
        ::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </Box>
  );
};
export default LandingPage;
