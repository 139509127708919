import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LogoSVG from "src/Images/Logo";
import LogoNoTextSVG from "src/Images/LogoNoText";
import { Auth, API } from "aws-amplify";
import { createNewUser, listUsers } from "src/graphql/queries";
import { isMobile as deviceMobile } from "react-device-detect";

const Onboarding = ({ isMobile }) => {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [userIsCustomer, setUserIsCustomer] = useState(true);
  const [onBoardingStep, setOnboardingStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [isUsernameAvailable, setUsernameAvailable] = useState(0);

  const checkCurrentUser = async () => {
    try {
      console.log("checkingCurrentUser");
      const uu = await Auth.currentAuthenticatedUser();
      if (uu == null) {
        history.push("/login");
      }
      console.log("authenticated");
      return true;
    } catch (_error) {
      history.push("/login");
    }
    return false;
  };

  useEffect(() => {
    checkCurrentUser();
  });

  const checkIfButtonDisabled = () => {
    if (onBoardingStep === 1) {
      return false;
    } else if (onBoardingStep === 2) {
      if (username !== "") return false;
      else return true;
    } else if (onBoardingStep === 3) {
      if (companyName !== "") return false;
      else return true;
    } else {
      return true;
    }
  };

  const doLogout = async () => {
    // Logout
    try {
      var currentUser = await Auth.currentAuthenticatedUser();
      if (currentUser) {
        try {
          window.localStorage.clear();
          console.log("clearing local storage");
          await Auth.signOut();
          history.push("/login");

          // Force useEffect execution to redirect back to Login
          return true;
        } catch (error) {
          alert(error);
          return false;
        }
      }
    } catch {
      history.push("/login");
    }
  };

  const checkUsernameExists = async () => {
    if (username === "") {
      setCheckingUsername(false);
      setUsernameAvailable(0);
      return;
    }

    const checkUserFilter = {
      username: {
        eq: username,
      },
    };

    const checkUsername = await API.graphql({
      query: listUsers,
      variables: { filter: checkUserFilter },
    });

    if (checkUsername.data.listUsers.items.length > 0) {
      setUsernameAvailable(1);
      setCheckingUsername(false);
      return;
    } else {
      setUsernameAvailable(2);
      setCheckingUsername(false);
      return;
    }
    // const query = new Parse.Query(Parse.User);
    // query.equalTo("username", username);
    // try {
    //   const result = await query.first();
    //   if (result !== undefined) {
    //     setUsernameAvailable(1);
    //   } else {
    //     setUsernameAvailable(2);
    //   }
    // } catch (error) {
    //   console.error("Error checking username:", error);
    // }
    // setCheckingUsername(false);
  };

  // useEffect(() => {
  //   checkUsernameExists();
  // }, [username]);

  const getAvailabilityMessageColor = () => {
    if (isUsernameAvailable === 0) {
      return "#CCCCCC";
    } else if (isUsernameAvailable === 1) {
      return "red";
    } else if (isUsernameAvailable === 2) {
      return "#00FF00";
    }
  };

  const updateUser = async () => {
    setLoading(true);

    // const data = await API.graphql({
    //   query: createStripeUser,
    // });

    const curr = await Auth.currentAuthenticatedUser();
    const userId = curr.attributes.sub;

    console.log(
      userId,
      username,
      curr.attributes.email,
      !userIsCustomer,
      companyName
    );

    API.graphql({
      query: createNewUser,
      variables: {
        userId: userId,
        username: username,
        email: curr.attributes.email,
        isBrand: !userIsCustomer,
        displayName: companyName,
      },
    })
      .then((response) => {
        console.log(response);
        const createdUser = response.data.createNewUser;
        if (createdUser) {
          history.push("/");
        }
        // Handle success or perform additional actions
      })
      .catch((error) => {
        console.error("Failed to create user:", error);
        // Handle error or display error message
      });

    setLoading(false);

    // const inputData = {
    //   input: {
    //     id: userId,
    //     username: username,
    //     email: curr.attributes.email,
    //     isBrand: !userIsCustomer,
    //     displayName: companyName,
    //     bio: "",
    //     showNewUserInfo: true,
    //     stripeId: data.data.createStripeUser,
    //   },
    // };
    // API.graphql({
    //   query: createUser,
    //   variables: inputData,
    // })
    //   .then((response) => {
    //     const createdUser = response.data.createUser;
    //     console.log("User created:", createdUser);
    //     history.push("/");
    //     // Handle success or perform additional actions
    //   })
    //   .catch((error) => {
    //     console.error("Failed to create user:", error);
    //     // Handle error or display error message
    //   });
  };

  return (
    <Box display={"flex"} flex={1} width={"100vw"} height={"100%"}>
      {!isMobile && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            margin: 15,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            borderRadius: 20,
            minWidth: 480,
            maxWidth: 480,
            background: "#E9ECF0",
            alignItems: "center",
          }}
        >
          <Box
            position={"absolute"}
            style={{
              width: "100%",
              height: "80%",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LogoNoTextSVG />
          </Box>
          <Box style={{ width: "100%" }}>
            <LogoSVG width={211} height={76} />
          </Box>
          <Box height={50} />
          {/* <InformationCheck
            header={"Type of user"}
            subHeading={"Selected how you will use profféra"}
            isFullfilled={true}
          />
          <Box height={20} />
          <InformationCheck
            header={"Who are you?"}
            subHeading={"Selected a valid and available username"}
            isFullfilled={false}
          />
          <Box height={20} />
          <InformationCheck
            header={"Company name"}
            subHeading={"Selected a valid company name"}
            isFullfilled={false}
          /> */}
          <Box flex={1} />
          <Typography
            style={{
              paddingLeft: 8,
              paddingRight: 8,
              fontFamily: "Outfit",
              fontWeight: 600,
              fontSize: 16,
              color: "#1a3a63",
            }}
          >
            Let us take a few seconds to get to know you and how we can get you
            on the path to success right away!
          </Typography>
          <Box height={45} />
          <Box
            border={2}
            style={{
              zIndex: 10,
              cursor: "pointer",
              borderColor: "#CCC",
              width: "100%",
              height: 40,
              backgroundColor: "white",
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => doLogout()}
          >
            <Typography
              style={{
                fontSize: 16,
                color: "#1A3A63",
                fontFamily: "Outfit",
                fontWeight: 700,
              }}
            >
              Return to dashboard
            </Typography>
          </Box>
          <Box height={deviceMobile ? 15 : 50} />
        </div>
      )}
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={isMobile ? "100%" : 950}
        style={{
          height: isMobile ? "100%" : "auto",
          overflowY: "auto",
          paddingLeft: isMobile ? 15 : 10,
          paddingRight: isMobile ? 15 : 30,
          marginTop: isMobile ? 10 : 30,
          marginBottom: 30,
        }}
      >
        <Box
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box style={{ width: "50%", display: "flex", alignItems: "center" }}>
            <LogoSVG width={150} height={50} />
          </Box>
          {isMobile || (deviceMobile && <Box flex={1} />)}
          {isMobile ||
            (deviceMobile && (
              <Box
                border={1}
                style={{
                  maxWidth: 150,
                  width: 150,
                  height: 29,
                  borderRadius: 15,
                  borderColor: "#795CB8",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <Box
                  style={{
                    height: 27,
                    width: (onBoardingStep / 3) * 100,
                    display: "flex",
                    backgroundColor: "#F2EFF8",
                    position: "absolute",
                    borderRadius: 15,
                  }}
                />
                <Typography
                  style={{
                    fontSize: 12,
                    fontFamily: "Outfit",
                    fontWeight: 600,
                    color: "#795CB8",
                    zIndex: 1,
                  }}
                >
                  {Math.round(((onBoardingStep - 1) / 3) * 100)}% complete
                </Typography>
              </Box>
            ))}
        </Box>
        <Box height={20} />
        {((!isMobile && !deviceMobile) || onBoardingStep === 1) && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            marginTop={deviceMobile || isMobile ? 2 : 0}
          >
            <Box
              onClick={() => setUserIsCustomer(true)}
              border={userIsCustomer ? 4 : 2}
              style={{
                zIndex: 2,
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                height: 120,
                width: "100%",
                borderRadius: deviceMobile || isMobile ? 15 : 30,
                borderColor: userIsCustomer ? "#1A3A63" : "#D9D9D9",
                padding: 10,
                paddingRight: deviceMobile || isMobile ? 20 : 10,
                paddingLeft: deviceMobile || isMobile ? 20 : 10,
                alignItems: "center",
              }}
            >
              <Box flex={1} />
              <Box
                style={{
                  height: 30,
                  width: 200,
                  backgroundColor: "#D1D8E0",
                  borderRadius: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontSize={deviceMobile || isMobile ? 16 : 20}
                  color={"#1A3A63"}
                  fontWeight={600}
                  fontFamily={"Outfit"}
                  textAlign={"center"}
                >
                  I represent a brand
                </Typography>
              </Box>
              <Box height={10} />
              <Typography
                lineHeight={1.1}
                fontSize={deviceMobile || isMobile ? 16 : 18}
                color={"#929292"}
                fontWeight={500}
                fontFamily={"Outfit"}
                textAlign={"center"}
              >
                I will pay others to help promote my products / services
              </Typography>
              <Box flex={1} />
            </Box>
            {isMobile ? <Box height={20} /> : <Box height={20} />}

            <Box
              onClick={() => setUserIsCustomer(false)}
              border={userIsCustomer ? 2 : 4}
              style={{
                zIndex: 2,
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                height: 120,
                width: "100%",
                borderRadius: deviceMobile || isMobile ? 15 : 30,
                borderColor: userIsCustomer ? "#D9D9D9" : "#1A3A63",
                padding: 10,
                paddingRight: deviceMobile || isMobile ? 20 : 10,
                paddingLeft: deviceMobile || isMobile ? 20 : 10,
                alignItems: "center",
              }}
            >
              <Box flex={1} />
              <Box
                style={{
                  height: 30,
                  width: 200,
                  backgroundColor: "#D1D8E0",
                  borderRadius: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontSize={deviceMobile || isMobile ? 16 : 20}
                  color={"#1A3A63"}
                  fontWeight={600}
                  fontFamily={"Outfit"}
                  textAlign={"center"}
                >
                  I am a creator
                </Typography>
              </Box>
              <Box height={10} />
              <Typography
                lineHeight={1.1}
                fontSize={deviceMobile || isMobile ? 16 : 18}
                color={"#929292"}
                fontWeight={500}
                fontFamily={"Outfit"}
                textAlign={"center"}
              >
                People will pay me to promote their products / services
              </Typography>
              <Box flex={1} />
            </Box>
          </Box>
        )}
        <Box height={isMobile || deviceMobile ? 0 : 70} />
        {((!isMobile && !deviceMobile) || onBoardingStep === 2) && (
          <Box>
            <Typography
              fontWeight={600}
              fontFamily={"Outfit"}
              fontSize={deviceMobile || isMobile ? 16 : 20}
              color={"#929292"}
            >
              <span style={{ fontWeight: 700 }}>Username</span>
            </Typography>
            <Box height={10} />
            <Box
              height={60}
              position={"relative"}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
            >
              <Typography
                position={"absolute"}
                textAlign={"center"}
                style={{
                  paddingLeft: deviceMobile || isMobile ? 20 : 20,
                  fontWeight: 500,
                  fontFamily: "Outfit",
                  fontSize: deviceMobile || isMobile ? 16 : 22,
                  color: "#CBCBCB",
                }}
              >
                proffera.com/f/
              </Typography>
              <input
                id={"inputID"}
                style={{
                  width: 950,
                  height: deviceMobile || isMobile ? 50 : 60,
                  borderRadius: deviceMobile || isMobile ? 15 : 20,
                  borderWidth: 1,
                  borderColor: "#CCC",
                  paddingLeft: deviceMobile || isMobile ? 135 : 181,
                  paddingRight: deviceMobile || isMobile ? 20 : 39,
                  fontWeight: 500,
                  color: "#795cb8",
                  fontFamily: "Outfit",
                  fontSize: deviceMobile || isMobile ? 16 : 22,
                }}
                type={"email"}
                value={username}
                onChange={(event) =>
                  setUsername(event.target.value.toLowerCase())
                }
                onFocus={() => {
                  setCheckingUsername(true);
                }}
                onBlur={() => {
                  setCheckingUsername(true);
                  checkUsernameExists();
                }}
              ></input>
            </Box>

            <Box height={5} />
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography
                fontSize={deviceMobile || isMobile ? 14 : 18}
                fontWeight={500}
                fontFamily={"Outfit"}
              >
                <span style={{ color: "#CBCBCB" }}>Username unavailable? </span>
                {!checkingUsername && (
                  <span style={{ color: getAvailabilityMessageColor() }}>
                    {isUsernameAvailable === 0
                      ? "Unknown"
                      : isUsernameAvailable === 1
                      ? "Unavailable"
                      : isUsernameAvailable === 2
                      ? "Available"
                      : "Error"}
                  </span>
                )}
              </Typography>
              {checkingUsername && (
                <CircularProgress
                  style={{
                    marginLeft: 5,
                    width: 15,
                    height: 15,
                    color: "#795CB8",
                  }}
                />
              )}
            </Box>
          </Box>
        )}

        <Box height={deviceMobile || isMobile ? 0 : 50} />
        {((!isMobile && !deviceMobile) || onBoardingStep === 3) && (
          <Box>
            <Typography
              fontWeight={600}
              fontFamily={"Outfit"}
              fontSize={deviceMobile || isMobile ? 16 : 20}
              color={"#929292"}
            >
              <span style={{ fontWeight: 700 }}>
                {userIsCustomer ? "Name" : "Company Name"}
              </span>{" "}
            </Typography>
            <Box height={10} />
            <Box
              height={60}
              position={"relative"}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
            >
              <input
                id={"inputID"}
                style={{
                  width: 950,
                  height: deviceMobile || isMobile ? 50 : 60,
                  borderRadius: deviceMobile || isMobile ? 15 : 20,
                  borderWidth: 1,
                  borderColor: "#CCC",
                  paddingLeft: deviceMobile || isMobile ? 20 : 20,
                  paddingRight: deviceMobile || isMobile ? 20 : 39,
                  fontWeight: 500,
                  color: "#795cb8",
                  fontFamily: "Outfit",
                  fontSize: deviceMobile || isMobile ? 16 : 22,
                }}
                type={"email"}
                value={companyName}
                onChange={(event) => setCompanyName(event.target.value)}
              ></input>
            </Box>
          </Box>
        )}
        <Box flex={1} />
        {isMobile || deviceMobile ? (
          <Box
            style={{
              display: "flex",
              width: "100%",
              marginBottom: deviceMobile && !isMobile ? 0 : 60,
            }}
          >
            {onBoardingStep !== 1 && (
              <Box
                onClick={() => {
                  if (onBoardingStep === 2) {
                    setOnboardingStep(1);
                  } else if (onBoardingStep === 3) {
                    setOnboardingStep(2);
                  }
                }}
                style={{
                  borderWidth: 2,
                  borderColor: "#DDD",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textTransform: "none",
                  height: 45,
                  width: "95%",
                  borderRadius: 15,
                  backgroundColor: "#D1D8E0",
                  color: "#1A3A63",
                }}
                variant={"contained"}
              >
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    fontFamily: "Outfit",
                    color: "#000",
                  }}
                >
                  Back
                </Typography>
              </Box>
            )}
            <Box flex={1} />
            {onBoardingStep !== 1 && <Box width={20} />}
            <LoadingButton
              loading={loading}
              onClick={() => {
                if (checkIfButtonDisabled()) {
                } else {
                  if (onBoardingStep === 1) {
                    setOnboardingStep(2);
                  } else if (onBoardingStep === 2) {
                    setOnboardingStep(3);
                  } else if (onBoardingStep === 3) {
                    updateUser();
                  }
                }
              }}
              borderColor={"#4D4D4D"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textTransform: "none",
                height: 45,
                width: "95%",
                backgroundColor: checkIfButtonDisabled()
                  ? "#F1EAFF"
                  : "#E2D4FF",
                borderRadius: 15,
                color: checkIfButtonDisabled() ? "white" : "#795CB8",
              }}
            >
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  fontFamily: "Outfit",
                }}
              >
                {loading ? "" : onBoardingStep === 3 ? "Finish" : "Continue"}
              </Typography>
            </LoadingButton>
            {onBoardingStep === 1 && <Box flex={1} />}
          </Box>
        ) : (
          <Box style={{ display: "flex", width: "100%" }}>
            <Box flex={1} />
            <LoadingButton
              loading={loading}
              disabled={isUsernameAvailable !== 2 || companyName === ""}
              onClick={() => updateUser()}
              border={1}
              borderColor={"#4D4D4D"}
              style={{
                textTransform: "none",
                height: 50,
                width: 209,
                fontSize: 16,
                fontWeight: 600,
                fontFamily: "Outfit",
                backgroundColor:
                  isUsernameAvailable !== 2 || companyName === ""
                    ? "#D7CEEA"
                    : "#795CB8",
                borderRadius: 5,
                color: "white",
              }}
              variant={"contained"}
            >
              {loading ? "" : "Continue"}
            </LoadingButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default Onboarding;
