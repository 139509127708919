/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      email
      profilePic {
        bucket
        key
        region
      }
      isBrand
      displayName
      bio
      showNewUserInfo
      stripeId
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      profilePic {
        bucket
        key
        region
      }
      isBrand
      displayName
      bio
      showNewUserInfo
      stripeId
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      email
      profilePic {
        bucket
        key
        region
      }
      isBrand
      displayName
      bio
      showNewUserInfo
      stripeId
      createdAt
      updatedAt
    }
  }
`;
export const createNegotiation = /* GraphQL */ `
  mutation CreateNegotiation(
    $input: CreateNegotiationInput!
    $condition: ModelNegotiationConditionInput
  ) {
    createNegotiation(input: $input, condition: $condition) {
      id
      brand
      brandUsername
      brandDisplayName
      brandLastSeen
      creator
      creatorUsername
      creatorDisplayName
      creatorLastSeen
      lastUpdate
      payment
      budgetRangeIndex
      requirements
      isPaid
      date
      additionalNotes
      contractState
      delivarablesFinalized
      negotiationOpen
      stripePaymentIntent
      createdAt
      updatedAt
    }
  }
`;
export const updateNegotiation = /* GraphQL */ `
  mutation UpdateNegotiation(
    $input: UpdateNegotiationInput!
    $condition: ModelNegotiationConditionInput
  ) {
    updateNegotiation(input: $input, condition: $condition) {
      id
      brand
      brandUsername
      brandDisplayName
      brandLastSeen
      creator
      creatorUsername
      creatorDisplayName
      creatorLastSeen
      lastUpdate
      payment
      budgetRangeIndex
      requirements
      isPaid
      date
      additionalNotes
      contractState
      delivarablesFinalized
      negotiationOpen
      stripePaymentIntent
      createdAt
      updatedAt
    }
  }
`;
export const deleteNegotiation = /* GraphQL */ `
  mutation DeleteNegotiation(
    $input: DeleteNegotiationInput!
    $condition: ModelNegotiationConditionInput
  ) {
    deleteNegotiation(input: $input, condition: $condition) {
      id
      brand
      brandUsername
      brandDisplayName
      brandLastSeen
      creator
      creatorUsername
      creatorDisplayName
      creatorLastSeen
      lastUpdate
      payment
      budgetRangeIndex
      requirements
      isPaid
      date
      additionalNotes
      contractState
      delivarablesFinalized
      negotiationOpen
      stripePaymentIntent
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      negotiationId
      userId
      content
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      negotiationId
      userId
      content
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      negotiationId
      userId
      content
      type
      createdAt
      updatedAt
    }
  }
`;
