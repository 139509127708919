import React, { useEffect, useState } from "react";
import { Box, Typography, Tab, Tabs, List } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import { Storage, API } from "aws-amplify";
import { updatePaymentInfo } from "./graphql/queries";
import "./styles/ButtonRipple.css";
import MyInfo from "./components/AdvertiserSpaceTabs";
import MenuIcon from "./Images/menu.png";
import WarningIcon from "./Images/warning.png";
import BackArrow from "./Images/backarrow.png";
import { LoadingButton } from "@mui/lab";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import { Auth, graphqlOperation } from "aws-amplify";
import { updateUser } from "src/graphql/mutations";
import { isMobile as deviceMobile } from "react-device-detect";

const useStyles = makeStyles({
  customLabel: {
    fontFamily: "Outfit",
    fontSize: 16,
    width: 180,
    textTransform: "none",
  },
});

const Icon = styled("img")`
  width: 30px;
  height: 30px;
`;

const NewComponent = ({ bio, setShowNewComponent, setBio }) => {
  const [message, setMessage] = useState(bio);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const updateBio = async () => {
    setIsSaveLoading(true);

    const u = await Auth.currentAuthenticatedUser();

    const input = {
      id: u.attributes.sub,
      bio: message,
    };

    API.graphql(graphqlOperation(updateUser, { input: input }))
      .then((response) => {
        setIsSaveLoading(false);
        setBio(message);
        setShowNewComponent(false);
      })
      .catch((error) => {
        console.log(error);
        setIsSaveLoading(false);
      });
  };

  return (
    <>
      <textarea
        ontouchstart=""
        id="bioInputID"
        type={"email"}
        rows="3"
        value={message}
        onChange={(event) => setMessage(event.target.value)}
      ></textarea>
      <Box height={10} />
      <LoadingButton
        loading={isSaveLoading}
        onClick={() => updateBio()}
        style={{
          fontFamily: "Outfit",
          fontWeight: 600,
          color: "#795CB8",
          cursor: "pointer",
          height: 35,
          backgroundColor: "#E2D4FF",
          width: "100%",
          borderRadius: 15,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          textTransform: "none",
        }}
      >
        {isSaveLoading ? "" : "Save"}
      </LoadingButton>
    </>
  );
};

const AdvertiserSpace = ({
  currentUser,
  isMobile,
  showSideBar,
  setShowSideBar,
}) => {
  const [profilePic, setProfilePic] = useState(null);
  const [value, setValue] = React.useState("one");
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [isPaymentsSetup, setPaymentsSetup] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showNewComponent, setShowNewComponent] = useState(false);
  const [bio, setBio] = useState(currentUser?.bio ?? "");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const findProfilePic = async () => {
    if (currentUser.profilePic) {
      const url = await Storage.get(currentUser.profilePic.key, {
        key: currentUser.profilePic.bucket,
      });
      setProfilePic(url);
    }
  };
  useEffect(() => {
    if (currentUser) {
      findProfilePic();
    }
  });
  const classes = useStyles();

  const slideInOut = {
    initial: { x: "100%" },
    enter: { x: 0, transition: { type: "tween", duration: 0.3 } },
    exit: { x: "-100%", transition: { type: "tween", duration: 0.3 } },
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        minWidth: 200,
        overflow: "hidden",
      }}
    >
      {!isMobile && showNewComponent ? (
        <Box
          border={1}
          style={{
            borderColor: "#1A3A63",
            borderRadius: 10,
            padding: 10,
            height: 35,
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
          backgroundColor={"#F1F2F5"}
          width={"100%"}
        >
          <Typography
            noWrap
            style={{
              width: "100%",
              textAlign: "center",
              wordWrap: "break-all",
              display: "flex",
            }}
            color={"#4D4D4D"}
            fontFamily={"Outfit"}
            fontSize={16}
            fontWeight={500}
          >
            <pre
              style={{
                margin: 0,
                width: "100%",
                fontFamily: "inherit",
                whiteSpace: "pre-wrap",
              }}
            >
              Update your bio
            </pre>
          </Typography>
        </Box>
      ) : (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {isMobile && (
            <img
              onClick={() => {
                if (showNewComponent) {
                  setShowNewComponent(false);
                } else {
                  setShowSideBar(!showSideBar);
                }
              }}
              style={{
                width: showNewComponent ? 30 : 35,
                height: showNewComponent ? 30 : 35,
              }}
              src={showNewComponent ? BackArrow : MenuIcon ?? ""}
              alt={""}
              loading="lazy"
            />
          )}
          {isMobile && <Box width={15} />}
          {showNewComponent && <Box flex={1} />}
          {isMobile && (
            <Typography
              style={{
                cursor: "default",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Outfit",
                color: "#000",
                lineHeight: "110%",
              }}
            >
              {showNewComponent ? "Editing Bio" : "Advertising"}
            </Typography>
          )}
          {isMobile && !showNewComponent && <Box flex={1} />}
          {!isMobile && (
            <>
              {profilePic ? (
                <img
                  style={{
                    width: isMobile ? 35 : 75,
                    height: isMobile ? 35 : 75,
                    borderRadius: 1000,
                  }}
                  src={profilePic ?? ""}
                  alt={""}
                  loading="lazy"
                />
              ) : (
                <Box
                  style={{
                    width: isMobile ? 35 : 75,
                    height: isMobile ? 35 : 75,
                    borderRadius: 1000,
                    backgroundColor: "#E2D4FF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    align="center"
                    fontWeight={600}
                    color={"#795CB8"}
                    fontSize={isMobile ? 15 : 30}
                    fontFamily={"Nunito"}
                  >
                    {currentUser?.username.charAt(0).toUpperCase()}
                  </Typography>
                </Box>
              )}
            </>
            // <img
            //   style={{
            //     width: isMobile ? 35 : 75,
            //     height: isMobile ? 35 : 75,
            //     borderRadius: 1000,
            //   }}
            //   src={profilePic ?? ""}
            //   alt={""}
            //   loading="lazy"
            // />
          )}
          <Box width={10} />
          {!isMobile && (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: isMobile ? 35 : 75,
              }}
            >
              <Typography
                fontFamily={"Outfit"}
                fontSize={isMobile ? 18 : 24}
                fontWeight={500}
              >
                {currentUser?.username}
              </Typography>
              <Typography
                marginTop={-1}
                fontFamily={"Outfit"}
                fontSize={isMobile ? 12 : 16}
                color={"#3F3F3F"}
              >
                Creator
              </Typography>
            </Box>
          )}
          <Box flex={1} />
          <Box width={45} />
        </Box>
      )}
      <Box height={showNewComponent ? 0 : isMobile ? 20 : 40} />
      {!isMobile && !showNewComponent && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            position: "relative",
          }}
        >
          <Box
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              height: 2,
              backgroundColor: "#CCC",
            }}
          />
          <Tabs
            textColor="inherit"
            fontFamily="Outfit"
            TabIndicatorProps={{ style: { background: "#4D4D4D" } }}
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab className={classes.customLabel} value="one" label="My Info" />
            <Tab
              className={classes.customLabel}
              value="two"
              label="Payment Details"
            />
          </Tabs>
        </Box>
      )}
      {!isPaymentsSetup && (
        <Box
          border={1}
          style={{
            borderColor: "#FF8A00",
            width: "100%",
            minHeight: 60,
            display: "flex",
            alignItems: "center",
            marginTop: 10,
            backgroundColor: "#fff",
            paddingLeft: 10,
            borderRadius: 10,
          }}
        >
          <Icon src={WarningIcon ?? ""} alt="" loading="lazy" />
          <Box width={10} />
          <Typography
            style={{ fontFamily: "Outfit", color: "#FF8A00", fontWidth: 500 }}
          >
            You must setup a payment method in order to start accepting offers
          </Typography>
        </Box>
      )}
      <Box
        margin={isMobile ? 0 : showNewComponent ? 2 : 5}
        marginLeft={0}
        marginRight={0}
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflowY: "auto",
        }}
      >
        <AnimatePresence exitBeforeEnter>
          {showNewComponent ? (
            <motion.div
              variants={slideInOut}
              initial="initial"
              animate="enter"
              exit="exit"
            >
              <NewComponent
                bio={bio}
                setBio={setBio}
                setShowNewComponent={setShowNewComponent}
              />
            </motion.div>
          ) : (
            <List style={{ height: 0, flexGrow: 1, overflowY: "auto" }}>
              <Box>
                {value == "one" && (
                  <MyInfo
                    currentUser={currentUser}
                    isMobile={isMobile}
                    bio={bio}
                    editBio={setShowNewComponent}
                  />
                )}
                {isMobile && (
                  <Box
                    width={"100%"}
                    height={2}
                    style={{ backgroundColor: "#EEE" }}
                  />
                )}
                {(value == "two" || isMobile) && (
                  <Box
                    style={{
                      marginTop: isMobile ? 20 : 0,
                      marginBottom: 35,
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      width: "100%",
                    }}
                  >
                    <Box>
                      <Typography
                        fontFamily={"Outfit"}
                        fontSize={18}
                        fontWeight={600}
                        color={"#4D4D4D"}
                      >
                        Payment Information
                      </Typography>
                      <Box height={3} />
                      <Typography
                        color={"#AAA"}
                        fontFamily={"Outfit"}
                        fontSize={14}
                        fontWeight={500}
                      >
                        Proffera partners with Stripe to handle payments. Setup
                        your payment details in order to get paid.
                      </Typography>
                    </Box>
                    {isMobile ? <Box height={20} /> : <Box width={10} />}
                    <Box flex={1} />
                    <Box
                      width={isMobile ? "100%" : "auto"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <LoadingButton
                        loading={loading}
                        onClick={async () => {
                          setLoading(true);
                          console.log(window.location.origin);
                          API.graphql({
                            query: updatePaymentInfo,
                            variables: {
                              userId: currentUser.id,
                              location: window.location.origin,
                            },
                          }).then((response) => {
                            try {
                              window.location.href =
                                response.data.updatePaymentInfo;
                              // window.open(
                              //   response.data.updatePaymentInfo,
                              //   "_blank"
                              // );
                              setLoading(false);
                            } catch {
                              console.log("failure");
                              setLoading(false);
                            }
                          });
                          // const stripeAccountID =
                          //   currentUser.get("stripeAccountID");
                          // Parse.Cloud.run("createStripeLink", {
                          //   stripeAccountID,
                          // }).then((response) => {
                          //   try {
                          //     window.location.replace(response.url);
                          //   } catch {
                          //     console.log("failure");
                          //   }
                          // });
                        }}
                        style={{
                          textTransform: "none",
                          cursor: "pointer",
                          height: isMobile ? 35 : 45,
                          width: isMobile ? "100%" : 150,
                          borderColor: "#1A3A63",
                          backgroundColor: "#E2D4FF",
                          borderRadius: 15,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: 16,
                          fontFamily: "Outfit",
                          fontWeight: 600,
                          color: "#795CB8",
                        }}
                      >
                        {loading ? "" : "Update details"}
                      </LoadingButton>
                    </Box>
                  </Box>
                )}
              </Box>
            </List>
          )}
        </AnimatePresence>
      </Box>
      <style>{`
        /* Hide scrollbar for all elements with the default WebKit scrollbar style */
        ::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </Box>
  );
};

export default AdvertiserSpace;
