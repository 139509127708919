import React, { useEffect, useState, useRef } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { API, graphqlOperation, Auth } from "aws-amplify";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeliverableItem from "./OfferComponents/DeliverableItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DeliverbleIcon from "src/Images/DeliverableIcon";
import PaymentIcon from "src/Images/PaymentIcon";
import { updateNegotiation } from "src/graphql/mutations";

const CreateEditContract = ({
  setNavigation,
  id,
  currentOffer,
  isMobile,
  reloadOffer,
}) => {
  const [textFields, setTextFields] = useState([
    { text: "", fulfilled: false },
  ]);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [payment, setPayment] = useState("");
  const [addDeliverablesScreen, setAddDeliverablesScreen] = useState(false);
  const [mobileDeliverable, setMobileDeliverable] = useState("");

  const [addPaymentScreen, setAddPaymentScreen] = useState(false);
  const [mobilePayment, setMobilePayment] = useState("");

  const [addNotesScreen, setAddNotesScreen] = useState(false);
  const [mobileNotes, setMobileNotes] = useState("");

  const [addDateScreen, setAddDateScreen] = useState(false);
  const [mobileDate, setMobileDate] = useState("");

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [addDeliverablesScreen, addPaymentScreen, addNotesScreen, addDateScreen]);

  useEffect(() => {
    const getCurrentUser = async () => {
      const curr = await Auth.currentAuthenticatedUser();
      setCurrentUser(curr);
    };
    getCurrentUser();
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const disabledButton = () => {
    if (currentUser == null) {
      return true;
    }
    if (currentOffer.creator === currentUser.attributes.sub) {
      return false;
    }

    if (currentOffer.brand === currentUser.attributes.sub) {
      return true;
    }
    return true;
  };

  useEffect(() => {
    if (currentOffer.requirements) {
      console.log(currentOffer.requirements);
      const formattedData = currentOffer.requirements.map((item) => {
        const keyValuePairs = item.match(/(\w+)=([^,]+)/g);
        const obj = {};
        keyValuePairs.forEach((keyValue) => {
          const [key, value] = keyValue.split("=");
          obj[key] = value;
        });
        return obj;
      });
      console.log(formattedData);
      formattedData.push({ text: "", fulfilled: false });
      setTextFields(formattedData);
    }
    if (currentOffer.payment) {
      setPayment(currentOffer.payment);
      setMobilePayment(currentOffer.payment);
    }
    if (currentOffer.date) {
      setSelectedDate(moment(currentOffer.date).toDate());
    }
    if (currentOffer.additionalNotes) {
      setAdditionalNotes(currentOffer.additionalNotes);
      setMobileNotes(currentOffer.additionalNotes);
    }
  }, []);

  const handleChange = (index, event) => {
    if (event.target.value === "" && index !== textFields.length - 1) {
      deleteItem(index);
      return;
    }
    console.log("hi " + index);
    const updatedFields = [...textFields];
    updatedFields[index] = { text: event.target.value, fulfilled: false };

    // Add a new text field if the last one is typed into
    if (index === textFields.length - 1 && event.target.value !== "") {
      updatedFields.push({ text: "", fulfilled: false });
    }

    setTextFields(updatedFields);
  };

  const deleteItem = (index) => {
    const updatedItems = [...textFields];
    updatedItems.splice(index, 1);
    setTextFields(updatedItems);
  };

  const saveButton = async () => {
    if (disabledButton()) {
      return;
    }
    if (addDeliverablesScreen) {
      if (mobileDeliverable === "") {
        return;
      }
      const updatedFields = [...textFields];
      updatedFields[textFields.length - 1] = {
        text: mobileDeliverable,
        fulfilled: false,
      };

      updatedFields.push({ text: "", fulfilled: false });

      setMobileDeliverable("");

      setTextFields(updatedFields);
      return;
    }

    if (addPaymentScreen) {
      setPayment(mobilePayment);
      setAddPaymentScreen(false);
      return;
    }

    if (addNotesScreen) {
      setAdditionalNotes(mobileNotes);
      setAddNotesScreen(false);
      return;
    }

    if (addDateScreen) {
      setSelectedDate(moment(mobileDate).toDate());
      setAddDateScreen(false);
      return;
    }

    console.log("------");
    textFields.map((value, index) => {
      console.log("deliverable " + value);
    });
    console.log("payment: " + payment);
    console.log("date: " + selectedDate);
    console.log("additional notes: " + additionalNotes);

    let formattedData = textFields;

    if (
      formattedData.length > 0 &&
      formattedData[formattedData.length - 1].text === ""
    ) {
      formattedData = formattedData.slice(0, formattedData.length - 1);
    }

    const input = {
      id: currentOffer.id,
      requirements: formattedData,
      payment: payment,
      date: selectedDate,
      additionalNotes: additionalNotes,
      contractState: "Pending",
      lastUpdate: moment(moment.now()),
    };

    API.graphql(graphqlOperation(updateNegotiation, { input: input }))
      .then((response) => {
        console.log(response);
        reloadOffer();
        setNavigation("main");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(textFields);
  }, [textFields]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        paddingTop: 22,
        paddingLeft: "2.5%",
        paddingRight: "2.5%",
        paddingBottom: 35,
      }}
    >
      {addDeliverablesScreen ? (
        <>
          <Box
            style={{
              height: 25,
              borderRadius: 15,
              backgroundColor: "#D1D8E0",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box width={10} />
            <DeliverbleIcon height={12} width={11} color={"#1A3A63"} />
            <Box width={6} />
            <Typography
              style={{
                fontSize: 12,
                fontFamily: "Inter",
                fontWeight: 600,
                color: "#1A3A63",
              }}
            >
              Add Deliverable
            </Typography>
          </Box>
          <Box height={20} />
          <input
            ref={inputRef}
            ontouchstart=""
            id="deliverableInputID"
            rows="1"
            value={mobileDeliverable}
            onChange={(event) => setMobileDeliverable(event.target.value)}
          ></input>
        </>
      ) : addPaymentScreen ? (
        <>
          <Box
            style={{
              height: 25,
              borderRadius: 15,
              backgroundColor: "#D1D8E0",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box width={10} />
            <PaymentIcon height={8.3} width={12} color={"#1A3A63"} />
            <Box width={6} />
            <Typography
              style={{
                fontSize: 12,
                fontFamily: "Inter",
                fontWeight: 600,
                color: "#1A3A63",
              }}
            >
              Payment in USD
            </Typography>
          </Box>
          <Box height={20} />
          <input
            ref={inputRef}
            ontouchstart=""
            id="deliverableInputID"
            rows="1"
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            value={mobilePayment}
            onChange={(event) => setMobilePayment(event.target.value)}
          ></input>
        </>
      ) : addNotesScreen ? (
        <>
          <Box
            style={{
              height: 25,
              borderRadius: 15,
              backgroundColor: "#D1D8E0",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box width={10} />
            <PaymentIcon height={8.3} width={12} color={"#1A3A63"} />
            <Box width={6} />
            <Typography
              style={{
                fontSize: 12,
                fontFamily: "Inter",
                fontWeight: 600,
                color: "#1A3A63",
              }}
            >
              Additional Notes
            </Typography>
          </Box>
          <Box height={20} />
          <textarea
            ref={inputRef}
            ontouchstart=""
            id="bioInputID"
            rows="3"
            value={mobileNotes}
            onChange={(event) => setMobileNotes(event.target.value)}
          ></textarea>
        </>
      ) : addDateScreen ? (
        <>
          <Box
            style={{
              height: 25,
              borderRadius: 15,
              backgroundColor: "#D1D8E0",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box width={10} />
            <PaymentIcon height={8.3} width={12} color={"#1A3A63"} />
            <Box width={6} />
            <Typography
              style={{
                fontSize: 12,
                fontFamily: "Inter",
                fontWeight: 600,
                color: "#1A3A63",
              }}
            >
              Fulfillment Due Date
            </Typography>
          </Box>
          <Box height={20} />
          <input
            ref={inputRef}
            ontouchstart=""
            id="deliverableInputID"
            type="date"
            style={{
              minWidth: "95%",
              width: "100%",
              boxSizing: "border-box",
              backgroundColor: "white",
            }}
            value={mobileDate}
            onChange={(event) => setMobileDate(event.target.value)}
          ></input>
        </>
      ) : (
        <Box
          style={{
            width: "100%",
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "scroll",
          }}
        >
          <Box style={{ width: "100%" }}>
            <Box
              style={{
                height: 25,
                borderRadius: 15,
                backgroundColor: "#D1D8E0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box width={10} />
              <DeliverbleIcon height={12} width={11} color={"#1A3A63"} />
              <Box width={6} />
              <Typography
                style={{
                  fontSize: 12,
                  fontFamily: "Inter",
                  fontWeight: 600,
                  color: "#1A3A63",
                }}
              >
                Deliverables
              </Typography>
            </Box>
            <Box height={23} />

            <Box style={{ flexDirection: "column", width: "100%" }}>
              {textFields.map((value, index) => {
                console.log(index);
                return (
                  <Box key={index}>
                    <DeliverableItem
                      index={index}
                      value={value.text}
                      handleChange={handleChange}
                      deleteItem={deleteItem}
                      isMobile={isMobile}
                      setAddDeliverablesScreen={setAddDeliverablesScreen}
                    />
                    <Box height={10} />
                  </Box>
                );
              })}
            </Box>

            <Box height={36} />
            <Box style={{ display: "flex", width: "100%" }}>
              <Box
                style={{
                  height: 25,
                  borderRadius: 15,
                  width: "45%",
                  backgroundColor: "#D1D8E0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box width={10} />
                <PaymentIcon height={8.3} width={12} color={"#1A3A63"} />
                <Box width={6} />
                <Typography
                  style={{
                    fontSize: 12,
                    fontFamily: "Inter",
                    fontWeight: 600,
                    color: "#1A3A63",
                  }}
                >
                  Payment
                </Typography>
              </Box>
              <Box flex={1} />
              <Box
                style={{
                  height: 25,
                  borderRadius: 15,
                  width: "45%",
                  backgroundColor: "#D1D8E0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontSize: 12,
                    fontFamily: "Inter",
                    fontWeight: 600,
                    color: "#1A3A63",
                  }}
                >
                  Fulfillment Date
                </Typography>
              </Box>
            </Box>

            <Box height={23} />

            <Box style={{ display: "flex", width: "100%" }}>
              <Box
                border={1}
                onClick={() => {
                  if (isMobile) setAddPaymentScreen(true);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 15,
                  height: 40,
                  borderRadius: 15,
                  width: "45%",
                  borderColor: "#1A3A63",
                }}
              >
                <TextField
                  value={payment}
                  onChange={(event) => setPayment(event.target.value)}
                  variant="standard"
                  style={{ flex: 1 }}
                  InputProps={{
                    startAdornment: <div style={{ color: "#DDDDDD" }}>$</div>,
                    style: {
                      fontWeight: 500,
                      fontSize: 14,
                      fontFamily: "Outfit",
                    },
                    disableUnderline: true,
                  }}
                />
              </Box>
              <Box flex={1} />
              <Box
                border={1}
                onClick={() => {
                  if (isMobile) setAddDateScreen(true);
                }}
                style={{
                  display: "flex",
                  height: 40,
                  borderRadius: 15,
                  width: "45%",
                  borderColor: "#1A3A63",
                  alignItems: "center",
                  paddingLeft: 10,
                  overflow: "hidden",
                }}
              >
                <CalendarMonthIcon
                  style={{ color: "#1A3A63", marginRight: 10 }}
                />
                <Box width={10} />
                <DatePicker
                  style={{
                    border: 0,
                    outline: 0,
                    width: "25%",
                    backgroundColor: "red",
                  }}
                  calendarIcon={null}
                  placeholder="Select a date"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  customInput={
                    <input
                      placeholder="ih"
                      style={{
                        border: 0,
                        outline: 0,
                        fontSize: 14,
                        color: "#1A3A63",
                        fontWeight: 500,
                        fontFamily: "Outfit",
                      }}
                    />
                  }
                />
              </Box>
            </Box>
            <Box height={48} />
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "Inter",
                fontWeight: 600,
                color: "#1A3A63",
              }}
            >
              Additional Notes
            </Typography>
            <Box height={23} />
            <Box
              border={1}
              style={{
                width: "100%",
                height: 110,
                borderRadius: 15,
                borderColor: "#1A3A63",
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 5,
                paddingBottom: 5,
                overflow: "scroll",
              }}
            >
              <TextField
                value={additionalNotes}
                onClick={() => {
                  if (isMobile) setAddNotesScreen(true);
                }}
                onChange={(event) => setAdditionalNotes(event.target.value)}
                multiline
                variant="standard"
                placeholder="Add notes"
                style={{ flex: 1, width: "100%" }}
                InputProps={{
                  style: {
                    fontWeight: 500,
                    fontSize: 14,
                    fontFamily: "Outfit",
                  },
                  disableUnderline: true,
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
      <Box height={40} />
      <Box style={{ display: "flex", height: 35, width: "100%" }}>
        <Box
          onClick={() => {
            if (addDeliverablesScreen) {
              setAddDeliverablesScreen(false);
            } else if (addPaymentScreen) {
              setAddPaymentScreen(false);
            } else if (addNotesScreen) {
              setAddNotesScreen(false);
            } else if (addDateScreen) {
              setAddDateScreen(false);
            } else {
              setNavigation("main");
            }
          }}
          style={{
            width: "45%",
            height: 35,
            borderRadius: 15,
            borderColor: "#1A3A63",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#D1D8E0",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography
            style={{
              fontSize: 16,
              fontFamily: "Outfit",
              fontWeight: 500,
              color: "#1A3A63",
            }}
          >
            Back
          </Typography>
        </Box>
        <Box flex={1} />
        <Box
          onMouseDown={(e) => {
            if (isMobile) {
              e.preventDefault();
            }
            saveButton();
          }}
          // onClick={() => saveButton()}
          style={{
            width: "45%",
            height: 35,
            borderRadius: 15,
            backgroundColor: disabledButton() ? "#F1EAFF" : "#E2D4FF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography
            style={{
              fontSize: 16,
              fontFamily: "Outfit",
              fontWeight: 500,
              color: disabledButton() ? "#B6A3DC" : "#795CB8",
            }}
          >
            {disabledButton()
              ? "Creator Only"
              : addPaymentScreen || addNotesScreen || addDateScreen
              ? "Save"
              : addDeliverablesScreen
              ? "Add"
              : "Offer"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default CreateEditContract;
