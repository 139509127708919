const GUARD = "DELETE MY ACCOUNT";

export const DELETE_STRINGS = {
  DELETE_DESCRIPTION:
    "Delete all your data and account details. This action is irreversible and we will not be able to recover this account should you change your mind.",
  DELETE_TITLE: "Delete your account",
  DELETE_GUARD_PROMPT: `Enter "${GUARD}" to continue`,
  DELETE_GUARD: GUARD,
  DELETE_BUTTON: "Delete Account",
};

export const ACCOUNT_STRINGS = {
  EMAIL_HEADER: "Email",
  EMAIL_BODY: "The email address associated with this account.",
  COMPANY_HEADER: "Company Name",
  COMPANY_BODY:
    "Primary name that will be shown to customers / advertisers. Changing this will not update the name on existing offers.",
  PROFILE_PIC_HEADER: "Profile Picture",
  PROFILE_PIC_BODY: "Change the picture associated with your account.",
  PROFILE_PIC_BUTTON: "Change",
};

export const SUPPORT_STRINGS = {
  EMAIL: "support@proffera.com",
  SUPPORT_BODY:
    "For all feedback, queries or concerns please reach out and our support team will get back to you as soon as possible.",
};
