function LogoNoTextSVG(props) {
  return (
    <svg
      width="476"
      height="453"
      viewBox="0 0 476 227" // Modified viewBox attribute
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx="69.9621"
        cy="73.2042"
        rx="69.9621"
        ry="73.2042"
        transform="matrix(0.934681 -0.355488 0.320375 0.947291 317.361 108.52)"
        fill="#1A3A63"
        fill-opacity="0.03"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.69418 205.344C-13.0849 149.817 16.1054 87.9125 70.8926 67.0753L107.336 53.2147C101.357 69.2666 100.641 87.6018 106.567 105.124C119.52 143.423 159.297 163.335 195.413 149.599C231.528 135.863 250.305 93.6813 237.352 55.3827C231.426 37.8604 219.885 24.1868 205.648 15.8235L229.176 6.87528C283.963 -13.9619 343.6 14.159 362.379 69.6853L362.692 70.6106C325.375 92.7112 307.493 140.798 322.399 184.872C337.305 228.947 379.931 254.021 421.987 245.933L422.299 246.856C441.078 302.382 411.888 364.287 357.1 385.124L243.922 428.169C231.061 408.853 215.508 392.192 197.944 379.565C165.211 356.031 126.22 347.364 90.1566 361.08C80.4293 364.78 71.5134 369.88 63.4599 376.146L5.69418 205.344ZM219.179 437.58L198.817 445.324C150.304 463.775 97.9884 443.839 73.6042 400.48C80.9962 393.805 89.2928 388.642 98.3172 385.21C124.737 375.161 155.23 380.769 183.089 400.798C196.432 410.391 208.647 422.916 219.179 437.58Z"
        fill="#1A3A63"
        fill-opacity="0.03"
      />
    </svg>
  );
}
export default LogoNoTextSVG;
