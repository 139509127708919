import { Box, TextField } from "@mui/material";

const DeliverableItem = ({
  index,
  value,
  handleChange,
  deleteItem,
  isMobile,
  setAddDeliverablesScreen,
}) => {
  return (
    <Box
      border={1}
      onClick={() => isMobile && value === "" && setAddDeliverablesScreen(true)}
      style={{
        display: "flex",
        alignItems: "center",
        flex: 1,
        height: 40,
        width: "100%",
        borderRadius: 10,
        borderColor: "#1A3A63",
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 11,
        paddingBottom: 11,
        backgroundColor: value !== "" ? "#F3F5F7" : null,
      }}
    >
      <TextField
        variant="standard"
        value={value}
        onChange={(event) => handleChange(index, event)}
        placeholder="Add item"
        style={{ flex: 1 }}
        InputProps={{
          style: {
            fontSize: 14,
            fontFamily: "Outfit",
            fontWeight: 600,
          },
          disableUnderline: true,
        }}
      />
      {value === "" ? (
        <Box width={25} />
      ) : (
        <Box
          onClick={() => deleteItem(index)}
          border={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 25,
            height: 25,
            borderRadius: 1000,
            backgroundColor: "#D9D9D9",
            borderColor: "#BBB",
          }}
        >
          <Box
            style={{
              width: 12,
              height: 2,
              borderRadius: 2,
              backgroundColor: "#1A3A63",
            }}
          />
        </Box>
      )}
    </Box>
  );
};
export default DeliverableItem;
