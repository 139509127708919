import * as React from "react";

function LandingNotificationIcon(props) {
  return (
    <svg
      width="55"
      height="54"
      viewBox="0 0 55 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3084 4.37538L11.3641 0.415385C4.74423 5.48308 0.386159 13.2923 0 22.1538H5.51655C5.93029 14.8154 9.68155 8.39077 15.3084 4.37538ZM49.4835 22.1538H55C54.5863 13.2923 50.2282 5.48308 43.6359 0.415385L39.7192 4.37538C45.2909 8.39077 49.0697 14.8154 49.4835 22.1538ZM44.0497 23.5385C44.0497 15.0369 39.5261 7.92 31.6374 6.03692V4.15385C31.6374 1.85538 29.7894 0 27.5 0C25.2106 0 23.3626 1.85538 23.3626 4.15385V6.03692C15.4463 7.92 10.9504 15.0092 10.9504 23.5385V37.3846L5.4338 42.9231V45.6923H49.5662V42.9231L44.0497 37.3846V23.5385ZM27.5 54C27.8862 54 28.2447 53.9723 28.6033 53.8892C30.3962 53.5015 31.8581 52.2831 32.5752 50.6215C32.8511 49.9569 32.989 49.2369 32.989 48.4615H21.9559C21.9835 51.5077 24.4383 54 27.5 54Z"
        fill="#795CB8"
      />
    </svg>
  );
}

export default LandingNotificationIcon;
