import React from "react";
import { Box, Typography } from "@mui/material";
import LandingContractIcon from "src/Images/LandingContractIcon";
import LandingNotificationIcon from "src/Images/LandingNotificationIcon";
import msgIphone from "src/Images/iphonetemp.png";
import LandingLeadIcon from "src/Images/LandingLeadIcon";
import LandingCloseIcon from "src/Images/LandingCloseIcon";

const NegotiateDeal = () => {
  return (
    <Box style={{ height: 650 }}>
      <Box height={50} />

      <Box
        style={{
          width: "100%",
          maxWidth: 1440,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flex: 1,
            width: 450,
            height: "90%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LandingContractIcon />
            <Box height={6} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 20,
                fontWeight: 700,
                color: "#795CB8",
                lineHeight: 1,
              }}
            >
              Engage with Brands
            </Typography>
            <Box height={14} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 18,
                fontWeight: 500,
                color: "#57616F",
                lineHeight: 1,
                width: 300,
              }}
            >
              Exchange messages with brands to secure business.
            </Typography>
          </Box>
          <Box height={80} />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LandingNotificationIcon />
            <Box height={10} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 20,
                fontWeight: 700,
                color: "#795CB8",
                lineHeight: 1,
              }}
            >
              Push Notifications
            </Typography>
            <Box height={14} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 18,
                fontWeight: 500,
                color: "#57616F",
                lineHeight: 1,
                width: 300,
              }}
            >
              Receive notifications to track the progress of your leads.
            </Typography>
          </Box>
          <Box height={90} />
        </Box>
        <img src={msgIphone} alt={""} width={295} />
        <Box
          style={{
            display: "flex",
            flex: 1,
            width: 450,
            height: "90%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LandingLeadIcon />
            <Box height={6} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 20,
                fontWeight: 700,
                color: "#795CB8",
                lineHeight: 1,
              }}
            >
              Sort Leads
            </Typography>
            <Box height={14} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 18,
                fontWeight: 500,
                color: "#57616F",
                lineHeight: 1,
                width: 250,
              }}
            >
              View all your leads in one place.
            </Typography>
          </Box>
          <Box height={80} />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LandingCloseIcon />
            <Box height={10} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 20,
                fontWeight: 700,
                color: "#795CB8",
                lineHeight: 1,
              }}
            >
              Create & Close
            </Typography>
            <Box height={14} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 18,
                fontWeight: 500,
                color: "#57616F",
                lineHeight: 1,
                width: 300,
              }}
            >
              Seamlessly transition from discussions to closing
            </Typography>
          </Box>
          <Box height={90} />
        </Box>
      </Box>
    </Box>
  );
};
export default NegotiateDeal;
