import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledBox = styled(Box)`
  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
  }
`;

const UserInfo = ({ username, userType, onClick, profilePic }) => (
  <StyledBox onClick={onClick} display="flex" style={{ cursor: "pointer" }}>
    <Box display="flex" flexDirection="column">
      <Typography
        fontWeight={600}
        fontFamily="Outfit"
        fontSize={16}
        align="right"
        color="black"
      >
        {username}
      </Typography>
      <Typography
        fontWeight={500}
        fontFamily="Outfit"
        fontSize={14}
        color="#3F3F3F"
        align="right"
      >
        {userType}
      </Typography>
    </Box>
    <Box width={5} />
    <img
      style={{
        width: 50,
        height: 50,
        borderRadius: 1000,
      }}
      src={profilePic ?? ""}
      alt=""
      loading="lazy"
    />
  </StyledBox>
);
export default UserInfo;
