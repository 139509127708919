import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Modal,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Slide,
} from "@mui/material";
import { MoreOutlined } from "@ant-design/icons";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { GetStatusButtonData } from "./logic/StatusLabelLogic";
import backIcon from "./Images/backarrow.png";
import { API, Storage } from "aws-amplify";
import { getUser } from "./graphql/queries";

import { MessageList } from "./MessageList";
import moment from "moment";
import InBorderTitle from "./components/InBorderTitle";
import ConfirmationPopup from "./Modals/ConfirmationPopup";
import ContractOfferProgress from "./Screens/ContractOfferProgress";

moment.relativeTimeThreshold("s", 60);
moment.relativeTimeThreshold("m", 60);
moment.relativeTimeThreshold("h", 24);
moment.relativeTimeThreshold("d", 31);
moment.relativeTimeThreshold("M", 12);

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const TestingOffer = (props) => {
  const [statusPopupOpen, setPopupOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(
    false
    // props.currentUser?.attributes.NewToActiveInfo &&
    //   !props.currentOffer?.attributes.AdvertiserFlag
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [heldItem, setHeldItem] = React.useState(null);
  // const [disputeOpen, setDisputeOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [profilePic, setProfilePic] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const findProfilePic = async () => {
    if (props.currentOffer != null) return;
    const offer = props.currentOffer;
    const response = await API.graphql({
      query: getUser,
      variables: {
        id: props.currentUser.isBrand ? offer.creator : offer.brand,
      },
    });

    const profilePic = response.data.getUser.profilePic;

    if (profilePic != null) {
      const url = await Storage.get(profilePic.key, { key: profilePic.bucket });
      setProfilePic(url);
    }
  };

  useEffect(() => {
    findProfilePic();
  });

  const updateUserViewed = async () => {
    //   const Offer = new Parse.Object("Channel");
    //   Offer.set("objectId", props.currentOffer?.id);
    //   if (props.currentOffer?.attributes.owner.id === props.currentUser?.id)
    //     Offer.set("customerLastViewed", new Date());
    //   else Offer.set("advertiserLastViewed", new Date());
    //   try {
    //     await Offer.save();
    //   } catch (error) {
    //     console.log(error);
    //   }
  };

  const { statusBackColor, statusTextColor } = GetStatusButtonData(
    props.currentOffer
    // props.currentOffer?.attributes.Status,
    // props.currentOffer?.attributes.owner === props.currentUser?.id
  );

  const triggerCustomerFlag = () => {
    //   const _user = new Parse.User();
    //   const _query = new Parse.Query(_user);
    //   console.log("We here");
    //   // Finds the user by its ID
    //   _query.get(props.currentUser?.id).then((user) => {
    //     // Updates the data we want
    //     user.set("NewToActiveInfo", false);
    //     // Saves the user with the updated data
    //     user
    //       .save()
    //       .then((response) => {
    //         console.log("Updated user", response);
    //       })
    //       .catch((error) => {
    //         console.error("Error while updating user", error);
    //       });
    //   });
  };

  const findOfferStateText = () => {
    if (
      props.currentOffer.contractState === "NoOffer" ||
      props.currentOffer.contractState === "Editing"
    ) {
      return "Pre Offer";
    } else if (props.currentOffer.contractState === "Pending") {
      return "Offer Pending";
    } else if (
      props.currentOffer.contractState === "Accepted" &&
      !props.currentOffer.isPaid
    ) {
      return "Awaiting Payment";
    } else if (
      props.currentOffer.isPaid &&
      !props.currentOffer.delivarablesFinalized
    ) {
      return "Awaiting Fulfillment";
    } else if (
      props.currentOffer.isPaid &&
      props.currentOffer.delivarablesFinalized &&
      props.currentOffer.negotiationOpen
    ) {
      return "Fulfilled";
    } else if (
      props.currentOffer.isPaid &&
      props.currentOffer.delivarablesFinalized &&
      !props.currentOffer.negotiationOpen
    ) {
      return "Closed";
    } else {
      return "Unknown";
    }
  };

  const budgetIndexToValue = () => {
    var budgetIndex = 0;

    if (budgetIndex === 0) {
      return "0-$100";
    } else if (budgetIndex === 1) {
      return "100-$500";
    } else if (budgetIndex === 2) {
      return "500-$1000";
    } else if (budgetIndex === 3) {
      return "1000-$5000";
    } else if (budgetIndex === 4) {
      return "5000+";
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const RunHeldFunction = () => {
    heldItem.stopPropagation();
    props.EndNegotiation(props.currentOffer.id);
  };

  if (!props.currentOffer) return <Box></Box>;

  return (
    <Box display={"flex"} flexDirection={"column"} flex={1}>
      <ConfirmationPopup
        setConfirmationOpen={setConfirmationOpen}
        confirmationOpen={confirmationOpen}
        RunHeldFunction={triggerCustomerFlag}
        confirmationText={
          'This offer will remain in "New Leads" until engaged by the advertiser. Engagement must be in the form of a message or an action on offer. '
        }
        altConfirmationText={
          'This offer will remain in "New Leads" until engaged by the advertiser. Engagement must be in the form of a message or an action to make / on a made offer. Offers will then be moved to "Active Contracts". '
        }
        preventConfirm={true}
      />

      {/* More actions modals */}
      <ConfirmationPopup
        setConfirmationOpen={setDeleteConfirmationOpen}
        confirmationOpen={deleteConfirmationOpen}
        RunHeldFunction={RunHeldFunction}
        confirmationText={
          "Are you sure you want to delete this offer? You will not be able to undo this action."
        }
        altConfirmationText={
          "You cannot delete an offer that has already been accepted or that is in dispute."
        }
        showCheckBox={false}
        preventConfirm={
          false
          // props.currentOffer?.attributes.Status === "Awaiting Fulfillment" ||
          // props.currentOffer?.attributes.Status === "Fulfilled" ||
          // props.currentOffer?.attributes.Status === "In Dispute"
        }
      />
      {/* {props.currentOffer && props.currentUser && (
        // <TextFormModal
        //   offer={props.currentOffer}
        //   currentUser={props.currentUser}
        //   open={disputeOpen}
        //   setOpen={setDisputeOpen}
        //   activeDispute={props.currentOffer.attributes.Status === "In Dispute"}
        // />
      )} */}
      {/*  */}

      <Modal open={statusPopupOpen}>
        <Slide in={statusPopupOpen} direction={"up"} timeout={300}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              paddingBottom: 20,
              backgroundColor: props.isMobile ? "white" : "rgba(0,0,0,0)",
            }}
          >
            <ContractOfferProgress
              setPopupOpen={setPopupOpen}
              id={props.currentOffer?.id}
              currentOffer={props.currentOffer}
              isMobile={props.isMobile}
            />
            {/* <StatusPopup
              updateUserViewed={updateUserViewed}
              setPopupOpen={setPopupOpen}
              id={props.currentOffer?.id}
              currentUser={props.currentUser}
              isMobile={props.isMobile}
              currentOffer={props.currentOffer}
            /> */}
          </Box>
        </Slide>
      </Modal>
      {props.isMobile || true ? (
        <Box
          borderBottom={2}
          style={{
            borderColor: "#F4F6FF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: -10,
            height: 55,
            marginLeft: -15,
            marginRight: -15,
            paddingLeft: 15,
            paddingRight: 15,
            paddingBottom: props.isMobile ? 10 : 10,
          }}
        >
          <img
            onClick={() => props.setCurrentChannel(null)}
            style={{
              width: 32,
              height: 32,
              borderRadius: 1000,
            }}
            src={backIcon ?? ""}
            alt={""}
            loading="lazy"
          />
          <Box width={10} />
          {profilePic ? (
            <img
              style={{
                width: 40,
                height: 40,
                borderRadius: 1000,
              }}
              src={profilePic ?? ""}
              alt={""}
              loading="lazy"
            />
          ) : (
            <Box
              style={{
                width: 40,
                height: 40,
                borderRadius: 1000,
                backgroundColor: "#E2D4FF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                fontWeight={600}
                color={"#795CB8"}
                fontSize={20}
                fontFamily={"Nunito"}
              >
                {props.currentUser?.isBrand
                  ? props.currentOffer?.creatorDisplayName
                      .charAt(0)
                      .toUpperCase()
                  : props.currentOffer?.brandDisplayName
                      .charAt(0)
                      .toUpperCase()}
              </Typography>
            </Box>
          )}
          <Box width={10} />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: 40,
            }}
          >
            <Typography
              style={{
                fontWeight: 600,
                fontFamily: "poppins",
                fontSize: 20,
              }}
            >
              {props.currentUser?.isBrand
                ? props.currentOffer?.creatorDisplayName
                : props.currentOffer?.brandDisplayName}
            </Typography>
            <Typography
              style={{
                marginTop: -3,
                fontWeight: 600,
                fontFamily: "Inter",
                fontSize: 10,
                color: "#909090",
              }}
            >
              Budget {budgetIndexToValue()}
            </Typography>
          </Box>
          <Box flex={1} />
          <Box
            onClick={() => setPopupOpen(true)}
            style={{
              height: 40,
              backgroundColor: "#E2D4FF",
              paddingLeft: 20,
              paddingRight: 20,
              borderWidth: 1,
              borderColor: "#C0DFFC",
              borderRadius: 15,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "Outfit",
                fontSize: 14,
                fontWeight: 600,
                color: "#795CB8",
              }}
            >
              {"Offer"}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          style={{
            height: 50,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box border={1} borderColor={"#CCC"} style={{ borderRadius: 10 }}>
            <IconButton
              onClick={() => props.setCurrentChannel(null)}
              style={{
                height: 50,
                width: 50,
                borderRadius: 10,
                borderWidth: 1,
              }}
            >
              <ChevronLeftIcon color={"#4D4D4D"} />
            </IconButton>
          </Box>
          <Box style={{ width: 20 }} />
          <Box
            border={1}
            borderColor={"#CCC"}
            style={{
              height: "100%",
              borderRadius: 10,
              borderWidth: 1,
              display: "flex",
              alignItems: "center",
              paddingLeft: 10,
              paddingRight: 10,
              position: "relative",
              minWidth: 120,
            }}
          >
            <InBorderTitle length={80} name={"Company Name"} />
            <Typography
              paddingX={3}
              fontWeight={600}
              fontSize={18}
              fontFamily={"Outfit"}
              color={"#4D4D4D"}
            >
              {props.currentUser?.isBrand
                ? props.currentOffer?.creatorDisplayName
                : props.currentOffer?.brandDisplayName}
            </Typography>
          </Box>
          <Box style={{ width: 20 }} />
          <Box
            border={1}
            borderColor={"#CCC"}
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              borderRadius: 10,
              borderWidth: 1,
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 10,
              paddingRight: 10,
              position: "relative",
            }}
          >
            <InBorderTitle length={37} name={"Budget"} />
            <Typography
              paddingX={5}
              fontSize={18}
              color={"#4D4D4D"}
              fontWeight={600}
              fontFamily={"Outfit"}
            >
              ${props.currentOffer?.payment ?? budgetIndexToValue()}
            </Typography>
          </Box>
          <Box style={{ width: 20 }} />
          <Box
            border={1}
            borderColor={"#CCC"}
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              borderRadius: 10,
              borderWidth: 1,
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 10,
              paddingRight: 10,
              position: "relative",
            }}
          >
            <InBorderTitle length={67} name={"Offer Status"} />
            <Box
              px={5}
              style={{
                height: 25,
                borderRadius: 10,
                backgroundColor: statusBackColor,
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                color={statusTextColor}
                fontFamily={"Roboto"}
                fontWeight={600}
                fontSize={14}
              >
                {findOfferStateText()}
              </Typography>
            </Box>
          </Box>
          <Box width={20} />
          <Box border={1} borderColor={"#CCC"} style={{ borderRadius: 10 }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClick(e);
              }}
              border={1}
              style={{
                backgroundColor: "white",
                height: 50,
                width: 25,
                borderRadius: 10,
                borderColor: "#D9D9D9",
                borderWidth: 1,
              }}
            >
              <MoreOutlined style={{ fontSize: 30 }} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={(e) => {
                handleClose();
                e.stopPropagation(e);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  setHeldItem(e);
                  setDeleteConfirmationOpen(true);
                  e.stopPropagation(e);
                }}
              >
                Delete Offer
              </MenuItem>
              {/* <MenuItem
                onClick={(e) => {
                  e.stopPropagation(e);
                  handleClose();
                  // setDisputeOpen(true);
                }}
              >
                Lodge Dispute
              </MenuItem> */}
            </Menu>
          </Box>
          <Box style={{ flex: 1 }} />
          <Button
            onClick={() => setPopupOpen(true)}
            border={1}
            borderColor={"#CCC"}
            style={{
              border: "1px solid #CCCCCC",
              borderRadius: 10,
              height: 50,
              paddingRight: 20,
              paddingLeft: 20,
            }}
          >
            <Typography fontFamily={"Outfit"} fontWeight={600} fontSize={14}>
              View Offer
            </Typography>
          </Button>
        </Box>
      )}
      {/* {props.isMobile ? (
        <Box
          marginTop={2}
          height={5}
          width={"100%"}
          borderRadius={1000}
          backgroundColor={"#EEE"}
        />
      ) : (
        <Box height={30} width={"100%"} borderBottom={2} borderColor={"#EEE"} />
      )} */}
      <Box
        style={{
          width: "100%",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          paddingTop: props.isMobile ? 0 : 10,
          paddingBottom: 0,
        }}
      >
        <MessageList
          isMobile={props.isMobile}
          updateUserViewed={updateUserViewed}
          currentUser={props.currentUser}
          currentChannel={props.currentOffer}
        />
      </Box>
    </Box>
  );
};
export default TestingOffer;
