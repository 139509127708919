import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/system";
import PropTypes from "prop-types";

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EmailInput = styled("input")`
  height: ${({ isMobile }) => (isMobile ? 40 : 60)}px;
  width: ${({ isMobile }) => (isMobile ? "90%" : 591)}px;
  border-radius: ${({ isMobile }) => (isMobile ? 15 : 20)}px;
  border: 1px solid #ccc;
  padding-left: ${({ isMobile }) => (isMobile ? 15 : 39)}px;
  padding-right: ${({ isMobile }) => (isMobile ? 15 : 39)}px;
  font-weight: 500;
  font-family: Outfit;
  font-size: ${({ isMobile }) => (isMobile ? 16 : 22)}px;
`;

const ResetButton = styled("button")`
  height: ${({ isMobile }) => (isMobile ? 40 : 60)}px;
  width: ${({ isMobile }) => (isMobile ? "90%" : 591)}px;
  border-radius: ${({ isMobile }) => (isMobile ? 15 : 20)}px;
  background-color: #795cb8;
  border-width: 0;
  color: #fff;
  font-family: Outfit;
  font-weight: 500;
  font-size: ${({ isMobile }) => (isMobile ? 16 : 22)}px;
`;

const HomeButton = styled("button")`
  width: 591px;
  height: 60px;
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #ccc;
  color: #795cb8;
  font-family: Outfit;
  font-size: 20px;
`;

const ResetPassword = ({ isMobile }) => {
  const history = useHistory();
  const [emailInput, setEmailInput] = useState("");

  const doRequestPasswordReset = async function () {
    // try {
    //   await Parse.User.requestPasswordReset(emailInput);
    //   alert(
    //     `Success! Please check ${emailInput} to proceed with password reset.`
    //   );
    //   return true;
    // } catch (error) {
    //   alert(`Error! ${error}`);
    //   return false;
    // }
  };

  return (
    <Container>
      <Typography fontWeight={600} fontSize={isMobile ? 24 : 30}>
        Reset Password
      </Typography>
      <Box height={56} />
      <EmailInput
        id="inputID"
        type="email"
        value={emailInput}
        placeholder="Email"
        onChange={(event) => setEmailInput(event.target.value)}
        isMobile={isMobile}
      />
      <Box height={17} />
      <ResetButton onClick={() => doRequestPasswordReset()} isMobile={isMobile}>
        Reset password
      </ResetButton>
      <Box height={20} />
      <HomeButton onClick={() => history.push("/")}>Return to home</HomeButton>
    </Container>
  );
};

ResetPassword.propTypes = {
  isMobile: PropTypes.bool,
};

ResetPassword.defaultProps = {
  isMobile: false,
};

export default ResetPassword;
