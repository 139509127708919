/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateTodo = /* GraphQL */ `
  subscription OnCreateTodo($filter: ModelSubscriptionTodoFilterInput) {
    onCreateTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTodo = /* GraphQL */ `
  subscription OnUpdateTodo($filter: ModelSubscriptionTodoFilterInput) {
    onUpdateTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTodo = /* GraphQL */ `
  subscription OnDeleteTodo($filter: ModelSubscriptionTodoFilterInput) {
    onDeleteTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      username
      email
      profilePic {
        bucket
        key
        region
      }
      isBrand
      displayName
      bio
      showNewUserInfo
      stripeId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      username
      email
      profilePic {
        bucket
        key
        region
      }
      isBrand
      displayName
      bio
      showNewUserInfo
      stripeId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      username
      email
      profilePic {
        bucket
        key
        region
      }
      isBrand
      displayName
      bio
      showNewUserInfo
      stripeId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNegotiation = /* GraphQL */ `
  subscription OnCreateNegotiation(
    $filter: ModelSubscriptionNegotiationFilterInput
  ) {
    onCreateNegotiation(filter: $filter) {
      id
      brand
      brandUsername
      brandDisplayName
      brandLastSeen
      creator
      creatorUsername
      creatorDisplayName
      creatorLastSeen
      lastUpdate
      payment
      budgetRangeIndex
      requirements
      isPaid
      date
      additionalNotes
      contractState
      delivarablesFinalized
      negotiationOpen
      stripePaymentIntent
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNegotiation = /* GraphQL */ `
  subscription OnUpdateNegotiation(
    $filter: ModelSubscriptionNegotiationFilterInput
  ) {
    onUpdateNegotiation(filter: $filter) {
      id
      brand
      brandUsername
      brandDisplayName
      brandLastSeen
      creator
      creatorUsername
      creatorDisplayName
      creatorLastSeen
      lastUpdate
      payment
      budgetRangeIndex
      requirements
      isPaid
      date
      additionalNotes
      contractState
      delivarablesFinalized
      negotiationOpen
      stripePaymentIntent
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNegotiation = /* GraphQL */ `
  subscription OnDeleteNegotiation(
    $filter: ModelSubscriptionNegotiationFilterInput
  ) {
    onDeleteNegotiation(filter: $filter) {
      id
      brand
      brandUsername
      brandDisplayName
      brandLastSeen
      creator
      creatorUsername
      creatorDisplayName
      creatorLastSeen
      lastUpdate
      payment
      budgetRangeIndex
      requirements
      isPaid
      date
      additionalNotes
      contractState
      delivarablesFinalized
      negotiationOpen
      stripePaymentIntent
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onCreateMessage(filter: $filter) {
      id
      negotiationId
      userId
      content
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onUpdateMessage(filter: $filter) {
      id
      negotiationId
      userId
      content
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($filter: ModelSubscriptionMessageFilterInput) {
    onDeleteMessage(filter: $filter) {
      id
      negotiationId
      userId
      content
      type
      createdAt
      updatedAt
    }
  }
`;
