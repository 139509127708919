import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CreateEditContract from "./OfferScreens/CreateEditContract";
import "react-datepicker/dist/react-datepicker.css";
import ReviewSignContract from "./OfferScreens/ReviewSignContract";
import MakePayment from "./OfferScreens/MakePayment";
import FulfillDeliverables from "./OfferScreens/FulfillDeliverables";
import { getNegotiation } from "src/graphql/queries";
import { updateNegotiation } from "src/graphql/mutations";
import { API, Auth, graphqlOperation } from "aws-amplify";
import ConfirmationPopup from "src/Modals/ConfirmationPopup";
import EditIcon from "src/Images/EditIcon";
import ReviewIcon from "src/Images/ReviewIcon";
import PaymentIcon from "src/Images/PaymentIcon";
import DeliverbleIcon from "src/Images/DeliverableIcon";
import FulfillmentIcon from "src/Images/FulfillmentIcon";
import moment from "moment";

const stateColor = (state) => {
  if (state === "future") {
    return {
      backgroundColor: "#FBFCFD",
      textColor: "#B8C2D0",
      borderColor: "#CCCCCC",
    };
  } else if (state === "active") {
    return {
      backgroundColor: "#F0F3F6",
      textColor: "#1A3A63",
      borderColor: "#1A3A63",
    };
  } else {
    return {
      backgroundColor: "#B0BCCB",
      textColor: "#1A3A63",
      borderColor: "#1A3A63",
    };
  }
};

const mobileStateColor = (state) => {
  if (state === "future") {
    return {
      backgroundColor: "#FBFCFD",
      textColor: "#8A9AAE",
      borderColor: "#CCCCCC",
    };
  } else if (state === "active") {
    return {
      backgroundColor: "#F0F3F6",
      textColor: "#1A3A63",
      borderColor: "#1A3A63",
    };
  } else {
    return {
      backgroundColor: "#EFF6F3",
      textColor: "#29E294",
      borderColor: "#29E294",
    };
  }
};

const ContractNavigationButton = ({
  title,
  subTitle,
  mobileButtonText,
  state,
  type,
  setNavigation,
  isMobile,
}) => {
  const [stateColors, setStateColors] = useState({
    backgroundColor: "#F0F3F6",
    textColor: "#1A3A63",
    borderColor: "#CCCCCC",
  });

  const [mobileStateColors, setMobileStateColors] = useState({
    backgroundColor: "#F0F3F6",
    textColor: "#1A3A63",
    borderColor: "#CCCCCC",
  });

  const mobileStateToButtonText = () => {
    if (state === "passed") {
      return "#29E294";
    }
    if (state === "active") {
      return "#795CB8";
    }
    if (state === "future") {
      return "#B4A2DA";
    }
  };

  const mobileStateToButtonBack = () => {
    if (state === "passed") {
      return "#EAFCF4";
    }
    if (state === "active") {
      return "#E2D4FF";
    }
    if (state === "future") {
      return "#EEE7FC";
    }
  };

  const renderIcon = () => {
    if (type === 1) {
      return <EditIcon color={mobileStateColors.textColor} />;
    } else if (type === 2) {
      return <ReviewIcon color={mobileStateColors.textColor} />;
    } else if (type === 3) {
      return <PaymentIcon color={mobileStateColors.textColor} />;
    } else if (type === 4) {
      return <DeliverbleIcon color={mobileStateColors.textColor} />;
    } else if (type === 5) {
      return <FulfillmentIcon color={mobileStateColors.textColor} />;
    }
  };

  useEffect(() => {
    console.log("button state " + state);
    setStateColors(stateColor(state));
    setMobileStateColors(mobileStateColor(state));
  }, [state]);

  if (true) {
    return (
      <Box
        onClick={() => {
          if (state === "active") {
            setNavigation(type);
          } else if (state === "passed" && type === 2) {
            setNavigation(type);
          }
        }}
        border={state === "passed" ? 1 : isMobile ? 0 : 1}
        style={{
          cursor: "pointer",
          position: "relative",
          height: 60,
          width: "95%",
          display: "flex",
          alignItems: "center",
          backgroundColor:
            type === 2 && state === "passed"
              ? "white"
              : mobileStateColors.backgroundColor,
          borderRadius: isMobile ? 15 : 10,
          borderColor: mobileStateColors.borderColor,
          paddingRight: isMobile ? 7 : 20,
          paddingLeft: isMobile ? 7 : 20,
        }}
      >
        {state === "passed" && type !== 2 && (
          <Box
            style={{
              display: "flex",
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(234,252,244,0.4)",
            }}
          />
        )}
        <Box
          style={{
            width: 46,
            height: 46,
            borderRadius: 1000,
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {renderIcon()}
        </Box>
        <Box width={14} />
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            style={{
              fontSize: 12,
              fontFamily: "Inter",
              fontWeight: 800,
              color: mobileStateColors.textColor,
            }}
          >
            {title}
          </Typography>
          <Box height={2} />
          <Typography
            style={{
              fontSize: 10,
              fontFamily: "Inter",
              fontWeight: 600,
              color: "#909090",
            }}
          >
            {subTitle}
          </Typography>
        </Box>
        <Box flex={1} />
        <Box
          border={state === "passed" ? 1 : 0}
          style={{
            cursor: "pointer",
            width: 22,
            height: 46,
            borderRadius: 10,
            borderColor: mobileStateToButtonText(state),
            backgroundColor:
              type === 2 && state === "passed"
                ? "white"
                : mobileStateToButtonBack(state),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              transform: "rotate(-90deg)",
              fontSize: 9,
              fontFamily: "Outfit",
              fontWeight: 700,
              color: mobileStateToButtonText(state),
            }}
          >
            {state === "passed" && type !== 2
              ? "DONE"
              : state === "passed" && type === 2
              ? "VIEW"
              : mobileButtonText}
          </Typography>
        </Box>
      </Box>
    );
  } else {
  }
};

const ContractNavigation = ({
  setPopupOpen,
  setNavigation,
  currentOffer,
  isMobile,
}) => {
  const [cec, setCec] = useState("future");
  const [rsc, setRsc] = useState("future");
  const [mp, setMp] = useState("future");
  const [d, setD] = useState("future");
  const [f, setF] = useState("future");
  const [offer, setOffer] = useState(currentOffer);
  const [confirmationOpen, setConfirmationOpen] = useState(null);

  useEffect(() => {
    updateOffer();
  }, []);

  useEffect(() => {
    console.log(offer.contractState);
    console.log(offer);
    if (offer) {
      if (
        offer.contractState === "NoOffer" ||
        offer.contractState === "Editing"
      ) {
        setCec("active");
        setRsc("future");
        setMp("future");
        setD("future");
        setF("future");
      } else if (offer.contractState === "Pending") {
        setCec("passed");
        setRsc("active");
        setMp("future");
        setD("future");
        setF("future");
      } else if (offer.contractState === "Accepted" && !offer.isPaid) {
        setCec("passed");
        setRsc("passed");
        setMp("active");
        setD("future");
        setF("future");
      } else if (offer.isPaid && !offer.delivarablesFinalized) {
        setCec("passed");
        setRsc("passed");
        setMp("passed");
        setD("active");
        setF("future");
      } else if (
        offer.isPaid &&
        offer.delivarablesFinalized &&
        offer.negotiationOpen
      ) {
        setCec("passed");
        setRsc("passed");
        setMp("passed");
        setD("passed");
        setF("active");
      } else if (
        offer.isPaid &&
        offer.delivarablesFinalized &&
        !offer.negotiationOpen
      ) {
        setCec("passed");
        setRsc("passed");
        setMp("passed");
        setD("passed");
        setF("passed");
      } else {
        setCec("future");
        setRsc("future");
        setMp("future");
        setD("future");
        setF("future");
      }
    }
  }, [offer]);

  const updateOffer = async () => {
    const input = {
      id: offer.id,
    };

    API.graphql({ query: getNegotiation, variables: input })
      .then((response) => {
        setOffer(response.data.getNegotiation);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const markOfferFulfilled = () => {
    const input = {
      id: currentOffer.id,
      negotiationOpen: false,
      lastUpdate: moment(moment.now()),
    };

    API.graphql(graphqlOperation(updateNegotiation, { input: input }))
      .then((response) => {
        console.log(response);
        updateOffer();
        setNavigation("main");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkCanMarkFulfilled = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    if (currentUser == null) {
      return;
    }
    if (currentOffer.creator === currentUser.attributes.sub) {
      return;
    }
    setConfirmationOpen(true);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
        paddingTop: isMobile ? 30 : 59,
        paddingLeft: isMobile ? 0 : 30,
        paddingRight: isMobile ? 0 : 30,
        paddingBottom: 35,
      }}
    >
      <ConfirmationPopup
        setConfirmationOpen={setConfirmationOpen}
        confirmationOpen={confirmationOpen}
        RunHeldFunction={markOfferFulfilled}
        showCheckBox={false}
        confirmationText={
          "Are you sure you want to mark this offer as fulfilled? Once you complete this action it cannot be undone and this offer will be completed."
        }
      />
      <ContractNavigationButton
        type={"1"}
        title={"Create / Edit Contract"}
        subTitle={"Outline a new contract"}
        buttonText={"Create"}
        mobileButtonText={"CREATE"}
        state={cec}
        setNavigation={setNavigation}
        isMobile={isMobile}
      />
      <Box height={20} />
      <ContractNavigationButton
        type={2}
        title={"Review Contract"}
        subTitle={"View offer made by a creator"}
        buttonText={"Review"}
        mobileButtonText={"REVIEW"}
        state={rsc}
        setNavigation={setNavigation}
        isMobile={isMobile}
      />
      <Box height={20} />
      <ContractNavigationButton
        type={3}
        title={"Make Payment"}
        subTitle={"Use Stripe to make payment"}
        buttonText={"Make payment"}
        mobileButtonText={"PAY"}
        state={mp}
        setNavigation={setNavigation}
        isMobile={isMobile}
      />
      <Box height={20} />
      <ContractNavigationButton
        type={4}
        title={"Deliverables"}
        subTitle={"Mark delivarables as fulfilled"}
        buttonText={"Update progress"}
        mobileButtonText={"UPDATE"}
        state={d}
        setNavigation={setNavigation}
        isMobile={isMobile}
      />
      <Box height={20} />
      <ContractNavigationButton
        type={5}
        title={"Fulfilled"}
        subTitle={"Mark the offer as complete"}
        buttonText={"Mark Fulfilled"}
        mobileButtonText={"FINISH"}
        state={f}
        setNavigation={checkCanMarkFulfilled}
        isMobile={isMobile}
      />
      <Box flex={1} />
      {isMobile ? (
        <Box
          onClick={() => setPopupOpen(false)}
          style={{
            width: "95%",
            cursor: "pointer",
            paddingLeft: 75,
            paddingRight: 75,
            height: 35,
            borderRadius: 15,
            backgroundColor: "#D1D8E0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: 16,
              fontFamily: "Outfit",
              fontWeight: 500,
              color: "#1A3A63",
            }}
          >
            Close
          </Typography>
        </Box>
      ) : (
        <Box
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <Box flex={1} />
          <Box
            onClick={() => setPopupOpen(false)}
            style={{
              cursor: "pointer",
              paddingLeft: 75,
              paddingRight: 75,
              height: 45,
              borderRadius: 10,
              backgroundColor: "#1A3A63",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "Outfit",
                fontWeight: 500,
                color: "#FFF",
              }}
            >
              Close
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const findTitle = (navigation) => {
  console.log("this is it " + navigation);
  if (navigation === "main") {
    return "Contract and Offer progress";
  } else if (navigation === "1") {
    return "Create / Edit Contract";
  } else if (navigation === "2") {
    return "Review Contract";
  } else if (navigation === "3") {
    return "Make Payment";
  } else if (navigation === "4") {
    return "Deliverables";
  }
};

const ContractOfferProgress = ({
  setPopupOpen,
  id,
  currentOffer,
  isMobile,
}) => {
  const [navigation, setNavigation] = useState("main");
  const [offer, setOffer] = useState(currentOffer);

  const reloadOffer = async () => {
    API.graphql(graphqlOperation(getNegotiation, { id: id })).then(
      (response) => {
        setOffer(response.data.getNegotiation);
      }
    );
  };

  return (
    <Box
      border={isMobile ? 0 : 2}
      style={{
        display: "flex",
        flexDirection: "column",
        borderColor: "#1A3A63",
        maxWidth: 1290,
        width: isMobile ? "100%" : "95%",
        maxHeight: 930,
        minHeight: isMobile ? "100%" : 630,
        height: isMobile ? "100%" : "95%",
        borderRadius: 10,
        backgroundColor: "#FFF",
      }}
    >
      {/* Header */}
      <Box
        borderBottom={2}
        style={{
          display: "flex",
          height: isMobile ? 45 : 65,
          width: "100%",
          alignItems: "center",
          borderColor: "#D9D9D9",
          paddingLeft: 30,
          paddingRight: 30,
          justifyContent: isMobile ? "center" : "auto",
        }}
      >
        <Typography
          style={{
            fontSize: 16,
            fontFamily: "Inter",
            fontWeight: 700,
            color: "#1A3A63",
          }}
        >
          {findTitle(navigation)}
        </Typography>
      </Box>
      <Box style={{ flex: 1, overflow: "hidden" }}>
        <Box
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {renderSwitchContract(
            navigation,
            setPopupOpen,
            setNavigation,
            id,
            offer,
            isMobile,
            reloadOffer
          )}
        </Box>
      </Box>
    </Box>
  );
};

const renderSwitchContract = (
  navigation,
  setPopupOpen,
  setNavigation,
  id,
  currentOffer,
  isMobile,
  reloadOffer
) => {
  if (navigation === "main") {
    return (
      <ContractNavigation
        setPopupOpen={setPopupOpen}
        setNavigation={setNavigation}
        currentOffer={currentOffer}
        isMobile={isMobile}
        id={id}
      />
    );
  } else if (navigation === "1") {
    return (
      <CreateEditContract
        setNavigation={setNavigation}
        id={id}
        currentOffer={currentOffer}
        isMobile={isMobile}
        reloadOffer={reloadOffer}
      />
    );
  } else if (navigation === "2") {
    return (
      <ReviewSignContract
        setNavigation={setNavigation}
        id={id}
        isMobile={isMobile}
        currentOffer={currentOffer}
      />
    );
  } else if (navigation === "3") {
    return (
      <MakePayment
        setNavigation={setNavigation}
        currentOffer={currentOffer}
        id={id}
        isMobile={isMobile}
      />
    );
  } else if (navigation === "4") {
    return (
      <FulfillDeliverables
        setNavigation={setNavigation}
        currentOffer={currentOffer}
        id={id}
      />
    );
  }
};

export default ContractOfferProgress;
