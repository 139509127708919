import { Box, Typography } from "@mui/material";

const Avatar = ({ profilePic, currentUser }) => {
  if (profilePic) {
    return (
      <img
        style={{
          marginTop: 10,
          width: 40,
          height: 40,
          borderRadius: 1000,
        }}
        src={profilePic}
        alt=""
        loading="lazy"
      />
    );
  }
  return (
    <Box
      style={{
        marginTop: 10,
        width: 40,
        height: 40,
        borderRadius: 1000,
        backgroundColor: "#E2D4FF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        align="center"
        fontWeight={600}
        color={"795CB8"}
        fontFamily={"Nunito"}
        fontSize={18}
      >
        {currentUser?.username.charAt(0).toUpperCase()}
      </Typography>
    </Box>
  );
};
export default Avatar;
