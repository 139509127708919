import NewIcon from "../Images/new.png";
import SideBarItem from "./SideBarItem";
import HourglassIcon from "../Images/hourglass.png";
import DueIcon from "../Images/Date.png";
import CompleteIcon from "../Images/complete.png";
import AdvertiserIcon from "../Images/advertiser.png";
import SettingsIcon from "../Images/settings.png";
import { Box } from "@mui/material";
import LogoSVG from "src/Images/Logo";

const SideBarItems = ({
  setSideBarItemSelected,
  sideBarItemSelected,
  currentUser,
}) => (
  <>
    <SideBarItem
      setSideBarItemSelected={setSideBarItemSelected}
      isSelected={sideBarItemSelected === "New Leads"}
      icon={NewIcon}
      name={"New Leads"}
    />
    <Box height={5} />
    <SideBarItem
      setSideBarItemSelected={setSideBarItemSelected}
      isSelected={sideBarItemSelected === "Active Contracts"}
      icon={HourglassIcon}
      name={"Active Contracts"}
    />
    <Box height={5} />
    <SideBarItem
      setSideBarItemSelected={setSideBarItemSelected}
      isSelected={sideBarItemSelected === "Due Dates"}
      icon={DueIcon}
      name={"Due Dates"}
    />
    <Box height={5} />
    <SideBarItem
      setSideBarItemSelected={setSideBarItemSelected}
      isSelected={sideBarItemSelected === "Completed Contracts"}
      icon={CompleteIcon}
      name={"Completed Contracts"}
    />
    <Box height={5} />
    {!currentUser?.isBrand && (
      <SideBarItem
        setSideBarItemSelected={setSideBarItemSelected}
        isSelected={sideBarItemSelected === "Creator Space"}
        icon={AdvertiserIcon}
        name={"Creator Space"}
      />
    )}
    {!currentUser?.isBrand && <Box height={5} />}
    <SideBarItem
      setSideBarItemSelected={setSideBarItemSelected}
      isSelected={sideBarItemSelected === "Settings"}
      icon={SettingsIcon}
      name={"Settings"}
    />
  </>
);

const LogoutButton = ({ doLogout }) => (
  <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
    <Box style={{ height: 35, width: 220 }}>
      <Box
        onClick={doLogout}
        borderColor={"#1A3A63"}
        style={{
          cursor: "pointer",
          textTransform: "none",
          height: "100%",
          width: "100%",
          flex: 1,
          fontSize: 16,
          fontWeight: 600,
          fontFamily: "Outfit",
          backgroundColor: "#D1D8E0",
          borderRadius: 15,
          color: "#1A3A63",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        variant={"contained"}
      >
        Logout
      </Box>
    </Box>
  </Box>
);

const NavigationBar = ({
  setCurrentChannel,
  sideBarItemSelected,
  setSideBarItemSelected,
  currentUser,
  doLogout,
  isMobile,
}) => {
  return (
    <Box
      style={{
        width: 250,
        display: "flex",
        position: "relative",
        flexDirection: "column",
        backgroundColor: "white",
        height: "100%",
        zIndex: 3,
      }}
      borderRight={2}
      borderColor={"#EEE"}
      paddingTop={2}
    >
      <Box
        onClick={() => setCurrentChannel(null)}
        style={{
          display: "flex",
          width: "100%",
          height: 50,
        }}
      >
        <Box width={20} />
        <LogoSVG width="180" height={"70"} />
      </Box>
      <Box
        style={{
          paddingTop: 40,
          display: "flex",
          flexDirection: "column",
          flex: 1,
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <SideBarItems
          setSideBarItemSelected={setSideBarItemSelected}
          sideBarItemSelected={sideBarItemSelected}
          currentUser={currentUser}
        />
        <Box flex={1} />
        <LogoutButton doLogout={doLogout} />
        <Box height={isMobile ? 40 : 20} />
      </Box>
    </Box>
  );
};
export default NavigationBar;
