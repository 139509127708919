import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { SettingsBody, SettingsHeading } from "src/styles/AppStyles";
import { styled } from "@mui/system";

const PasswordWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const ContentWrapper = styled(Box)(({ theme, isMobile }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: "flex",
  flexDirection: isMobile ? "column" : "row",
  width: "100%",
  alignItems: "center",
}));

const ResetPasswordButton = styled(Box)(({ theme }) => ({
  textTransform: "none",
  height: "100%",
  width: "100%",
  fontSize: 16,
  fontWeight: 600,
  fontFamily: "Outfit",
  backgroundColor: "#E2D4FF",
  borderRadius: 15,
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Divider = styled(Box)`
  width: 100%;
  height: 2px;
  background-color: #eee;
`;

const Password = ({ email, isMobile }) => {
  const [loading] = useState(false);

  const doRequestPasswordReset = async function () {
    // setLoading(true);
    // const emailValue = email;
    // try {
    //   await Parse.User.requestPasswordReset(emailValue);
    //   alert(
    //     `Success! Please check ${emailValue} to proceed with password reset.`
    //   );
    //   setLoading(false);
    //   return true;
    // } catch (error) {
    //   alert(`Error! ${error}`);
    //   setLoading(false);
    //   return false;
    // }
  };

  return (
    <PasswordWrapper>
      <ContentWrapper isMobile={isMobile}>
        <Box width="100%" justifyContent="left">
          <SettingsHeading>Change Password</SettingsHeading>
          <Box height={3} />
          <SettingsBody>
            Send a password reset link to the email associated with your account
          </SettingsBody>
        </Box>
        <Box flex={1} />
        {isMobile && <Box height={30} />}
        <Box height={35} width={isMobile ? "100%" : 180}>
          <ResetPasswordButton
            loading={loading}
            onClick={() => doRequestPasswordReset()}
          >
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "Outfit",
                fontWeight: 500,
                color: "#795CB8",
              }}
            >
              {loading ? "" : "Reset Password"}
            </Typography>
          </ResetPasswordButton>
        </Box>
      </ContentWrapper>
      <Box height={isMobile ? 30 : 0} />
      <Divider />
    </PasswordWrapper>
  );
};

export default Password;
