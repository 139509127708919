import * as React from "react";

function LandingPaymentIcon(props) {
  return (
    <svg
      width="79"
      height="63"
      viewBox="0 0 79 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.875 0H7.875C3.50437 0 0.039375 3.50437 0.039375 7.875L0 55.125C0 59.4956 3.50437 63 7.875 63H70.875C75.2456 63 78.75 59.4956 78.75 55.125V7.875C78.75 3.50437 75.2456 0 70.875 0ZM70.875 55.125H7.875V31.5H70.875V55.125ZM70.875 15.75H7.875V7.875H70.875V15.75Z"
        fill="#795CB8"
      />
    </svg>
  );
}

export default LandingPaymentIcon;
