import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import MarkableDeliverableItem from "./OfferComponents/MarkableDeliverableItem";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { updateNegotiation } from "src/graphql/mutations";
import moment from "moment";
import DeliverbleIcon from "src/Images/DeliverableIcon";

const FulfillDeliverables = ({ setNavigation, currentOffer, id }) => {
  const [textFields, setTextFields] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);

  // Calculate the percentage and update the state variable
  const calculatePercentage = () => {
    const fulfilledTasks = textFields.filter((task) => task.fulfilled);
    const fulfilledPercentage =
      (fulfilledTasks.length / textFields.length) * 100;
    setPercentage(Math.round(fulfilledPercentage));
  };

  useEffect(() => {
    const getCurrentUser = async () => {
      const curr = await Auth.currentAuthenticatedUser();
      setCurrentUser(curr);
    };
    getCurrentUser();
  }, []);

  // Call the calculatePercentage function to update the percentage on initial render
  useEffect(() => {
    calculatePercentage();
  }, [textFields]);

  useEffect(() => {
    if (currentOffer.requirements) {
      console.log(currentOffer.requirements);
      const formattedData = currentOffer.requirements.map((item) => {
        const keyValuePairs = item.match(/(\w+)=([^,]+)/g);
        const obj = {};
        keyValuePairs.forEach((keyValue) => {
          const [key, value] = keyValue.split("=");
          if (key === "fulfilled") {
            obj[key] = false;
          } else {
            obj[key] = value;
          }
        });
        return obj;
      });
      console.log(formattedData);
      setTextFields(formattedData);
    }
  }, []);

  const disabledButton = () => {
    if (currentUser == null) {
      return true;
    }
    if (currentOffer.brand === currentUser.attributes.sub) {
      return true;
    }
    if (currentOffer.creator === currentUser.attributes.sub) {
      return false;
    }
    return true;
  };

  const updateDeliverable = async (index) => {
    if (disabledButton()) {
      return;
    }
    const updatedFields = [...textFields];
    updatedFields[index].fulfilled = !updatedFields[index].fulfilled;

    setTextFields(updatedFields);
  };

  const finalizeDeliverables = async () => {
    const input = {
      id: currentOffer.id,
      delivarablesFinalized: true,
      lastUpdate: moment(moment.now()),
    };

    API.graphql(graphqlOperation(updateNegotiation, { input: input }))
      .then((response) => {
        console.log(response);
        setNavigation("main");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        height: "100%",
        paddingLeft: "2.5%",
        paddingRight: "2.5%",
        justifyContent: "center",
        paddingTop: 22,
        paddingBottom: 35,
      }}
    >
      <Box
        style={{
          width: "100%",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "scroll",
        }}
      >
        <Box style={{ width: "100%" }}>
          <Box
            style={{
              height: 25,
              borderRadius: 15,
              backgroundColor: "#D1D8E0",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box width={10} />
            <DeliverbleIcon height={12} width={11} color={"#1A3A63"} />
            <Box width={6} />
            <Typography
              style={{
                fontSize: 12,
                fontFamily: "Inter",
                fontWeight: 600,
                color: "#1A3A63",
              }}
            >
              Deliverables
            </Typography>
          </Box>
          <Box height={10} />
          <Box
            border={1}
            style={{
              width: "100%",
              height: 29,
              borderRadius: 10,
              borderColor: "#795CB8",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Box
              style={{
                height: 27,
                width: `${percentage}%`,
                display: "flex",
                backgroundColor: "#F2EFF8",
                position: "absolute",
                borderRadius: 10,
              }}
            />
            <Typography
              style={{
                fontSize: 12,
                fontFamily: "Outfit",
                fontWeight: 600,
                color: "#795CB8",
                zIndex: 1,
              }}
            >
              {percentage}% complete
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box height={23} />

          <Box style={{ flexDirection: "column", width: "100%" }}>
            {textFields.map((value, index) => {
              console.log(index);
              return (
                <Box>
                  <MarkableDeliverableItem
                    isDisabled={disabledButton()}
                    index={index}
                    value={value}
                    updateDeliverable={updateDeliverable}
                  />
                  <Box height={10} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box flex={1} />
      <Box style={{ display: "flex", height: 35, width: "100%" }}>
        <Box
          onClick={() => setNavigation("main")}
          style={{
            zIndex: 4,
            width: "45%",
            height: 35,
            borderRadius: 15,
            borderColor: "#1A3A63",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#D1D8E0",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography
            style={{
              fontSize: 16,
              fontFamily: "Outfit",
              fontWeight: 500,
              color: "#1A3A63",
            }}
          >
            Back
          </Typography>
        </Box>
        <Box flex={1} />
        <Box
          onClick={() => {
            if (percentage === 100) {
              finalizeDeliverables();
            }
          }}
          style={{
            borderColor: "#795CB8",
            zIndex: 4,
            width: "45%",
            height: 35,
            borderRadius: 15,
            backgroundColor:
              percentage !== 100 || disabledButton() ? "#F1EAFF" : "#E2D4FF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography
            style={{
              fontSize: 16,
              fontFamily: "Outfit",
              fontWeight: 500,
              color:
                percentage !== 100 || disabledButton() ? "#B6A3DC" : "#795CB8",
            }}
          >
            Finalize
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default FulfillDeliverables;
