import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const SettingsHeading = styled(Typography)`
  font-family: "Outfit";
  font-size: 18px;
  font-weight: 600;
  color: #4d4d4d;
`;

export const SettingsBody = styled(Typography)`
  font-family: "Outfit";
  font-size: 14px;
  font-weight: 500;
  color: #aaa;
`;
