import React from "react";
import "./Checkmark.css"; // assuming you saved your css in Checkmark.css file
import { Box } from "@mui/material";
import LogoNoTextColor from "src/Images/LogoNoTextColor";
import ProfferaNoSubtext from "src/Images/ProfferaNoSubtext";

const StripeSuccess = () => {
  return (
    <Box style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <Box
        borderBottom={1}
        style={{
          display: "flex",
          height: 70,
          width: "100%",
          backgroundColor: "#FAFAFA",
          alignItems: "center",
          borderBottomColor: "#CCCCCC",
        }}
      >
        <Box width={29} />
        <Box
          style={{
            zIndex: 2,
            height: 50,
            width: 50,
            backgroundColor: "#DED9ED",
            borderRadius: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LogoNoTextColor width={35} height={35} />
        </Box>
        <Box width={10} />
        <ProfferaNoSubtext height={30} width={130} />
        <Box flex={1} />
        <Box width={20} />
      </Box>
      <div className="wrapper">
        <svg
          className="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            className="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            className="checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      </div>
    </Box>
  );
};
export default StripeSuccess;
