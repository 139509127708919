import { Box, Typography } from "@mui/material";
import iphoneMakePayment from "src/Images/iphoneMakePayment.png";
import LandingPaymentIcon from "src/Images/SwapIcon copy";
import LandingFastPaymentIcon from "src/Images/LandingFastPaymentIcon";
import LandingDetailPayIcon from "src/Images/LandingDetailPayIcon";
import LandingThumbIcon from "src/Images/LandingThumbIcon";

const HandlePayment = () => {
  return (
    <Box style={{ height: 650 }}>
      <Box height={50} />

      <Box
        style={{
          width: "100%",
          maxWidth: 1440,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flex: 1,
            width: 450,
            height: "90%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LandingPaymentIcon />
            <Box height={14} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 20,
                fontWeight: 700,
                color: "#795CB8",
                lineHeight: 1,
              }}
            >
              Secure Payments
            </Typography>
            <Box height={14} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 18,
                fontWeight: 500,
                color: "#57616F",
                lineHeight: 1,
                width: 300,
              }}
            >
              Safely and securely make payments with Stripe.
            </Typography>
          </Box>
          <Box height={80} />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LandingThumbIcon />
            <Box height={14} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 20,
                fontWeight: 700,
                color: "#795CB8",
                lineHeight: 1,
              }}
            >
              Hassle Free
            </Typography>
            <Box height={14} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 18,
                fontWeight: 500,
                color: "#57616F",
                lineHeight: 1,
                width: 300,
              }}
            >
              Easily setup your account and start making money.
            </Typography>
          </Box>
          <Box height={90} />
        </Box>
        <img src={iphoneMakePayment} alt={""} width={295} />
        <Box
          style={{
            display: "flex",
            flex: 1,
            width: 450,
            height: "90%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LandingFastPaymentIcon />
            <Box height={10} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 20,
                fontWeight: 700,
                color: "#795CB8",
                lineHeight: 1,
              }}
            >
              Fast Payments
            </Typography>
            <Box height={14} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 18,
                fontWeight: 500,
                color: "#57616F",
                lineHeight: 1,
                width: 250,
              }}
            >
              No delays, get paid as soon as the contract is signed.
            </Typography>
          </Box>
          <Box height={80} />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LandingDetailPayIcon />
            <Box height={14} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 20,
                fontWeight: 700,
                color: "#795CB8",
                lineHeight: 1,
              }}
            >
              Detailed
            </Typography>
            <Box height={14} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 18,
                fontWeight: 500,
                color: "#57616F",
                lineHeight: 1,
                width: 300,
              }}
            >
              Clear and concise payment details, who gets paid what and why.
            </Typography>
          </Box>
          <Box height={90} />
        </Box>
      </Box>
    </Box>
  );
};
export default HandlePayment;
