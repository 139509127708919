function LogoNoTextColor(props) {
  return (
    <svg
      width="111"
      height="102"
      viewBox="0 0 111 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx="15.7028"
        cy="16.4305"
        rx="15.7028"
        ry="16.4305"
        transform="matrix(0.934681 -0.355488 0.320375 0.947291 70.9175 24.7743)"
        fill="#795CB8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.34128 47.6226C-3.08218 34.5433 3.79367 19.9614 16.6989 15.0532L23.7769 12.3612C22.4356 15.9635 22.2752 20.0781 23.6051 24.0103C26.5123 32.6063 35.4402 37.0755 43.5461 33.9926C51.6521 30.9096 55.8665 21.442 52.9593 12.846C51.6295 8.91379 49.0397 5.84516 45.8451 3.96795L50.022 2.37938C62.6279 -2.41506 76.324 3.79407 81.0752 16.275C72.7112 21.24 68.7052 32.025 72.0485 41.9106C75.3919 51.7962 84.9484 57.4231 94.3815 55.6192C98.1932 68.4575 91.3402 82.482 78.7343 87.2764L54.7078 96.4144C51.7964 92.0274 48.2688 88.2413 44.2793 85.3729C36.8783 80.0519 28.0326 78.0727 19.8311 81.192C17.6471 82.0226 15.6446 83.1631 13.834 84.5614L1.34128 47.6226ZM48.8808 98.6306L45.4112 99.9502C34.2754 104.185 22.2889 99.8341 16.3693 90.1578C17.9871 88.7298 19.7938 87.621 21.7533 86.8757C27.6832 84.6204 34.5273 85.879 40.7801 90.3745C43.775 92.5278 46.5167 95.3391 48.8808 98.6306Z"
        fill="#1A3A63"
      />
    </svg>
  );
}
export default LogoNoTextColor;
