import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { API, Auth } from "aws-amplify";
import { testStuff } from "src/graphql/queries";
import PaymentIcon from "src/Images/PaymentIcon";

const MakePayment = ({ setNavigation, currentOffer, id, isMobile }) => {
  const [hasBeenPaid, setHasBeenPaid] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    setHasBeenPaid(currentOffer.isPaid);
  }, []);

  useEffect(() => {
    const getCurrentUser = async () => {
      const curr = await Auth.currentAuthenticatedUser();
      setCurrentUser(curr);
    };
    getCurrentUser();
  }, []);

  useEffect(() => {
    // const intents = currentOffer.get("PaymentIntents");
    // if (intents) {
    //   intents.map((item, index) => {
    //     const sessionID = item;
    //     Parse.Cloud.run("checkStripeCheckout", { sessionID }).then(
    //       (response) => {
    //         try {
    //           if (response) {
    //             updatePaid();
    //           }
    //         } catch {
    //           console.log("failure");
    //         }
    //       }
    //     );
    //   });
    // }
  }, []);

  const disabledButton = () => {
    if (currentUser == null) {
      return true;
    }
    if (currentOffer.creator === currentUser.attributes.sub) {
      return true;
    }
    if (currentOffer.brand === currentUser.attributes.sub) {
      return false;
    }
    return true;
  };

  const launchStripe = async () => {
    if (disabledButton()) {
      return;
    }
    console.log("launching stripe");
    const uu = await Auth.currentAuthenticatedUser();

    console.log(uu.username);
    console.log(currentOffer.id);
    // console.log(sessionToken);
    const data = await API.graphql({
      query: testStuff,
      variables: {
        negotiationId: currentOffer.id,
      },
    });
    console.log(data);

    window.location.href = data.data.testStuff;

    // const paymentWindow = window.open(data.data.testStuff, "_blank");

    // Using polling to check if the window is closed
    // const checkPaymentWindowClosed = setInterval(() => {
    //   if (paymentWindow.closed) {
    //     // Do what you want here, for example, show a message
    //     alert("Payment completed!");
    //     clearInterval(checkPaymentWindowClosed);
    //   }
    // }, 500); // check every half second

    // console.log(data.data.testStuff);
    // window.open(data.data.testStuff, "_blank").focus();

    // const advertiser = currentOffer.get("Advertiser");
    // const price = currentOffer.get("PaymentM");
    // console.log(price);
    // const stripeAccountID = advertiser.stripeAccountID;
    // console.log(stripeAccountID);

    // Parse.Cloud.run("createStripeCheckout", {
    //   price: price,
    //   stripeAccountID: stripeAccountID,
    // }).then(async (response) => {
    //   currentOffer.add("PaymentIntents", response.id);
    //   try {
    //     await currentOffer.save();
    //   } catch (error) {
    //     console.log("error");
    //   }
    //   console.log(response);
    //   try {
    //     window.open(response.url, "_blank").focus();
    //   } catch {
    //     console.log("failure");
    //   }
    // });
  };

  if (isMobile) {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          paddingTop: 25,
          paddingLeft: "2.5%",
          paddingRight: "2.5%",
          paddingBottom: 35,
        }}
      >
        <Box
          style={{
            height: 30,
            borderRadius: 15,
            backgroundColor: "#D1D8E0",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box width={10} />
          <PaymentIcon height={12} width={11} color={"#1A3A63"} />
          <Box width={6} />
          <Typography
            style={{
              fontSize: 12,
              fontFamily: "Inter",
              fontWeight: 600,
              color: "#1A3A63",
            }}
          >
            Make payment via Stripe
          </Typography>
        </Box>
        <Box height={23} />

        <Box>
          <Box
            onClick={() => {
              if (!hasBeenPaid) launchStripe();
            }}
            border={1}
            style={{
              cursor: hasBeenPaid ? null : "pointer",
              display: "flex",
              alignItems: "center",
              flex: 1,
              height: 50,
              width: "100%",
              borderRadius: 15,
              borderColor: hasBeenPaid ? "#47DA70" : "#795CB8",
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 11,
              paddingBottom: 11,
              backgroundColor: disabledButton()
                ? "#F1EAFF"
                : hasBeenPaid
                ? "#DAF8E2"
                : "#F2EFF8",
            }}
          >
            <Typography
              style={{
                fontSize: 14,
                fontFamily: "Outfit",
                color: disabledButton()
                  ? "#B6A3DC"
                  : hasBeenPaid
                  ? "#47DA70"
                  : "#795CB8",
                fontWeight: 600,
              }}
            >
              {disabledButton()
                ? "Awaiting Payment from Brand"
                : hasBeenPaid
                ? "Paid"
                : "Launch Stripe"}
            </Typography>
          </Box>
        </Box>
        <Box height={41} />
        <Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              width: "100%",
              borderRadius: 15,
              borderColor: "#1A3A63",
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 11,
              paddingBottom: 11,
              backgroundColor: "#F3F5F7",
            }}
          >
            <Typography
              style={{
                fontSize: 14,
                fontFamily: "Outfit",
                color: "#1A3A63",
                fontWeight: 600,
              }}
            >
              We encourage users to cross-check ID via Stripe and username via
              verified social accounts before making payments:
              <br />
              &nbsp;• Contract ID: {currentOffer.id}
              <br />
              &nbsp;• Creator username: {currentOffer.creatorUsername}
            </Typography>
          </Box>
          <Box height={10} />
        </Box>
        <Box flex={1} />
        <Box style={{ display: "flex", height: 45, width: "100%" }}>
          <Box
            onClick={() => setNavigation("main")}
            style={{
              width: "100%",
              height: 35,
              borderRadius: 15,
              borderColor: "#1A3A63",
              backgroundColor: "#D1D8E0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "Outfit",
                fontWeight: 500,
                color: "#1A3A63",
              }}
            >
              Back
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          paddingTop: 42,
          paddingLeft: 30,
          paddingRight: 30,
          paddingBottom: 35,
        }}
      >
        <Typography
          style={{
            fontSize: 16,
            fontFamily: "Inter",
            fontWeight: 600,
            color: "#1A3A63",
          }}
        >
          Make payment via Stripe
        </Typography>
        <Box height={23} />

        <Box>
          <Box
            onClick={() => {
              if (!hasBeenPaid) launchStripe();
            }}
            border={1}
            style={{
              cursor: hasBeenPaid ? null : "pointer",
              display: "flex",
              alignItems: "center",
              flex: 1,
              height: 58,
              width: "100%",
              borderRadius: 10,
              borderColor: hasBeenPaid ? "#47DA70" : "#795CB8",
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 11,
              paddingBottom: 11,
              backgroundColor: disabledButton()
                ? "#F1EAFF"
                : hasBeenPaid
                ? "#DAF8E2"
                : "#F2EFF8",
            }}
          >
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "Outfit",
                color: disabledButton()
                  ? "#B6A3DC"
                  : hasBeenPaid
                  ? "#47DA70"
                  : "#795CB8",
                fontWeight: 600,
              }}
            >
              {disabledButton()
                ? "Awaiting Payment from Brand"
                : hasBeenPaid
                ? "Paid"
                : "Launch Stripe"}
            </Typography>
          </Box>
        </Box>
        <Box height={41} />
        <Box>
          <Box
            border={1}
            style={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              width: "100%",
              borderRadius: 10,
              borderColor: "#1A3A63",
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 11,
              paddingBottom: 11,
              backgroundColor: "#F3F5F7",
            }}
          >
            <Typography
              style={{
                fontSize: 14,
                fontFamily: "Outfit",
                color: "#1A3A63",
                fontWeight: 600,
              }}
            >
              We encourage users to cross-check ID via Stripe and username via
              verified social accounts before making payments:
              <br />
              &nbsp;• Contract ID: {currentOffer.id}
              <br />
              &nbsp;• Creator username: {currentOffer.creatorUsername}
            </Typography>
          </Box>
          <Box height={10} />
        </Box>
        <Box flex={1} />
        <Box style={{ display: "flex", height: 45, width: "100%" }}>
          <Box flex={1} />
          <Box
            onClick={() => setNavigation("main")}
            border={1}
            style={{
              width: 198,
              height: 45,
              borderRadius: 10,
              borderColor: "#1A3A63",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "Outfit",
                fontWeight: 500,
                color: "#1A3A63",
              }}
            >
              Back
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
};
export default MakePayment;
