import * as React from "react";

function DeliverbleIcon(props) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4 4.90909H6.4V9.81818H4.8V3.27273H9.6V0H3.2V4.90909H1.6C0.72 4.90909 0 5.64545 0 6.54545V16.3636C0 17.2636 0.72 18 1.6 18H14.4C15.28 18 16 17.2636 16 16.3636V6.54545C16 5.64545 15.28 4.90909 14.4 4.90909Z"
        fill={props.color}
      />
    </svg>
  );
}

export default DeliverbleIcon;
