import React, { useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Modal, Box, TextField, Button, Typography } from "@mui/material";
import DisputeIcon from "../Images/dispute.png";
import emailjs from "@emailjs/browser";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "red",
    border: "2px solid #000",
    boxShadow: 1,
    padding: 2,
  },
}));

export default function TextFormModal({
  open,
  setOpen,
  offer,
  currentUser,
  activeDispute,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const inputProps = {
    style: {
      textAlign: "top",
      height: 280,
    },
  };

  const sendEmail = (e) => {
    // setLoading(true);
    // setError(false);
    // e.preventDefault();
    // emailjs
    //   .send(
    //     process.env.REACT_APP_SERVICE_ID,
    //     process.env.REACT_APP_TEMPLATE_ID,
    //     {
    //       message: value,
    //       objID: offer.id,
    //       from_name: currentUser.attributes.username,
    //       email: currentUser.attributes.email,
    //     },
    //     process.env.REACT_APP_PUBLIC_KEY
    //   )
    //   .then(
    //     async (result) => {
    //       const Offer = new Parse.Object("Channel");
    //       Offer.set("objectId", offer.id);
    //       Offer.set("Status", "In Dispute");
    //       try {
    //         await Offer.save();
    //         setLoading(false);
    //         setSubmitted(true);
    //         console.log(result.text);
    //       } catch (error) {
    //         setError(true);
    //         console.log(error);
    //       }
    //     },
    //     (error) => {
    //       setLoading(false);
    //       setError(true);
    //       console.log(error.text);
    //     }
    //   );
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Box
          style={{
            backgroundColor: "white",
            height: 600,
            width: 800,
            borderRadius: 10,
            display: "flex",
            position: "relative",
            flexDirection: "column",
            alignItems: "center",
            padding: 50,
            paddingTop: 40,
          }}
        >
          {loading && (
            <Box
              style={{ marginTop: -40, zIndex: 1 }}
              position={"absolute"}
              display={"flex"}
              width={"100%"}
              height={"100%"}
              backgroundColor={"rgba(0,0,0,0.1)"}
            />
          )}
          {submitted ? (
            <Typography
              width={700}
              fontFamily={"Outfit"}
              fontSize={20}
              fontWeight={600}
              color={"#4D4D4D"}
            >
              We have recieved your dispute, we will be in touch shortly.
            </Typography>
          ) : activeDispute ? (
            <Typography
              width={700}
              fontFamily={"Outfit"}
              fontSize={20}
              fontWeight={600}
              color={"#4D4D4D"}
            >
              Dispute is being processed. For further support please contact:
              support@proffera.com
            </Typography>
          ) : (
            <Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    width: 30,
                    height: 30,
                  }}
                  src={DisputeIcon ?? ""}
                  alt={""}
                  loading="lazy"
                />
                <Typography
                  width={700}
                  fontFamily={"Outfit"}
                  fontSize={20}
                  fontWeight={600}
                  color={"#4D4D4D"}
                >
                  {error ? "Unexpected error, try again" : "Lodge Dispute"}
                </Typography>
              </Box>

              <Typography
                style={{
                  borderRadius: 10,
                  paddingTop: 10,
                  padding: 10,
                  marginTop: 10,
                }}
                backgroundColor={"#F1F2F5"}
                color={"#4D4D4D"}
                fontFamily={"Outfit"}
                fontSize={18}
                fontWeight={500}
                width={700}
              >
                We are sorry that an issue has occurred on this offer. Please
                let us know a bit about what is going on and our team will
                investigate and get back to you via email as soon as possible.
              </Typography>
              <Box flex={1} />
              <TextField
                multiline
                defaultValue={value}
                onChange={(event) => setValue(event.target.value)}
                inputProps={inputProps}
                style={{ marginTop: 16, width: 700 }}
              />

              <Box display={"flex"} style={{ marginTop: 20 }} width={700}>
                <Box flex={1} />
                <Box width={200}>
                  <Button
                    onClick={() => setOpen(false)}
                    border={1}
                    borderColor={"#4D4D4D"}
                    style={{
                      textTransform: "none",
                      height: "100%",
                      width: "100%",
                      flex: 1,
                      fontSize: 16,
                      fontWeight: 600,
                      fontFamily: "Outfit",
                      backgroundColor: "white",
                      borderRadius: 5,
                      color: "#4D4D4D",
                    }}
                    variant={"contained"}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box width={10} />
                <Box width={200}>
                  <Button
                    onClick={(e) => sendEmail(e)}
                    border={1}
                    borderColor={"#4D4D4D"}
                    style={{
                      textTransform: "none",
                      height: "100%",
                      width: "100%",
                      flex: 1,
                      fontSize: 16,
                      fontWeight: 600,
                      fontFamily: "Outfit",
                      backgroundColor: "#4D4D4D",
                      borderRadius: 5,
                      color: "white",
                    }}
                    variant={"contained"}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}
