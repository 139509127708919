import { Box, Typography } from "@mui/material";
import { ButtonUnstyled } from "@mui/base";
import { styled } from "@mui/system";
import PropTypes from "prop-types";

const CustomButton = styled(ButtonUnstyled)`
  font-family: Outfit;
  font-weight: 600;
  font-size: 18px;
  color: white;
  height: 50px;
  display: flex;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  background-color: white;
  border-radius: 10px;

  &:hover {
    background-color: #eee;
  }
`;

const SideBarItemContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 248px;
  height: 50px;
  padding: 13px 0;
  border-radius: 10px;
  background-color: ${({ isSelected }) => (isSelected ? "#E2D4FF" : "")};
`;

const IconContainer = styled(Box)`
  width: 12px;
`;

const Icon = styled("img")`
  width: 35px;
  height: 35px;
`;

const Name = styled(Typography)`
  margin-left: 10px;
  font-family: Outfit;
  font-size: 18px;
  font-weight: 600;
  color: ${({ isSelected }) => (isSelected ? "#795CB8" : "#4d4d4d")};
`;

const SideBarItem = ({ icon, name, isSelected, setSideBarItemSelected }) => {
  return (
    <CustomButton onClick={() => setSideBarItemSelected(name)}>
      <SideBarItemContainer isSelected={isSelected}>
        <IconContainer />
        <Icon src={icon ?? ""} alt="" loading="lazy" />
        <Name isSelected={isSelected}>{name}</Name>
      </SideBarItemContainer>
    </CustomButton>
  );
};

SideBarItem.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  setSideBarItemSelected: PropTypes.func.isRequired,
};

SideBarItem.defaultProps = {
  icon: "",
  isSelected: false,
};

export default SideBarItem;
