import React, { useState, useEffect } from "react";
import MenuIcon from "./Images/menu.png";
import {
  Box,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  List,
} from "@mui/material";
import { Storage } from "aws-amplify";
import { Search } from "@mui/icons-material";
import { OfferList } from "./OfferList";
import { styled } from "@mui/system";
import UserInfo from "./components/UserInfo";
import TopBar from "./components/DashboardTopBar";

const Dashboard = ({
  currentUser,
  doSelectChannel,
  sideBarItemSelected,
  setSideBarItemSelected,
  EndNegotiation,
  setShowSideBar,
  showSideBar,
  isMobile,
}) => {
  const [pageNum, setPageNum] = useState(1);
  const [searchTerm, setSearchTerm] = React.useState("");

  const [profilePic, setProfilePic] = useState(null);

  const [expandSearch, setExpandSearch] = useState(false);

  const findProfilePic = async () => {
    if (currentUser.profilePic) {
      const url = await Storage.get(currentUser.profilePic.key, {
        key: currentUser.profilePic.bucket,
      });
      setProfilePic(url);
    }
  };

  useEffect(() => {
    if (currentUser) {
      findProfilePic();
    }
  });

  const handleChanges = (event) => {
    setSearchTerm(event.target.value);
  };

  const companyFieldProps = {
    style: {
      height: 15,
      width: 642,
      backgroundColor: "#FBFBFB",
    },
  };

  return (
    <Box display={"flex"} flexDirection={"column"} flex={1} minWidth={300}>
      <TopBar
        isMobile={isMobile}
        showSideBar={showSideBar}
        toggleSideBar={() => setShowSideBar(!showSideBar)}
        sideBarItemSelected={sideBarItemSelected}
        setSideBarItemSelected={setSideBarItemSelected}
        expandSearch={expandSearch}
        setExpandSearch={setExpandSearch}
        searchTerm={searchTerm}
        handleChanges={handleChanges}
        companyFieldProps={companyFieldProps}
        currentUser={currentUser}
        profilePic={profilePic}
      />
      <Box
        height={isMobile ? 15 : 33}
        borderBottom={isMobile ? 0 : 2}
        style={{ borderColor: "#CCC" }}
      ></Box>
      <Grid
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          paddingTop: isMobile ? 0 : 18,
        }}
      >
        <List style={{ height: 0, flexGrow: 1, overflowY: "auto" }}>
          {currentUser !== null && (
            <OfferList
              isMobile={isMobile}
              EndNegotiation={EndNegotiation}
              searchTerm={searchTerm}
              sideBarItemSelected={sideBarItemSelected}
              page={pageNum}
              currentUser={currentUser}
              selectChannelCallback={doSelectChannel}
            />
          )}
        </List>
      </Grid>
      {/* <Box height={23} /> */}
      <style>{`
        /* Hide scrollbar for all elements with the default WebKit scrollbar style */
        ::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </Box>
  );
};
export default Dashboard;
