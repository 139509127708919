export function GetStatusButtonData(offer) {
  console.log(offer.contractState);
  console.log(offer.isPaid);
  console.log(offer.negotiationOpen);
  if (offer.contractState === "NoOffer" || offer.contractState === "Editing") {
    return { statusBackColor: "#EAFCF4", statusTextColor: "#29E294" };
  } else if (offer.contractState === "Pending") {
    return { statusBackColor: "#DBE7F2", statusTextColor: "#3B51E0" };
  } else if (offer.contractState === "Accepted" && !offer.isPaid) {
    return { statusBackColor: "#FEF3E8", statusTextColor: "#F47E11" };
  } else if (offer.isPaid && !offer.delivarablesFinalized) {
    return { statusBackColor: "#EBF9FA", statusTextColor: "#33BACC" };
  } else if (
    offer.isPaid &&
    offer.delivarablesFinalized &&
    offer.negotiationOpen
  ) {
    return { statusBackColor: "#F5E8F8", statusTextColor: "#C829E2" };
  } else if (
    offer.isPaid &&
    offer.delivarablesFinalized &&
    !offer.negotiationOpen
  ) {
    return { statusBackColor: "#EFE7FF", statusTextColor: "#5606FF" };
  } else {
    return "Unknown";
  }
}

export function GetLeftButton(status, isCustomer) {
  switch (status) {
    case "Awaiting Customer":
      return isCustomer ? "Accept Offer" : "Change Offer";
    case "Awaiting Advertiser":
      return isCustomer ? "Change Offer" : "Accept Offer";
    case "Awaiting Payment":
      return isCustomer ? "Make Payment" : "ERROR";
    case "Awaiting Fulfillment":
      return isCustomer ? "ERROR" : "Mark as Fulfilled";
    case "Fulfilled":
      return "ERROR";
    default:
      // Return error if unexpected status value is encountered
      return "ERROR";
  }
}

export function GetRightButton(status, isCustomer) {
  switch (status) {
    case "Awaiting Customer":
      return isCustomer ? "Counter Offer" : "Withdraw Offer";
    case "Awaiting Advertiser":
      return isCustomer ? "Withdraw Offer" : "Counter Offer";
    case "Awaiting Payment":
      return "Reopen Negotiation";
    default:
      return "ERROR";
  }
}
