import * as React from "react";

function FulfillmentIcon(props) {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2222 0H1.77778C0.8 0 0.00888888 0.818182 0.00888888 1.81818L0 13.5727C0 14.2 0.311111 14.7545 0.782222 15.0818L8 20L15.2089 15.0818C15.68 14.7545 15.9911 14.2 15.9911 13.5727L16 1.81818C16 0.818182 15.2 0 14.2222 0ZM6.22222 13.6364L1.77778 9.09091L3.03111 7.80909L6.22222 11.0636L12.9689 4.16364L14.2222 5.45455L6.22222 13.6364Z"
        fill={props.color}
      />
    </svg>
  );
}

export default FulfillmentIcon;
