import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Box, Typography, OutlinedInput, Button } from "@mui/material";
import { SettingsHeading } from "src/styles/AppStyles";
import { DELETE_STRINGS } from "src/statics/strings";

const DeleteUserSection = ({ isMobile, guard, setGuard }) => (
  <Box
    style={{
      marginTop: 35,
      marginBottom: 35,
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      width: "100%",
      alignItems: "center",
    }}
  >
    <Box>
      <SettingsHeading>{DELETE_STRINGS.DELETE_TITLE}</SettingsHeading>
      <Box height={3} />
      <Typography
        color={"#AAA"}
        fontFamily={"Outfit"}
        fontSize={14}
        fontWeight={500}
      >
        {DELETE_STRINGS.DELETE_DESCRIPTION}
      </Typography>
    </Box>
    <Box flex={1} />
    <Box width={20} />
    {isMobile && <Box height={20} />}
    <OutlinedInput
      placeholder={DELETE_STRINGS.DELETE_GUARD_PROMPT}
      value={guard}
      onChange={(events) => setGuard(events.target.value)}
      style={{
        fontSize: 14,
        height: 45,
        width: isMobile ? "100%" : 450,
        borderRadius: 15,
      }}
    />
  </Box>
);

const DeleteButton = ({ isMobile, guard, deleteUser }) => (
  <Box display={"flex"} flexDirection={"row"} justifyContent={"right"}>
    <Box style={{ height: 35, width: isMobile ? "100%" : 180 }}>
      <Button
        onClick={deleteUser}
        disabled={!(guard === DELETE_STRINGS.DELETE_GUARD)}
        style={{
          textTransform: "none",
          height: "100%",
          width: "100%",
          flex: 1,
          fontSize: 16,
          fontWeight: 600,
          fontFamily: "Outfit",
          backgroundColor:
            guard === DELETE_STRINGS.DELETE_GUARD
              ? "red"
              : "rgba(255, 0, 0, 0.2)",
          borderRadius: 15,
          color: "white",
        }}
        variant={"contained"}
      >
        {DELETE_STRINGS.DELETE_BUTTON}
      </Button>
    </Box>
  </Box>
);

const DangerZone = ({ cid, setCurrentUser, isMobile }) => {
  const history = useHistory();

  const [guard, setGuard] = useState("");

  const deleteUser = async () => {
    try {
      const result = await Auth.deleteUser();
      alert("Account has been successfully deleted.");
      history.push("/login");
      setCurrentUser(null);
      console.log(result);
    } catch (error) {
      console.log("Error deleting user", error);
    }
  };

  return (
    <Box>
      <DeleteUserSection
        isMobile={isMobile}
        guard={guard}
        setGuard={setGuard}
      />
      <Box width={"100%"} height={2} style={{ backgroundColor: "#EEE" }} />
      <Box height={35} />
      <DeleteButton isMobile={isMobile} guard={guard} deleteUser={deleteUser} />
    </Box>
  );
};

export default DangerZone;
