import { Box, OutlinedInput, Typography } from "@mui/material";
import { ACCOUNT_STRINGS } from "src/statics/strings";
import { SettingsBody, SettingsHeading } from "src/styles/AppStyles";
import awsmobile from "src/aws-exports";
import { Storage, API, graphqlOperation, Auth } from "aws-amplify";
import { updateUser } from "src/graphql/mutations";

const Account = ({
  currentUser,
  getCurrentUser,
  isMobile,
  email,
  displayName,
}) => {
  const updateProfile = async (profilePic) => {
    const u = await Auth.currentAuthenticatedUser();
    console.log(u);
    const input = {
      id: u.attributes.sub,
      profilePic,
    };

    console.log(input);

    try {
      await API.graphql(graphqlOperation(updateUser, { input: input }));
      getCurrentUser();
    } catch (err) {
      console.log(err);
    }
  };

  // @ts-ignore
  const handleFileChange = (event) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.click();
    fileInput.onchange = (event) => {
      // @ts-ignore
      const file = event.target.files[0];

      Storage.put(file.name, file, {
        contentType: "image/png",
      }).then((result) => {
        const profilePic = {
          bucket: awsmobile.aws_user_files_s3_bucket,
          region: awsmobile.aws_user_files_s3_bucket_region,
          key: file.name,
        };
        updateProfile(profilePic);
        console.log("Added Completed");
      });
    };
  };

  return (
    <Box>
      <Box
        style={{
          marginTop: isMobile ? 20 : 35,
          marginBottom: isMobile ? 20 : 35,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box style={{ width: "100%", justifyContent: "left" }}>
          <SettingsHeading>{ACCOUNT_STRINGS.EMAIL_HEADER}</SettingsHeading>
          <Box height={3} />
          <SettingsBody>{ACCOUNT_STRINGS.EMAIL_BODY}</SettingsBody>
        </Box>
        <Box flex={1} />
        {isMobile ? <Box height={10} /> : <Box width={50} />}
        <OutlinedInput
          key={email}
          disabled={true}
          defaultValue={email}
          style={{
            height: 45,
            width: isMobile ? "100%" : 501,
            borderRadius: 15,
          }}
        />
      </Box>
      <Box width={"100%"} height={2} style={{ backgroundColor: "#EEE" }} />
      <Box
        style={{
          marginTop: isMobile ? 20 : 35,
          marginBottom: isMobile ? 20 : 35,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box style={{ width: "100%", justifyContent: "left" }}>
          <SettingsHeading>{ACCOUNT_STRINGS.COMPANY_HEADER}</SettingsHeading>
          <Box height={3} />
          <SettingsBody>{ACCOUNT_STRINGS.COMPANY_BODY}</SettingsBody>
        </Box>
        <Box flex={1} />

        {isMobile ? <Box height={10} /> : <Box width={50} />}
        <OutlinedInput
          key={displayName}
          disabled={true}
          defaultValue={displayName}
          style={{
            height: 45,
            width: isMobile ? "100%" : 501,
            borderRadius: 15,
          }}
        />
      </Box>
      <Box width={"100%"} height={2} style={{ backgroundColor: "#EEE" }} />
      <Box
        style={{
          marginTop: isMobile ? 20 : 35,
          marginBottom: isMobile ? 20 : 35,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box style={{ width: "100%", justifyContent: "left" }}>
          <SettingsHeading>
            {ACCOUNT_STRINGS.PROFILE_PIC_HEADER}
          </SettingsHeading>
          <Box height={3} />
          <SettingsBody>{ACCOUNT_STRINGS.PROFILE_PIC_BODY}</SettingsBody>
        </Box>
        <Box flex={1} />
        {isMobile && <Box height={30} />}
        <Box style={{ height: 35, width: isMobile ? "100%" : 140 }}>
          <Box
            //@ts-ignore
            onClick={() => handleFileChange()}
            style={{
              textTransform: "none",
              height: "100%",
              width: "100%",
              flex: 1,
              fontSize: 16,
              fontWeight: 600,
              fontFamily: "Outfit",
              backgroundColor: "#E2D4FF",
              borderRadius: 15,
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            variant={"contained"}
          >
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "Outfit",
                fontWeight: 500,
                color: "#795CB8",
              }}
            >
              {ACCOUNT_STRINGS.PROFILE_PIC_BUTTON}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box height={isMobile ? 30 : 0} />
      <Box width={"100%"} height={2} style={{ backgroundColor: "#EEE" }} />
    </Box>
  );
};
export default Account;
