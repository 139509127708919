import * as React from "react";

function LandingCloseIcon(props) {
  return (
    <svg
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.3333 1H8.63333C4.96667 1 2 4 2 7.66667L2.03333 54.3333C2.03333 58 5 61 8.66667 61H42L62 41V7.66667C62 4 59 1 55.3333 1ZM15.3333 17.6667H48.6667V24.3333H15.3333V17.6667ZM32 37.6667H15.3333V31H32V37.6667ZM38.6667 56V37.6667H57L38.6667 56Z"
        fill="#795CB8"
      />
    </svg>
  );
}

export default LandingCloseIcon;
