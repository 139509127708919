import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { Auth, API, Storage } from "aws-amplify";
import { ButtonUnstyled } from "@mui/base";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/system";
import { updateLastUpdated } from "./Network/Queries";
import LogoSVG from "./Images/Logo";
import LogoNoTextSVG from "src/Images/LogoNoText";
import { isMobile as deviceMobile } from "react-device-detect";
import {
  getUser,
  getUserByUsername,
  checkAccountActive,
  usersByUsername,
} from "./graphql/queries";
import { createMessage, createNegotiation } from "./graphql/mutations";
import moment from "moment";

const StyledBox = styled(Box)`
  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
  }
`;

const CustomButton = styled(LoadingButton)`
  font-family: Outfit;
  font-weight: 600;
  font-size: 12;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  background-color: white;

  &:hover {
    background-color: ${"#EEE"};
  }
`;

const FormTemplate = ({ isMobile, height }) => {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(null);
  const [formOwner, setFormOwner] = useState(null);
  const [madeOffer, setMadeOffer] = useState(false);
  const [queryUser, setQueryUser] = useState("");
  const [message, setMessage] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [formProfilePic, setFormProfilePic] = useState(null);
  const [budgetBracket, setBudgetBracket] = React.useState(-1);
  const [loading, setLoading] = useState(false);
  const [isFormOwnerSetup, setFormOwnerSetup] = useState(false);
  const [editingMessage, setEditingMessage] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [editingMessage]);

  const handleChange = (event) => {
    setBudgetBracket(event.target.value);
  };

  const findProfilePic = async () => {
    // const file = await currentUser.get("ProfilePic");
    // if (file) setProfilePic(file._url);
  };

  const findFormProfilePic = async () => {
    // const file = await formOwner.get("ProfilePic");
    // if (file) setFormProfilePic(file._url);
  };

  useEffect(() => {
    const doStuff = async () => {
      const url = await Storage.get(formOwner.profilePic.key, {
        key: formOwner.profilePic.bucket,
      });
      console.log(url);

      setFormProfilePic(url);
    };

    if (formOwner?.profilePic) {
      doStuff();
    }
  }, [formOwner]);

  useEffect(() => {
    const doStuff = async () => {
      const url = await Storage.get(currentUser.profilePic.key, {
        key: currentUser.profilePic.bucket,
      });
      console.log(url);

      setProfilePic(url);
    };

    if (currentUser?.profilePic) {
      console.log(currentUser);
      doStuff();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      console.log("formOwner is " + formOwner);
      // findProfilePic();
    }
    if (formOwner) {
      API.graphql({
        query: checkAccountActive,
        variables: {
          userId: formOwner.id,
        },
      }).then((response) => {
        console.log(response);
        setFormOwnerSetup(response.data.checkAccountActive);
      });
      // const stripeAccountID = "someval"; // formOwner.get("stripeAccountID");
      // Parse.Cloud.run("checkAccountActive", {
      //   stripeAccountID,
      // }).then((response) => {
      //   try {
      //     if (response.charges_enabled) {
      //       setFormOwnerSetup(true);
      //     } else {
      //       setFormOwnerSetup(true);
      //     }
      //   } catch {
      //     setFormOwnerSetup(false);
      //     console.log("failure");
      //   }
      // });
      // findFormProfilePic();
    }
  });

  useEffect(() => {
    const pathname = window.location.pathname;
    const u = pathname.split("/").pop();
    console.log(u);
    setQueryUser(u);
  }, []);

  useEffect(() => {
    const checkCurrentUser = async () => {
      console.log("checking  user");
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log(user);
        if (user === null || user === undefined) {
          history.push("/", {
            data: "form",
            username: queryUser,
          });
        } else {
          if (currentUser === null) {
            const newUser = await API.graphql({
              query: getUser,
              variables: { id: user.attributes.sub },
            });
            setCurrentUser(newUser.data.getUser);
          }
          console.log("attempt");
          const pathname = window.location.pathname;
          const _queryUser = pathname.split("/").pop();
          API.graphql({
            query: usersByUsername,
            variables: { username: _queryUser },
          })
            .then((response) => {
              const u = response.data.usersByUsername.items[0];
              console.log(u);
              setFormOwner(response.data.usersByUsername.items[0]);
            })
            .catch((error) => {
              history.push("/");
              console.log(error);
            });
          // setFormOwner(m_formOwner);
        }
        return true;
      } catch (_error) {
        history.push("/login", {
          data: "form",
          username: queryUser,
        });
      }
      return false;
    };
    checkCurrentUser();
  }, [!formOwner, queryUser]);

  const createNewOffer = async () => {
    console.log(currentUser);
    console.log(formOwner);

    if (!isFormOwnerSetup) {
      return;
    }

    const inputData = {
      input: {
        brand: currentUser.id,
        brandUsername: currentUser.username,
        brandDisplayName: currentUser.displayName,
        creator: formOwner.id,
        creatorUsername: formOwner.username,
        creatorDisplayName: formOwner.displayName,
        budgetRangeIndex: budgetBracket,
        isPaid: false,
        contractState: "NoOffer",
        delivarablesFinalized: false,
        negotiationOpen: true,
        lastUpdate: moment(moment.now()),
      },
    };

    console.log(inputData);

    const newNegotiation = await API.graphql({
      query: createNegotiation,
      variables: inputData,
    });

    const messageInputData = {
      input: {
        negotiationId: newNegotiation.data.createNegotiation.id,
        userId: currentUser.id,
        content: message,
        type: "Message",
      },
    };

    console.log(messageInputData);

    API.graphql({
      query: createMessage,
      variables: messageInputData,
    }).then((response) => {
      console.log(response);
      console.log("success");
      history.push("/");
    });
  };

  const buttonText = () => {
    if (!currentUser.isBrand || madeOffer || !isFormOwnerSetup) {
      return "Return to Dashboard";
    } else {
      return "Send Enquiry";
    }
  };

  const buttonEnabled = () => {
    if (!currentUser.isBrand) {
      return true;
    } else if (!isFormOwnerSetup) {
      return true;
    } else if (budgetBracket != -1 && message != "") {
      return true;
    } else {
      return false;
    }
  };

  const buttonAction = () => {
    if (!currentUser.isBrand) {
      history.push("/");
    } else if (madeOffer) {
      history.push("/");
    } else if (!isFormOwnerSetup) {
      history.push("/");
    } else {
      createNewOffer();
    }
  };

  const inputProps = {
    style: {
      textAlign: "top",
      height: 320,
    },
  };

  if (!formOwner || !currentUser) {
    return <Box></Box>;
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      height={"100%"}
      style={{
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Box
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        backgroundColor={"white"}
        maxWidth={deviceMobile ? "100% " : "1500px"}
      >
        {!isMobile ? (
          <Box
            marginTop={2}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            width={"100%"}
          >
            <Box width={30} />
            <LogoSVG height={55} width={150} />
            <Box style={{ flex: 1 }} />
            <StyledBox
              onClick={() => history.push("/")}
              display={"flex"}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                height={30}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Typography
                  fontWeight={600}
                  fontFamily={"Outfit"}
                  fontSize={isMobile ? 14 : 16}
                  align="right"
                  color={"black"}
                >
                  {currentUser?.username}
                </Typography>
                <Typography
                  fontWeight={500}
                  fontFamily={"Outfit"}
                  fontSize={isMobile ? 12 : 14}
                  color={"#3F3F3F"}
                  align="right"
                >
                  {currentUser?.isBrand ? "Customer" : "Avertiser"}
                </Typography>
              </Box>
              <Box width={5} />
              <img
                style={{
                  width: isMobile ? 30 : 50,
                  height: isMobile ? 30 : 50,
                  borderRadius: 1000,
                }}
                src={profilePic ?? ""}
                alt={""}
                loading="lazy"
              />
            </StyledBox>
            <Box width={20} />
          </Box>
        ) : (
          <Box
            style={{
              backgroundColor: "#e9ecf0",
              width: "100%",
              height: "7%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                position: "absolute",
                width: "100%",
                height: "18%",
                overflow: "hidden",
              }}
            >
              <LogoNoTextSVG
                width={500}
                height={500}
                viewBox={"50 535 476 227"}
                style={{ position: "absolute" }}
              />
            </Box>
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box width={18} />
              <Box flex={1} />
              <LogoSVG width={150} height={50} />
            </Box>
          </Box>
        )}
        {editingMessage ? (
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!isMobile && (
              <Box
                style={{
                  marginTop: 15,
                  display: "flex",
                  flexDirection: "row",
                  width: isMobile ? "100%" : 476,
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    fontFamily={"Outfit"}
                    fontSize={18}
                    fontWeight={600}
                    color={"#4D4D4D"}
                  >
                    Message to advertiser
                  </Typography>
                  <Box height={3} />
                  <Typography
                    color={"#AAA"}
                    fontFamily={"Outfit"}
                    fontSize={14}
                    fontWeight={500}
                  >
                    Let the advertiser know a bit more about you. What does your
                    company do? What do you want advertised and how?
                  </Typography>
                </Box>
              </Box>
            )}
            <Box height={15} />
            <textarea
              ref={inputRef}
              style={{ width: isMobile ? "100%" : 476 }}
              ontouchstart=""
              id="formMessageInputID"
              type={"email"}
              rows="5"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            ></textarea>
            <Box height={10} />
            <Box style={{ height: 35, width: isMobile ? "100%" : 476 }}>
              <CustomButton
                onClick={() => {
                  setEditingMessage(false);
                }}
                sx={{
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
                style={{
                  textTransform: "none",
                  height: "100%",
                  width: isMobile ? "100%" : 476,
                  flex: 1,
                  fontSize: 16,
                  fontWeight: 600,
                  fontFamily: "Outfit",
                  borderRadius: 15,
                  backgroundColor: "#E2D4FF",
                  color: "#795CB8",
                }}
                variant={"contained"}
              >
                Return
              </CustomButton>
            </Box>
          </Box>
        ) : (
          <Box
            backgroundColor={"#FFF"}
            padding={4}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            {formProfilePic ? (
              <img
                style={{
                  width: 80,
                  height: 80,
                  borderRadius: 1000,
                  objectFit: "cover",
                }}
                src={formProfilePic ?? ""}
                loading="lazy"
              />
            ) : (
              <Box
                style={{
                  width: 80,
                  height: 80,
                  borderRadius: 1000,
                  backgroundColor: "#E2D4FF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  align="center"
                  fontWeight={600}
                  color={"#795CB8"}
                  fontSize={40}
                  fontFamily={"Nunito"}
                >
                  {currentUser.isBrand
                    ? formOwner.username.charAt(0).toUpperCase()
                    : formOwner.username.charAt(0).toUpperCase()}
                </Typography>
              </Box>
            )}
            <Box height={16} />
            <Typography
              fontFamily={"Outfit"}
              textAlign={"center"}
              fontWeight={"600"}
              color={"#4D4D4D"}
              fontSize={22}
            >
              <span>
                Make an offer to{" "}
                <span style={{ color: "#795CB8" }}>
                  {formOwner.displayName}
                </span>
              </span>
            </Typography>
            <Box height={16} />
            <Box
              height={2}
              width={isMobile ? "110%" : 476}
              backgroundColor={"#EEE"}
            />

            <Box height={26} />
            {madeOffer ? (
              <Typography
                color={"#AAA"}
                fontFamily={"Outfit"}
                fontSize={18}
                fontWeight={500}
              >
                {`Enquiry successfully sent!`}
              </Typography>
            ) : (
              <Box
                display={"flex"}
                width={"110%"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                {!currentUser.isBrand && (
                  <Box
                    style={{
                      borderColor: "red",
                      borderRadius: 15,
                      padding: 5,
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#D1D8E0",
                      display: "flex",
                      marginBottom: 20,
                    }}
                    width={isMobile ? "100%" : 476}
                  >
                    <Typography
                      style={{
                        fontFamily: "Outfit",
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#1A3A63",
                      }}
                    >
                      Viewing form in read-only mode
                    </Typography>
                  </Box>
                )}

                {currentUser.isBrand && !isFormOwnerSetup && (
                  <Box
                    style={{
                      borderColor: "red",
                      borderRadius: 15,
                      padding: 5,
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(255, 0, 0, 0.2)",
                      display: "flex",
                      marginBottom: 20,
                    }}
                    width={isMobile ? "100%" : 476}
                  >
                    <Typography
                      style={{
                        fontFamily: "Outfit",
                        fontSize: 16,
                        fontWeight: 500,
                        color: "red",
                      }}
                    >
                      This user has not finished setting up their account and
                      cannot accept offers
                    </Typography>
                  </Box>
                )}
                <Box
                  border={0}
                  style={{
                    borderColor: "red",
                    borderRadius: 10,
                    padding: 10,
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                  backgroundColor={"#F1F2F5"}
                  width={isMobile ? "100%" : 476}
                >
                  <Typography
                    noWrap
                    style={{
                      width: "100%",
                      textAlign: "center",
                      wordWrap: "break-all",
                      display: "flex",
                    }}
                    color={"#4D4D4D"}
                    fontFamily={"Outfit"}
                    fontSize={16}
                    fontWeight={500}
                  >
                    <pre
                      style={{
                        margin: 0,
                        width: "100%",
                        fontFamily: "inherit",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {formOwner.bio ?? ""}
                    </pre>
                  </Typography>
                </Box>
                <Box height={36} />
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: isMobile ? "100%" : 476,
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      fontFamily={"Outfit"}
                      fontSize={18}
                      fontWeight={600}
                      color={"#4D4D4D"}
                    >
                      Budget
                    </Typography>
                    <Box height={3} />
                    <Typography
                      color={"#AAA"}
                      fontFamily={"Outfit"}
                      fontSize={14}
                      fontWeight={500}
                    >
                      An estimate of what you are looking to spend
                    </Typography>
                  </Box>
                  <Box flex={1} />
                  <FormControl style={{ minWidth: 120 }}>
                    <Select
                      disabled={!currentUser.isBrand}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={budgetBracket}
                      onChange={handleChange}
                      style={{
                        backgroundColor: !currentUser.isBrand
                          ? "#F6F7F9"
                          : "#FFF",
                        height: 45,
                        borderRadius: 15,
                      }}
                    >
                      <MenuItem value={0}>$0-$100</MenuItem>
                      <MenuItem value={1}>$100-$500</MenuItem>
                      <MenuItem value={2}>$500-$1000</MenuItem>
                      <MenuItem value={3}>$1000-$5000</MenuItem>
                      <MenuItem value={4}>$5000+</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box height={26} />

                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: isMobile ? "100%" : 476,
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      fontFamily={"Outfit"}
                      fontSize={18}
                      fontWeight={600}
                      color={"#4D4D4D"}
                    >
                      Message to advertiser
                    </Typography>
                    <Box height={3} />
                    <Typography
                      color={"#AAA"}
                      fontFamily={"Outfit"}
                      fontSize={14}
                      fontWeight={500}
                    >
                      Let the advertiser know a bit more about you. What does
                      your company do? What do you want advertised and how?
                    </Typography>
                  </Box>
                </Box>

                <TextField
                  onClick={() => {
                    if (deviceMobile) setEditingMessage(true);
                  }}
                  disabled={!currentUser.isBrand}
                  multiline
                  defaultValue={message}
                  onChange={(event) => setMessage(event.target.value)}
                  inputProps={inputProps}
                  style={{
                    backgroundColor: !currentUser.isBrand ? "#F6F7F9" : "#FFF",
                    marginTop: 16,
                    width: isMobile ? "100%" : 476,
                  }}
                />
              </Box>
            )}

            <Box style={{ height: 26 }} />
            <Box style={{ height: 35, width: isMobile ? "110%" : 476 }}>
              <CustomButton
                disabled={!buttonEnabled()}
                onClick={() => {
                  buttonAction();
                }}
                loadingIndicator={{
                  style: { color: "#4A587C" },
                }}
                sx={{
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
                style={{
                  textTransform: "none",
                  height: "100%",
                  width: "100%",
                  flex: 1,
                  fontSize: 16,
                  fontWeight: 600,
                  fontFamily: "Outfit",
                  borderRadius: 15,
                  backgroundColor: buttonEnabled() ? "#E2D4FF" : "#F1EAFF",
                  color: buttonEnabled() ? "#795CB8" : "#E2D4FF",
                }}
                variant={"contained"}
              >
                {loading ? (
                  <CircularProgress size={16} style={{ color: "#4A587C" }} />
                ) : (
                  buttonText()
                )}
              </CustomButton>
            </Box>
          </Box>
        )}
        <Box height={55} />
      </Box>
    </Box>
  );
};

export default FormTemplate;
