import React from "react";
import { Box, Typography } from "@mui/material";
import iphoneCreateContract from "src/Images/iphoneCreateContract.png";
import iphoneCreateContract2 from "src/Images/iphoneCreateContract2.png";
import SwapIcon from "src/Images/SwapIcon";
import LandingStepByStepIcon from "src/Images/LandingStepByStepIcon";

const CreateContract = () => {
  return (
    <Box style={{ height: 650 }}>
      <Box height={50} />

      <Box
        style={{
          width: "100%",
          maxWidth: 1440,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={iphoneCreateContract} alt="" width={295} />
        <Box
          style={{
            display: "flex",
            flex: 1,
            width: 450,
            height: "90%",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LandingStepByStepIcon />
            <Box height={14} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 20,
                fontWeight: 700,
                color: "#795CB8",
                lineHeight: 1,
              }}
            >
              Step by Step
            </Typography>
            <Box height={14} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 18,
                fontWeight: 500,
                color: "#57616F",
                lineHeight: 1,
                width: 300,
              }}
            >
              Allow Proffera to take you from contract creation through to
              fulfillment.
            </Typography>
          </Box>
          <Box height={80} />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <SwapIcon />
            <Box height={14} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 20,
                fontWeight: 700,
                color: "#795CB8",
                lineHeight: 1,
              }}
            >
              Negotiate Contracts
            </Typography>
            <Box height={14} />
            <Typography
              style={{
                textAlign: "center",
                fontFamily: "nunito",
                fontSize: 18,
                fontWeight: 500,
                color: "#57616F",
                lineHeight: 1,
                width: 300,
              }}
            >
              Clearly and simply layout contracts to send to brands.
            </Typography>
          </Box>
          <Box height={90} />
        </Box>
        <img src={iphoneCreateContract2} alt="" width={295} />
      </Box>
    </Box>
  );
};
export default CreateContract;
