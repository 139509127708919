import { Box, Typography } from "@mui/material";
import LogoSVG from "src/Images/Logo";
import LogoNoTextSVG from "src/Images/LogoNoText";
import backarrow from "src/Images/backarrow.png";

const CodeScreen = ({
  setLocation,
  emailInput,
  setEmailInput,
  passwordInput,
  setPasswordInput,
  doSignup,
  isMobile,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: isMobile ? "100vh" : "100%",
        width: isMobile ? "100vw" : "100%",
      }}
    >
      <Box
        style={{
          backgroundColor: "#e9ecf0",
          width: "100%",
          height: "7%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            position: "absolute",
            width: "100%",
            height: "18%",
            overflow: "hidden",
          }}
        >
          <LogoNoTextSVG
            width={500}
            height={500}
            viewBox={"50 535 476 227"}
            style={{ position: "absolute" }}
          />
        </Box>
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box width={18} />
          <Box
            onClick={() => setLocation("Home")}
            style={{
              cursor: "pointer",
              zIndex: 2,
              height: 35,
              width: 35,
              borderRadius: 1000,
              backgroundColor: "#FCFCFC",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img style={{ height: 25, width: 25 }} src={backarrow} alt="" />
          </Box>
          <Box flex={1} />
          <LogoSVG width={150} height={50} />
        </Box>
      </Box>
      <Box height={35} />
      <Typography
        style={{
          width: "85%",
          textAlign: "left",
          fontFamily: "Outfit",
          fontSize: 16,
          fontWeight: 500,
          color: "#515151",
        }}
      >
        Enter code to view offer status
      </Typography>
      <Box height={15} />
      <input
        disabled={true}
        id={"inputID"}
        style={{
          height: 50,
          width: "90%",
          borderRadius: 15,
          borderWidth: 1,
          borderColor: "#CCC",
          paddingLeft: 15,
          paddingRight: 15,
          fontWeight: 500,
          fontFamily: "Outfit",
          fontSize: 16,
        }}
        type={"code"}
        placeholder={"This feature is currently still in development"}
        onChange={(event) => setEmailInput(event.target.value)}
      ></input>

      <Box height={30} />

      <Box
        border={2}
        style={{
          height: 50,
          width: "90%",
          borderRadius: 20,
          backgroundColor: "#F1EAFF",
          borderWidth: 0,
          color: "#795CB8",
          fontFamily: "Outfit",
          fontWeight: 500,
          fontSize: 16,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          fontWeight={600}
          fontFamily={"Outfit"}
          fontSize={16}
          color={"#E2D4FF"}
        >
          View
        </Typography>
      </Box>
    </Box>
  );
};
export default CodeScreen;
