import "./App.css";
import { Spin } from "antd";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { listNegotiations, getUser } from "./graphql/queries";
import { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";

import OfferCard from "./components/OfferCard";
import OfferCardMobile from "./components/OfferCardMobile";
import * as subscriptions from "./graphql/subscriptions";

export const OfferList = (props) => {
  const [offers, setOffers] = useState([]);

  const [currentUser, setCurrentUser] = useState(null);

  // useEffect(() => {
  //   const checkCurrentUser = async () => {
  //     const u = await Auth.currentAuthenticatedUser();
  //     API.graphql({
  //       query: getUser,
  //       variables: { id: u.attributes.sub },
  //     })
  //       .then((response) => {
  //         const target = response.data.getUser;
  //         setCurrentUser(target);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };
  //   checkCurrentUser();
  // }, []);

  useEffect(() => {
    const sub = API.graphql(
      graphqlOperation(subscriptions.onDeleteNegotiation)
    ).subscribe({
      next: ({ provider, value }) => fetchOffers(),
      error: (error) => console.warn(error),
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    console.log("here is the currentUser: " + props.currentUser);
    if (props.currentUser) fetchOffers();
  }, [props.currentUser, props.sideBarItemSelected]);

  useEffect(() => {
    console.log("count " + offers.length);
  }, [offers]);

  const fetchOffers = async () => {
    console.log("checking: " + props.currentUser.id);
    var firstFilter = {
      creator: { eq: props.currentUser.id },
      negotiationOpen: { eq: false },
    };

    var secondFilter = {
      creator: { eq: props.currentUser.id },
      contractState: { ne: "NoOffer" },
      negotiationOpen: { eq: true },
    };

    var thirdFilter = {
      creator: { eq: props.currentUser.id },
      contractState: { eq: "NoOffer" },
    };

    var fourthFilter = {
      creator: { eq: props.currentUser.id },
      isPaid: { eq: true },
      negotiationOpen: { eq: true },
    };

    if (props.currentUser.isBrand) {
      firstFilter = {
        brand: { eq: props.currentUser.id },
        negotiationOpen: { eq: false },
      };

      secondFilter = {
        brand: { eq: props.currentUser.id },
        contractState: { ne: "NoOffer" },
        negotiationOpen: { eq: true },
      };

      thirdFilter = {
        brand: { eq: props.currentUser.id },
        contractState: { eq: "NoOffer" },
      };

      fourthFilter = {
        brand: { eq: props.currentUser.id },
        isPaid: { eq: true },
        negotiationOpen: { eq: true },
      };
    }

    console.log(thirdFilter);

    if (props.sideBarItemSelected === "Completed Contracts") {
      API.graphql({
        query: listNegotiations,
        variables: {
          filter: firstFilter,
        },
      }).then((response) => {
        console.log(response.data);
        setOffers(response.data.listNegotiations.items);
        console.log(response.data.listNegotiations.items);
      });
    } else if (props.sideBarItemSelected === "Active Contracts") {
      API.graphql({
        query: listNegotiations,
        variables: {
          filter: secondFilter,
        },
      }).then((response) => {
        setOffers(response.data.listNegotiations.items);
        console.log(response.data.listNegotiations.items);
      });
    } else if (props.sideBarItemSelected === "Due Dates") {
      API.graphql({
        query: listNegotiations,
        variables: {
          filter: fourthFilter,
        },
      }).then((response) => {
        setOffers(response.data.listNegotiations.items);
        console.log(response.data.listNegotiations.items);
      });
    } else {
      console.log("new offer");
      API.graphql({
        query: listNegotiations,
        variables: { filter: thirdFilter },
      }).then((response) => {
        console.log(response);
        setOffers(response.data.listNegotiations.items);
        console.log(response.data.listNegotiations.items);
      });
    }
  };

  const openOfferCallback = (result) => {
    props.selectChannelCallback(result);
  };

  const filteredOffers = offers.filter((offer) =>
    props.currentUser.isBrand
      ? offer.creatorDisplayName
          .toLowerCase()
          .includes(props.searchTerm.toLowerCase())
      : offer.brandDisplayName
          .toLowerCase()
          .includes(props.searchTerm.toLowerCase())
  );

  return (
    <div>
      {/* {isLoading && <Spin />} */}
      {/* {error && <p>{error.message}</p>} */}
      {offers && props.currentUser && (
        <Grid
          container
          pl={props.isMobile ? 0 : 1}
          spacing={props.isMobile ? 3 : 5}
        >
          {/* {results.length > 0 ? (
            results
              .sort((a, b) => a.get("name") - b.get("name"))
              .map((result, idx) => (
                <Grid
                  item
                  key={idx}
                  style={{ width: props.isMobile ? "100%" : "none" }}
                >
                  {props.isMobile ? (
                    <OfferCardMobile
                      EndNegotiation={props.EndNegotiation}
                      openOfferCallback={openOfferCallback}
                      currentUser={props.currentUser}
                      offer={result}
                    />
                  ) : (
                    <OfferCard
                      EndNegotiation={props.EndNegotiation}
                      openOfferCallback={openOfferCallback}
                      currentUser={props.currentUser}
                      offer={result}
                    />
                  )}
                </Grid>
              )) */}

          {offers.length > 0 ? (
            filteredOffers
              .sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate))
              .map((result, idx) => (
                <Grid
                  item
                  key={idx}
                  style={{ width: props.isMobile ? "100%" : "none" }}
                >
                  {props.isMobile ? (
                    <OfferCardMobile
                      EndNegotiation={props.EndNegotiation}
                      openOfferCallback={openOfferCallback}
                      currentUser={props.currentUser}
                      sideBarItemSelected={props.sideBarItemSelected}
                      offer={result}
                    />
                  ) : (
                    <OfferCard
                      EndNegotiation={props.EndNegotiation}
                      openOfferCallback={openOfferCallback}
                      currentUser={props.currentUser}
                      sideBarItemSelected={props.sideBarItemSelected}
                      offer={result}
                    />
                  )}
                </Grid>
              ))
          ) : (
            <Grid item style={{ width: "100%" }}>
              <Box
                style={{
                  flex: 1,
                  backgroundColor: "#DDD",
                  padding: 5,
                  borderRadius: 1000,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  textAlign={"center"}
                  color={"#fff"}
                  fontWeight={600}
                >
                  {props.sideBarItemSelected == "Due Dates"
                    ? "You're up to date!"
                    : "No offers yet"}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};
