import { Box } from "@mui/material";
import { SettingsBody, SettingsHeading } from "src/styles/AppStyles";
import { styled } from "@mui/system";
import { SUPPORT_STRINGS } from "src/statics/strings";

const SupportWrapper = styled(Box)`
  margin-top: 35px;
  margin-bottom: 35px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const Divider = styled(Box)`
  width: 100%;
  height: 2px;
  background-color: #eee;
`;

const Support = ({ isMobile }) => {
  return (
    <Box>
      <SupportWrapper>
        <Box>
          <SettingsHeading>{SUPPORT_STRINGS.EMAIL}</SettingsHeading>
          <Box height={3} />
          <SettingsBody>{SUPPORT_STRINGS.SUPPORT_BODY}</SettingsBody>
        </Box>
      </SupportWrapper>
      <Box height={isMobile ? 40 : 0} />
      <Divider />
    </Box>
  );
};

export default Support;
