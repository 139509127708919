import { Box, Typography } from "@mui/material";

const DateLine = ({ dateString }) => (
  <Box
    sx={{
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      my: 2,
    }}
  >
    <Box height={1.5} flex={1} backgroundColor={"#F8F8F8"} />
    <Typography
      color={"#929BB1"}
      padding={2}
      fontSize={12}
      fontFamily={"Outfit"}
      fontWeight={600}
    >
      {dateString}
    </Typography>
    <Box height={1.5} flex={1} backgroundColor={"#F8F8F8"} />
  </Box>
);
export default DateLine;
