import DateLine from "./Subcomponents/DateLine";
import Avatar from "./Subcomponents/Avatar";
import Message from "./Subcomponents/Message";
import { Box } from "@mui/material";

const ChatBubble = ({
  showDateLine,
  date1,
  mine,
  profilePic,
  currentUser,
  messageText,
  otherUsername,
}) => (
  <Box>
    {showDateLine && <DateLine dateString={date1.format("D MMM HH:mm")} />}
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        my: 1,
      }}
    >
      {!mine && <Avatar profilePic={profilePic} currentUser={currentUser} />}
      {mine && <Box style={{ flex: 1 }} />}
      <Box width={12} />
      <Message
        mine={mine}
        time={date1.format("h:mm a")}
        username={otherUsername}
        text={messageText}
      />
    </Box>
  </Box>
);
export default ChatBubble;
