import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import { ButtonUnstyled } from "@mui/base";
import { styled } from "@mui/system";
import { API, Storage } from "aws-amplify";
import { getUser } from "src/graphql/queries";
import moment from "moment";
import { GetStatusButtonData } from "../logic/StatusLabelLogic";
import More from "../Images/more.png";
import ConfirmationPopup from "../Modals/ConfirmationPopup";
import TextFormModal from "../Modals/LodgeDisputePopup";

const CustomButton = styled(ButtonUnstyled)`
  font-family: Outfit;
  font-weight: 600;
  font-size: 12;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  background-color: white;

  &:hover {
    background-color: ${"#EEE"};
  }
`;

const StyledBox = styled(Box)`
  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
  }
`;

moment.relativeTimeThreshold("s", 60);
moment.relativeTimeThreshold("m", 60);
moment.relativeTimeThreshold("h", 24);
moment.relativeTimeThreshold("d", 31);
moment.relativeTimeThreshold("M", 12);

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const OfferCard = ({
  offer,
  openOfferCallback,
  EndNegotiation,
  currentUser,
  sideBarItemSelected,
}) => {
  const [profilePic, setProfilePic] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasUpdate, setHasUpdate] = useState(false);
  const [heldItem, setHeldItem] = React.useState(null);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [disputeOpen, setDisputeOpen] = useState(false);

  useEffect(() => {
    if (currentUser.isBrand) {
      if (offer.brandLastSeen === null) {
        setHasUpdate(true);
      } else if (
        moment(offer.brandLastSeen).add(5, "seconds").isBefore(offer.lastUpdate)
      ) {
        setHasUpdate(true);
      } else {
        setHasUpdate(false);
      }
    } else {
      console.log(offer.creatorLastSeen);
      console.log(offer.lastUpdate);
      if (offer.creatorLastSeen === null) {
        setHasUpdate(true);
      } else if (
        moment(offer.creatorLastSeen)
          .add(5, "seconds")
          .isBefore(offer.lastUpdate)
      ) {
        setHasUpdate(true);
      } else {
        setHasUpdate(false);
      }
    }
    console.log(hasUpdate);
  });

  console.log(currentUser);

  const confirmationText =
    "Are you sure you want to delete this offer? You will not be able to undo this action.";

  const altConfirmationText =
    "You cannot delete an offer that has already been accepted or that is in dispute.";

  const findProfilePic = async (user) => {
    const response = await API.graphql({
      query: getUser,
      variables: { id: currentUser.isBrand ? offer.creator : offer.brand },
    });

    const profilePic = response.data.getUser.profilePic;

    if (profilePic != null) {
      const url = await Storage.get(profilePic.key, { key: profilePic.bucket });
      setProfilePic(url);
    }
  };

  const RunHeldFunction = () => {
    heldItem.stopPropagation();
    EndNegotiation(offer.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    findProfilePic();
  });

  useEffect(() => {
    console.log(hasUpdate);
  }, [hasUpdate]);

  const { statusBackColor, statusTextColor } = GetStatusButtonData(offer);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const findOfferStateText = () => {
    if (
      offer.contractState === "NoOffer" ||
      offer.contractState === "Editing"
    ) {
      return "Pre Offer";
    } else if (offer.contractState === "Pending") {
      return "Offer Pending";
    } else if (offer.contractState === "Accepted" && !offer.isPaid) {
      return "Awaiting Payment";
    } else if (offer.isPaid && !offer.delivarablesFinalized) {
      return "Awaiting Fulfillment";
    } else if (
      offer.isPaid &&
      offer.delivarablesFinalized &&
      offer.negotiationOpen
    ) {
      return "Fulfilled";
    } else if (
      offer.isPaid &&
      offer.delivarablesFinalized &&
      !offer.negotiationOpen
    ) {
      return "Closed";
    } else {
      return "Unknown";
    }
  };

  const budgetIndexToValue = () => {
    var budgetIndex = offer.budgetRangeIndex;

    if (budgetIndex === 0) {
      return "0-$100";
    } else if (budgetIndex === 1) {
      return "100-$500";
    } else if (budgetIndex === 2) {
      return "500-$1000";
    } else if (budgetIndex === 3) {
      return "1000-$5000";
    } else if (budgetIndex === 4) {
      return "5000+";
    }
  };

  return (
    <StyledBox
      height={344}
      width={183}
      alignItems={"center"}
      display={"flex"}
      flexDirection={"column"}
      position={"relative"}
    >
      <ConfirmationPopup
        setConfirmationOpen={setConfirmationOpen}
        confirmationOpen={confirmationOpen}
        RunHeldFunction={RunHeldFunction}
        confirmationText={confirmationText}
        altConfirmationText={altConfirmationText}
        showCheckBox={false}
        preventConfirm={
          false
          // offer.attributes.Status === "Awaiting Fulfillment" ||
          // offer.attributes.Status === "Fulfilled" ||
          // offer.attributes.Status === "In Dispute"
        }
      />
      {offer && currentUser && (
        <TextFormModal
          offer={offer}
          currentUser={currentUser}
          open={disputeOpen}
          setOpen={setDisputeOpen}
          activeDispute={"Active" === "In Dispute"}
        />
      )}
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
        style={{
          position: "absolute",
          color: "#4A587C",
          backgroundColor: "white",
          height: 25,
          width: 25,
          right: 10,
          top: 35,
        }}
      >
        <img
          style={{ width: 25, height: 25, borderRadius: 1000 }}
          src={More ?? ""}
          alt={""}
          loading="lazy"
        />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => {
          handleClose();
          e.stopPropagation(e);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={(e) => {
            handleClose();
            setHeldItem(e);
            setConfirmationOpen(true);
            e.stopPropagation(e);
          }}
        >
          Delete Offer
        </MenuItem>
        {/* <MenuItem
          onClick={(e) => {
            e.stopPropagation(e);
            handleClose();
            setDisputeOpen(true);
          }}
        >
          Lodge Dispute
        </MenuItem> */}
      </Menu>
      <Box
        zIndex={2}
        backgroundColor={"#FFF"}
        borderRadius={100}
        width={92}
        height={92}
        border={hasUpdate ? 4 : 2}
        style={{
          display: "flex",
          overflow: "hidden",
          justifyContent: "center",
          alignItems: "center",
          borderColor: "#4A587C",
        }}
      >
        {profilePic ? (
          <img
            style={{ width: 92, height: 92, borderRadius: 1000 }}
            src={profilePic ?? "./Images/DefaultProfilePic.png"}
            alt={""}
            loading="lazy"
          />
        ) : (
          <Box
            style={{
              width: 92,
              height: 92,
              borderRadius: 1000,
              backgroundColor: "#E2D4FF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              align="center"
              fontWeight={600}
              color={"#795CB8"}
              fontSize={40}
              fontFamily={"Nunito"}
            >
              {currentUser.isBrand
                ? offer.creatorDisplayName.charAt(0).toUpperCase()
                : offer.brandDisplayName.charAt(0).toUpperCase()}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        height={235}
        width={183}
        border={hasUpdate ? 5 : 2}
        style={{
          borderRadius: 20,
          marginTop: -69,
          borderColor: "#4A587C",
          display: "flex",
          backgroundColor: hasUpdate ? "#F1F2F5" : "#FFF",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            marginTop: 80,
            height: 50,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            style={{
              cursor: "default",
              fontWeight: 600,
              fontSize: 22,
              fontFamily: "Outfit",
              color: "#4D4D4D",
              textAlign: "center",
              lineHeight: "110%",
              paddingRight: 10,
              paddingLeft: 10,
            }}
          >
            {currentUser.isBrand
              ? offer.creatorDisplayName
              : offer.brandDisplayName}
          </Typography>
        </Box>
        <Typography
          style={{
            cursor: "default",
            fontFamily: "Outfit",
            fontSize: 16,
            fontWeight: 500,
            color: "#929292",
          }}
        >
          {`${moment(offer.lastUpdate).fromNow()}`}
        </Typography>
        <Box flex={1} />
        <CustomButton
          onClick={() => openOfferCallback(offer)}
          style={{
            borderColor: "#4A587C",
            textTransform: "none",
            height: 28,
            width: 137,
            fontSize: 14,
            fontWeight: 600,
            fontFamily: "Outfit",
            borderRadius: 50,
            color: "#4A587C",
          }}
          variant={"contained"}
        >
          View Offer
        </CustomButton>
        <Box height={20} />
      </Box>
      <Box height={13} />
      <Box
        border={hasUpdate ? 3 : 1.5}
        style={{
          height: 30,
          width: 183,
          borderRadius: 10,
          borderColor: statusTextColor,
          backgroundColor: statusBackColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            cursor: "default",
            color: statusTextColor,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {sideBarItemSelected === "Due Dates"
            ? offer.date
              ? `Due ${moment().to(offer.date)}`
              : ""
            : findOfferStateText()}
        </Typography>
      </Box>
      <Box height={13} />
      <Box
        border={hasUpdate ? 3 : 1.5}
        style={{
          height: 30,
          width: 183,
          borderRadius: 10,
          borderColor: "#929292",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            cursor: "default",
            color: "#929292",
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          ${offer.payment ?? budgetIndexToValue(offer.Budget)}
        </Typography>
      </Box>
    </StyledBox>
  );
};
export default OfferCard;
