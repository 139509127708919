/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const testStuff = /* GraphQL */ `
  query TestStuff($negotiationId: String!) {
    testStuff(negotiationId: $negotiationId)
  }
`;
export const createStripeUser = /* GraphQL */ `
  query CreateStripeUser {
    createStripeUser
  }
`;
export const checkAccountActive = /* GraphQL */ `
  query CheckAccountActive($userId: String!) {
    checkAccountActive(userId: $userId)
  }
`;
export const updatePaymentInfo = /* GraphQL */ `
  query UpdatePaymentInfo($userId: String!, $location: String!) {
    updatePaymentInfo(userId: $userId, location: $location)
  }
`;
export const createNewUser = /* GraphQL */ `
  query CreateNewUser(
    $userId: String!
    $username: String!
    $email: String!
    $isBrand: Boolean!
    $displayName: String!
  ) {
    createNewUser(
      userId: $userId
      username: $username
      email: $email
      isBrand: $isBrand
      displayName: $displayName
    )
  }
`;
export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      profilePic {
        bucket
        key
        region
      }
      isBrand
      displayName
      bio
      showNewUserInfo
      stripeId
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        profilePic {
          bucket
          key
          region
        }
        isBrand
        displayName
        bio
        showNewUserInfo
        stripeId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByUsername = /* GraphQL */ `
  query UsersByUsername(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        email
        profilePic {
          bucket
          key
          region
        }
        isBrand
        displayName
        bio
        showNewUserInfo
        stripeId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNegotiation = /* GraphQL */ `
  query GetNegotiation($id: ID!) {
    getNegotiation(id: $id) {
      id
      brand
      brandUsername
      brandDisplayName
      brandLastSeen
      creator
      creatorUsername
      creatorDisplayName
      creatorLastSeen
      lastUpdate
      payment
      budgetRangeIndex
      requirements
      isPaid
      date
      additionalNotes
      contractState
      delivarablesFinalized
      negotiationOpen
      stripePaymentIntent
      createdAt
      updatedAt
    }
  }
`;
export const listNegotiations = /* GraphQL */ `
  query ListNegotiations(
    $filter: ModelNegotiationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNegotiations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brand
        brandUsername
        brandDisplayName
        brandLastSeen
        creator
        creatorUsername
        creatorDisplayName
        creatorLastSeen
        lastUpdate
        payment
        budgetRangeIndex
        requirements
        isPaid
        date
        additionalNotes
        contractState
        delivarablesFinalized
        negotiationOpen
        stripePaymentIntent
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      negotiationId
      userId
      content
      type
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        negotiationId
        userId
        content
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByNegotiationId = /* GraphQL */ `
  query MessagesByNegotiationId(
    $negotiationId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByNegotiationId(
      negotiationId: $negotiationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        negotiationId
        userId
        content
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByDate = /* GraphQL */ `
  query MessagesByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        negotiationId
        userId
        content
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
