import React, { useState, useEffect } from "react";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import { API, Storage } from "aws-amplify";
import { getUser } from "src/graphql/queries";
import moment from "moment";
import { GetStatusButtonData } from "../logic/StatusLabelLogic";
import ConfirmationPopup from "../Modals/ConfirmationPopup";
import TextFormModal from "../Modals/LodgeDisputePopup";

moment.relativeTimeThreshold("s", 60);
moment.relativeTimeThreshold("m", 60);
moment.relativeTimeThreshold("h", 24);
moment.relativeTimeThreshold("d", 31);
moment.relativeTimeThreshold("M", 12);

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const OfferCardMobile = ({
  offer,
  currentUser,
  openOfferCallback,
  EndNegotiation,
  sideBarItemSelected,
}) => {
  const [profilePic, setProfilePic] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasUpdate, setHasUpdate] = useState(false);
  const [heldItem, setHeldItem] = React.useState(null);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [disputeOpen, setDisputeOpen] = useState(false);

  const confirmationText =
    "Are you sure you want to delete this offer? You will not be able to undo this action.";

  const altConfirmationText =
    "You cannot delete an offer that has already been accepted or that is in dispute.";

  // const findProfilePic = async (user) => {
  //   if (user) {
  //     const file = await user.get("ProfilePic");
  //     if (file) setProfilePic(file._url);
  //   }
  // };

  const RunHeldFunction = () => {
    heldItem.stopPropagation();
    EndNegotiation(offer.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   if (offer.attributes.owner.id === currentUser.id) {
  //     if (
  //       moment(offer.attributes.customerLastViewed)
  //         .add(5, "seconds")
  //         .isBefore(offer.attributes.updatedAt)
  //     )
  //       setHasUpdate(true);
  //     else setHasUpdate(false);
  //   } else {
  //     if (
  //       moment(offer.attributes.advertiserLastViewed)
  //         .add(5, "seconds")
  //         .isBefore(offer.attributes.updatedAt)
  //     )
  //       setHasUpdate(true);
  //     else setHasUpdate(false);
  //   }
  // });

  useEffect(() => {
    if (currentUser.isBrand) {
      if (offer.brandLastSeen === null) {
        setHasUpdate(true);
      } else if (
        moment(offer.brandLastSeen).add(5, "seconds").isBefore(offer.lastUpdate)
      ) {
        setHasUpdate(true);
      } else {
        setHasUpdate(false);
      }
    } else {
      console.log(offer.creatorLastSeen);
      console.log(offer.lastUpdate);
      if (offer.creatorLastSeen === null) {
        setHasUpdate(true);
      } else if (
        moment(offer.creatorLastSeen)
          .add(5, "seconds")
          .isBefore(offer.lastUpdate)
      ) {
        setHasUpdate(true);
      } else {
        setHasUpdate(false);
      }
    }
    console.log(hasUpdate);
  });

  const findProfilePic = async (user) => {
    const response = await API.graphql({
      query: getUser,
      variables: { id: currentUser.isBrand ? offer.creator : offer.brand },
    });

    const profilePic = response.data.getUser.profilePic;

    if (profilePic != null) {
      const url = await Storage.get(profilePic.key, { key: profilePic.bucket });
      setProfilePic(url);
    }
  };

  useEffect(() => {
    findProfilePic();
  });

  const { statusBackColor, statusTextColor } = GetStatusButtonData(offer);

  const open = Boolean(anchorEl);

  const findOfferStateText = () => {
    const { contractState, isPaid, delivarablesFinalized, negotiationOpen } =
      offer;

    if (contractState === "NoOffer" || contractState === "Editing") {
      return "Pre Offer";
    }

    if (contractState === "Pending") {
      return "Offer Pending";
    }

    if (contractState === "Accepted" && !isPaid) {
      return "Awaiting Payment";
    }

    if (isPaid && !delivarablesFinalized) {
      return "Awaiting Fulfillment";
    }

    if (isPaid && delivarablesFinalized && negotiationOpen) {
      return "Fulfilled";
    }

    if (isPaid && delivarablesFinalized && !negotiationOpen) {
      return "Closed";
    }

    return "Unknown";
  };

  const budgetIndexToValue = () => {
    var budgetIndex = offer.budgetRangeIndex;

    if (budgetIndex === 0) {
      return "0-$100";
    } else if (budgetIndex === 1) {
      return "100-$500";
    } else if (budgetIndex === 2) {
      return "500-$1000";
    } else if (budgetIndex === 3) {
      return "1000-$5000";
    } else if (budgetIndex === 4) {
      return "5000+";
    }
  };

  return (
    <Box
      height={120}
      width={"100%"}
      alignItems={"center"}
      display={"flex"}
      flexDirection={"column"}
      position={"relative"}
      onClick={() => openOfferCallback(offer)}
    >
      <ConfirmationPopup
        setConfirmationOpen={setConfirmationOpen}
        confirmationOpen={confirmationOpen}
        RunHeldFunction={RunHeldFunction}
        confirmationText={confirmationText}
        altConfirmationText={altConfirmationText}
        showCheckBox={false}
        preventConfirm={
          false
          // offer.attributes.Status === "Awaiting Fulfillment" ||
          // offer.attributes.Status === "Fulfilled" ||
          // offer.attributes.Status === "In Dispute"
        }
      />
      {offer && currentUser && (
        <TextFormModal
          offer={offer}
          currentUser={currentUser}
          open={disputeOpen}
          setOpen={setDisputeOpen}
          activeDispute={offer.contractState === "In Dispute"}
        />
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => {
          handleClose();
          e.stopPropagation(e);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={(e) => {
            handleClose();
            setHeldItem(e);
            setConfirmationOpen(true);
            e.stopPropagation(e);
          }}
        >
          Delete Offer
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation(e);
            handleClose();
            setDisputeOpen(true);
          }}
        >
          Lodge Dispute
        </MenuItem>
      </Menu>

      <Box
        height={120}
        width={"100%"}
        border={hasUpdate ? 3 : 0}
        style={{
          borderRadius: 15,
          borderColor: "#4A587C",
          display: "flex",
          backgroundColor: hasUpdate ? "#F1F2F5" : "#F1F2F5",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box style={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Box width={18} />
          <Box
            zIndex={2}
            backgroundColor={"#FFF"}
            borderRadius={100}
            width={55}
            height={55}
            border={2}
            style={{
              display: "flex",
              overflow: "hidden",
              justifyContent: "center",
              alignItems: "center",
              borderColor: "#4A587C",
            }}
          >
            {profilePic ? (
              <img
                style={{ width: 55, height: 55, borderRadius: 1000 }}
                src={profilePic ?? ""}
                alt={""}
                loading="lazy"
              />
            ) : (
              <Box
                style={{
                  width: 55,
                  height: 55,
                  borderRadius: 1000,
                  backgroundColor: "#E2D4FF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  align="center"
                  fontWeight={600}
                  color={"#795CB8"}
                  fontSize={30}
                >
                  {currentUser.isBrand
                    ? offer.creatorDisplayName.charAt(0).toUpperCase()
                    : offer.brandDisplayName.charAt(0).toUpperCase()}
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 15,
            }}
          >
            <Typography
              style={{
                cursor: "default",
                fontWeight: 600,
                fontSize: 22,
                fontFamily: "Outfit",
                color: "#000",
                textAlign: "center",
                lineHeight: "110%",
                paddingRight: 10,
                paddingLeft: 10,
              }}
            >
              {currentUser.isBrand
                ? offer.creatorDisplayName
                : offer.brandDisplayName}
            </Typography>
            <Box height={5} />
            <Typography
              style={{
                cursor: "default",
                fontFamily: "Outfit",
                fontSize: 16,
                fontWeight: 500,
                color: "#929292",
              }}
            >
              {`${moment(offer.lastUpdate).fromNow()}`}
            </Typography>
          </Box>
          <Box height={20} />
        </Box>
        <Box height={10} />
        <Box style={{ display: "flex", width: "96%" }}>
          <Box
            border={hasUpdate ? 2 : 1.5}
            style={{
              height: 30,
              flex: 1,
              borderRadius: 10,
              borderColor: statusTextColor,
              backgroundColor: statusBackColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                cursor: "default",
                color: statusTextColor,
                fontSize: 14,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              {sideBarItemSelected === "Due Dates"
                ? offer.date
                  ? `Due ${moment().to(offer.date)}`
                  : ""
                : findOfferStateText()}
            </Typography>
          </Box>
          {sideBarItemSelected !== "Due Dates" && <Box width={13} />}
          {sideBarItemSelected !== "Due Dates" && (
            <Box
              border={hasUpdate ? 2 : 1.5}
              style={{
                height: 30,
                flex: 1,
                borderRadius: 10,
                borderColor: "#929292",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  cursor: "default",
                  color: "#929292",
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                ${offer.payment ?? budgetIndexToValue(offer.Budget)}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default OfferCardMobile;
