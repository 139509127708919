import * as React from "react";

function LandingStepByStepIcon(props) {
  return (
    <svg
      width="70"
      height="63"
      viewBox="0 0 70 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70 28V0H45.5V10.5H24.5V0H0V28H24.5V17.5H31.5V52.5H45.5V63H70V35H45.5V45.5H38.5V17.5H45.5V28H70Z"
        fill="#795CB8"
      />
    </svg>
  );
}

export default LandingStepByStepIcon;
