import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const ConfirmationPopup = ({
  setConfirmationOpen,
  confirmationOpen,
  RunHeldFunction,
  confirmationText,
  altConfirmationText,
  preventConfirm = false,
  showCheckBox = true,
}) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleClose = (event, reason) => {
    event.stopPropagation();
    if (dontShowAgain) RunHeldFunction();
    setConfirmationOpen(false);
  };

  console.log(preventConfirm);

  const handleConfirm = () => {
    // Perform action here
    console.log("thuis ran");
    setConfirmationOpen(false);
    RunHeldFunction();
  };

  return (
    <div>
      <Dialog
        open={confirmationOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {preventConfirm ? altConfirmationText : confirmationText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {showCheckBox && (
            <Checkbox
              checked={dontShowAgain}
              onChange={(e) => setDontShowAgain(e.target.checked)}
              color="primary"
            />
          )}
          {showCheckBox && (
            <Typography fontSize={12} fontFamily={"Roboto"} color={"#4D4D4D"}>
              Don't show this again
            </Typography>
          )}
          <Box flex={1} />
          <Button onClick={handleClose} color="primary">
            {preventConfirm ? "Done" : "Cancel"}
          </Button>
          {!preventConfirm && (
            <Button
              onClick={(e) => {
                handleConfirm();
                e.stopPropagation();
              }}
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ConfirmationPopup;
