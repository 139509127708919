import * as React from "react";

function SwapIcon(props) {
  return (
    <svg
      width="80"
      height="60"
      viewBox="0 0 80 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64 0L48 16H60V44C60 48.4 56.4 52 52 52C47.6 52 44 48.4 44 44V16C44 7.16 36.84 0 28 0C19.16 0 12 7.16 12 16V44H0L16 60L32 44H20V16C20 11.6 23.6 8 28 8C32.4 8 36 11.6 36 16V44C36 52.84 43.16 60 52 60C60.84 60 68 52.84 68 44V16H80L64 0Z"
        fill="#795CB8"
      />
    </svg>
  );
}

export default SwapIcon;
