import { Box, Typography } from "@mui/material";
import { useState } from "react";
import LogoSVG from "src/Images/Logo";
import LogoNoTextSVG from "src/Images/LogoNoText";
import backarrow from "src/Images/backarrow.png";
import { LoadingButton } from "@mui/lab";
import { Auth } from "aws-amplify";
import { AnimatePresence, motion } from "framer-motion";

const ForgotPasswordScreen = ({
  setLocation,
  passwordInput,
  setPasswordInput,
  doSignup,
  isMobile,
}) => {
  const [loading, setLoading] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [hasBeenSent, setHasBeenSent] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [newPasswordHasFailed, setNewPasswordHasFailed] = useState(false);
  const [passwordChangeComplete, setPasswordChangeComplete] = useState(false);

  const doRequestPasswordReset = async function () {
    setLoading(true);

    // Send confirmation code to user's email
    Auth.forgotPassword(emailInput)
      .then((data) => {
        console.log(data);
        setLoading(false);
        setHasBeenSent(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setHasBeenSent(false);
      });
  };

  const setNewPasswordFunc = async function () {
    setLoading(true);

    try {
      const data = await Auth.forgotPasswordSubmit(
        emailInput,
        verifyCode,
        newPassword
      );
      console.log(data);
      setPasswordChangeComplete(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setNewPasswordHasFailed(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: isMobile ? "100vh" : "100%",
        width: isMobile ? "100vw" : "100%",
      }}
    >
      <Box
        style={{
          backgroundColor: "#e9ecf0",
          width: "100%",
          height: "7%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 5,
        }}
      >
        <Box
          style={{
            position: "absolute",
            width: "100%",
            height: "18%",
            overflow: "hidden",
          }}
        >
          <LogoNoTextSVG
            width={500}
            height={500}
            viewBox={"50 535 476 227"}
            style={{ position: "absolute" }}
          />
        </Box>
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box width={18} />
          <Box
            onClick={() => setLocation("Home")}
            style={{
              cursor: "pointer",
              zIndex: 2,
              height: 35,
              width: 35,
              borderRadius: 1000,
              backgroundColor: "#FCFCFC",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img style={{ height: 25, width: 25 }} src={backarrow} alt="" />
          </Box>
          <Box flex={1} />
          <LogoSVG width={150} height={50} />
        </Box>
      </Box>
      <Box height={35} />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          overflow: "hiden",
        }}
      >
        <AnimatePresence exitBeforeEnter>
          {!hasBeenSent ? (
            <motion.div
              key="forgotPass"
              initial={{ y: "0%" }}
              animate={{ y: "0%" }}
              exit={{ y: "-100%" }}
              style={{
                display: "flex",
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
              transition={{ ease: "easeIn", duration: 0.3 }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    width: "90%",
                    textAlign: "left",
                    fontFamily: "Outfit",
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#515151",
                  }}
                >
                  Enter email to send password reset request to
                </Typography>
                <Box height={15} />
                <input
                  disabled={hasBeenSent}
                  id={"inputID"}
                  style={{
                    height: 50,
                    width: "90%",
                    borderRadius: 15,
                    borderWidth: 1,
                    borderColor: "#CCC",
                    paddingLeft: 15,
                    paddingRight: 15,
                    fontWeight: 500,
                    fontFamily: "Outfit",
                    fontSize: 16,
                  }}
                  type={"code"}
                  placeholder={"email"}
                  onChange={(event) => setEmailInput(event.target.value)}
                ></input>

                <Box height={30} />

                <LoadingButton
                  loading={loading}
                  disabled={hasBeenSent}
                  style={{
                    textTransform: "none",
                    height: 50,
                    width: "90%",
                    borderRadius: 20,
                    backgroundColor: "#E2D4FF",
                    borderWidth: 0,
                    color: "#795CB8",
                    fontFamily: "Outfit",
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                  onClick={() => doRequestPasswordReset()}
                >
                  {loading ? "" : hasBeenSent ? "Sent" : "Send email"}
                </LoadingButton>
              </Box>
            </motion.div>
          ) : passwordChangeComplete ? (
            <motion.div
              key="newPass"
              initial={{ y: "100%" }}
              animate={{ y: "0%" }}
              exit={{ y: "-100%" }}
              style={{
                display: "flex",
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
              transition={{ ease: "easeIn", duration: 0.3 }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    width: "90%",
                    textAlign: "left",
                    fontFamily: "Outfit",
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#515151",
                  }}
                >
                  {"Password has been successfully changed!"}
                </Typography>
              </Box>
            </motion.div>
          ) : (
            <motion.div
              key="newPass"
              initial={{ y: "100%" }}
              animate={{ y: "0%" }}
              exit={{ y: "-100%" }}
              style={{
                display: "flex",
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
              transition={{ ease: "easeIn", duration: 0.3 }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    width: "90%",
                    textAlign: "left",
                    fontFamily: "Outfit",
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#515151",
                  }}
                >
                  {"We have sent an email to " +
                    emailInput +
                    " with a verification code"}
                </Typography>
                <Box height={15} />
                <input
                  id={"inputID"}
                  style={{
                    height: 50,
                    width: "90%",
                    borderRadius: 20,
                    borderWidth: 1,
                    borderColor: "#CCC",
                    paddingLeft: 15,
                    paddingRight: 15,
                    fontWeight: 500,
                    fontFamily: "Outfit",
                    fontSize: 14,
                  }}
                  type={"code"}
                  placeholder={"code"}
                  onChange={(event) => setVerifyCode(event.target.value)}
                ></input>

                <Box height={30} />

                <Typography
                  style={{
                    width: "90%",
                    textAlign: "left",
                    fontFamily: "Outfit",
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#515151",
                  }}
                >
                  Enter your new password
                </Typography>
                <Box height={15} />
                <input
                  id={"inputID"}
                  style={{
                    height: 50,
                    width: "90%",
                    borderRadius: 20,
                    borderWidth: 1,
                    borderColor: "#CCC",
                    paddingLeft: 15,
                    paddingRight: 15,
                    fontWeight: 500,
                    fontFamily: "Outfit",
                    fontSize: 16,
                  }}
                  type={"password"}
                  placeholder={"password"}
                  onChange={(event) => setNewPassword(event.target.value)}
                ></input>

                <Box height={30} />

                <LoadingButton
                  loading={loading}
                  style={{
                    textTransform: "none",
                    height: 50,
                    width: "90%",
                    borderRadius: 20,
                    backgroundColor: "#E2D4FF",
                    borderWidth: 0,
                    color: "#795CB8",
                    fontFamily: "Outfit",
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                  onClick={() => setNewPasswordFunc()}
                >
                  {loading
                    ? ""
                    : newPasswordHasFailed
                    ? "Try Again"
                    : "Reset password"}
                </LoadingButton>
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  );
};
export default ForgotPasswordScreen;
