import * as React from "react";

function LandingContractIcon(props) {
  return (
    <svg
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_661_103)">
        <path
          d="M55.125 7.875H7.875C4.9875 7.875 2.625 10.2375 2.625 13.125V49.875C2.625 52.7625 4.9875 55.125 7.875 55.125H55.125C58.0125 55.125 60.375 52.7625 60.375 49.875V13.125C60.375 10.2375 58.0125 7.875 55.125 7.875ZM55.125 49.875H31.5V34.125H55.125V49.875Z"
          fill="#795CB8"
        />
      </g>
      <defs>
        <clipPath id="clip0_661_103">
          <rect width="63" height="63" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LandingContractIcon;
