import * as React from "react";

function LandingFastPaymentIcon(props) {
  return (
    <svg
      width="34"
      height="63"
      viewBox="0 0 34 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3026 27.65C10.721 25.585 8.28291 23.45 8.28291 20.125C8.28291 16.31 11.6562 13.65 17.3006 13.65C23.2456 13.65 25.4499 16.625 25.6503 21H33.0314C32.7976 14.98 29.2908 9.45 22.3104 7.665V0H12.2908V7.56C5.81139 9.03 0.601178 13.44 0.601178 20.195C0.601178 28.28 6.98035 32.305 16.2986 34.65C24.6483 36.75 26.3183 39.83 26.3183 43.085C26.3183 45.5 24.6817 49.35 17.3006 49.35C10.4204 49.35 7.71513 46.13 7.34774 42H0C0.400786 49.665 5.87819 53.97 12.2908 55.405V63H22.3104V55.475C28.8232 54.18 34 50.225 34 43.05C34 33.11 25.8841 29.715 18.3026 27.65Z"
        fill="#795CB8"
      />
    </svg>
  );
}

export default LandingFastPaymentIcon;
