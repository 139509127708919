import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from "prop-types";

const Line = styled(Box)`
  position: absolute;
  top: 0;
  left: 20px;
  width: ${({ length }) => length}px;
  height: 1px;
  margin-top: -1px;
  background-color: #fbfbfb;
  justify-content: center;
  align-items: center;
`;

const TitleContainer = styled(Box)`
  position: absolute;
  width: ${({ length }) => length}px;
  top: -8px;
  left: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const TitleText = styled(Typography)`
  font-family: Outfit;
  color: #afafaf;
  font-weight: 600;
  font-size: 10px;
`;

const InBorderTitle = ({ length, name }) => {
  return (
    <Box>
      <Line length={length} />
      <TitleContainer length={length}>
        <TitleText>{name}</TitleText>
      </TitleContainer>
    </Box>
  );
};

InBorderTitle.propTypes = {
  length: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default InBorderTitle;
