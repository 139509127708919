import * as React from "react";

function LandingDetailPayIcon(props) {
  return (
    <svg
      width="86"
      height="56"
      viewBox="0 0 86 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 24C2.68 24 0 26.68 0 30C0 33.32 2.68 36 6 36C9.32 36 12 33.32 12 30C12 26.68 9.32 24 6 24ZM6 0C2.68 0 0 2.68 0 6C0 9.32 2.68 12 6 12C9.32 12 12 9.32 12 6C12 2.68 9.32 0 6 0ZM6 48C2.68 48 0 50.72 0 54C0 57.28 2.72 60 6 60C9.28 60 12 57.28 12 54C12 50.72 9.32 48 6 48ZM18 54C18 56.2091 19.7909 58 22 58H82C84.2091 58 86 56.2091 86 54V54C86 51.7909 84.2091 50 82 50H22C19.7909 50 18 51.7909 18 54V54ZM18 30C18 32.2091 19.7909 34 22 34H82C84.2091 34 86 32.2091 86 30V30C86 27.7909 84.2091 26 82 26H22C19.7909 26 18 27.7909 18 30V30ZM22 2C19.7909 2 18 3.79086 18 6V6C18 8.20914 19.7909 10 22 10H82C84.2091 10 86 8.20914 86 6V6C86 3.79086 84.2091 2 82 2H22Z"
        fill="#795CB8"
      />
    </svg>
  );
}

export default LandingDetailPayIcon;
