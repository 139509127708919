import * as React from "react";

function SendIcon(props) {
  return (
    <svg
      width="22"
      height="30"
      viewBox="0 0 60 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0325954 64.8168C0.0358904 67.1233 2.53233 68.5635 4.53068 67.4117L57.9904 36.5992C59.993 35.445 59.993 32.555 57.9904 31.4008L4.53068 0.588287C2.53233 -0.5635 0.0358904 0.876669 0.0325954 3.18318L0.00350093 23.5493C0.00146365 24.9754 1.00365 26.2059 2.40067 26.4924L24.6733 31.0612C27.8695 31.7168 27.8695 36.2832 24.6733 36.9388L2.40067 41.5076C1.00366 41.7941 0.00146364 43.0246 0.00350092 44.4506L0.0325954 64.8168Z"
        fill={props.color}
      />
    </svg>
  );
}

export default SendIcon;
