import React, { useEffect, useState } from "react";
import { Auth, API, Storage } from "aws-amplify";
import { Box, Typography, Tab, Tabs, List } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DangerZone, Account, Support } from "src/components/SettingsTabs";
import MenuIcon from "./Images/menu.png";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { getUser } from "./graphql/queries";

const useStyles = makeStyles({
  customLabel: {
    fontFamily: "Outfit",
    fontSize: 16,
    width: 150,
    textTransform: "none",
  },
});

const Settings = ({
  currentUser,
  setCurrentUser,
  isMobile,
  showSideBar,
  setShowSideBar,
}) => {
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [value, setValue] = React.useState("one");

  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [username, setUsername] = useState("");

  const keyboardOpen = useDetectKeyboardOpen();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const findProfilePic = async () => {
    const file = await currentUser.get("ProfilePic");
    if (file) setProfilePic(file._url);
  };

  useEffect(() => {
    const doStuff = async () => {
      const url = await Storage.get(profilePic.key, { key: profilePic.bucket });
      console.log(url);

      setProfilePicUrl(url);
    };

    if (profilePic) {
      doStuff();
    }
  }, [profilePic]);
  // authMode: "AMAZON_COGNITO_USER_POOLS",

  const getCurrentUser = async () => {
    console.log("gettingUser");
    const curr = await Auth.currentAuthenticatedUser();
    if (curr) {
      API.graphql({
        query: getUser,
        variables: { id: curr.attributes.sub },
      })
        .then((response) => {
          const target = response.data.getUser;
          console.log(target);
          setEmail(target.email);
          setUsername(target.username);
          setDisplayName(target.displayName);
          setProfilePic(target.profilePic);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const classes = useStyles();

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        minWidth: 200,
        overflow: "hidden",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {isMobile && (
          <img
            onClick={() => setShowSideBar(!showSideBar)}
            style={{
              width: 35,
              height: 35,
            }}
            src={MenuIcon ?? ""}
            alt={""}
            loading="lazy"
          />
        )}
        {isMobile && <Box width={20} />}
        {isMobile && (
          <Typography
            style={{
              cursor: "default",
              fontWeight: 600,
              fontSize: 25,
              fontFamily: "Outfit",
              color: "#000",
              lineHeight: "110%",
            }}
          >
            Settings
          </Typography>
        )}
        {isMobile && <Box flex={1} />}
        {profilePicUrl ? (
          <img
            style={{
              width: isMobile ? 35 : 75,
              height: isMobile ? 35 : 75,
              borderRadius: 1000,
            }}
            src={profilePicUrl ?? ""}
            alt={""}
            loading="lazy"
          />
        ) : (
          <Box
            style={{
              width: isMobile ? 35 : 75,
              height: isMobile ? 35 : 75,
              borderRadius: 1000,
              backgroundColor: "#E2D4FF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              align="center"
              fontWeight={600}
              color={"#795CB8"}
              fontSize={isMobile ? 15 : 30}
              fontFamily={"Nunito"}
            >
              {username.charAt(0).toUpperCase()}
            </Typography>
          </Box>
        )}
        <Box width={10} />

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: isMobile ? 35 : 75,
          }}
        >
          <Typography
            fontFamily={"Outfit"}
            fontSize={isMobile ? 18 : 24}
            fontWeight={500}
          >
            {username}
          </Typography>
          <Typography
            marginTop={-1}
            fontFamily={"Outfit"}
            fontSize={isMobile ? 12 : 16}
            color={"#3F3F3F"}
          >
            Advertiser
          </Typography>
        </Box>
      </Box>
      <Box height={20} />
      {!isMobile && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            position: "relative",
          }}
        >
          <Box
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              height: 2,
              backgroundColor: "#CCC",
            }}
          />
          <Tabs
            textColor="inherit"
            fontFamily="Outfit"
            TabIndicatorProps={{
              style: { background: value === "four" ? "red" : "#4D4D4D" },
            }}
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab
              className={classes.customLabel}
              value="one"
              label="Account"
              wrapped
            />
            {/* <Tab className={classes.customLabel} value="two" label="Password" /> */}
            <Tab className={classes.customLabel} value="two" label="Support" />
            <Tab
              className={classes.customLabel}
              value="three"
              label="Danger Zone"
            />
          </Tabs>
        </Box>
      )}
      {!isMobile && (
        <Box>
          {value === "one" && (
            <Account
              findProfilePic={findProfilePic}
              isMobile={isMobile}
              displayName={displayName}
              email={email}
              getCurrentUser={getCurrentUser}
            />
          )}
          {/* {value === "two" && <Password email={email} isMobile={isMobile} />} */}
          {value === "two" && <Support isMobile={isMobile} />}
          {value === "three" && (
            <DangerZone cid={"PLACEHOLDER"} setCurrentUser={setCurrentUser} />
          )}
        </Box>
      )}
      {currentUser && isMobile && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          }}
        >
          <List style={{ height: 0, flexGrow: 1, overflowY: "auto" }}>
            {!keyboardOpen && (
              <Box>
                <Box
                  height={40}
                  width={"100%"}
                  style={{
                    borderRadius: 15,
                    borderColor: "#4A587C",
                    backgroundColor: "#F1F2F5",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{
                      cursor: "default",
                      fontWeight: 600,
                      fontSize: 18,
                      fontFamily: "Outfit",
                      color: "#000",
                      textAlign: "center",
                      lineHeight: "100%",
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                  >
                    Account
                  </Typography>
                </Box>
                <Account
                  findProfilePic={findProfilePic}
                  isMobile={isMobile}
                  email={email}
                  displayName={displayName}
                  getCurrentUser={getCurrentUser}
                />
                <Box height={20} />
                {/* <Box
                  height={40}
                  width={"100%"}
                  style={{
                    borderRadius: 15,
                    borderColor: "#4A587C",
                    backgroundColor: "#F1F2F5",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{
                      cursor: "default",
                      fontWeight: 600,
                      fontSize: 18,
                      fontFamily: "Outfit",
                      color: "#000",
                      textAlign: "center",
                      lineHeight: "100%",
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                  >
                    Password
                  </Typography>
                </Box>
                <Password currentUser={currentUser} isMobile={isMobile} />
                <Box height={20} /> */}
                <Box
                  height={40}
                  width={"100%"}
                  style={{
                    borderRadius: 15,
                    borderColor: "#4A587C",
                    backgroundColor: "#F1F2F5",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{
                      cursor: "default",
                      fontWeight: 600,
                      fontSize: 18,
                      fontFamily: "Outfit",
                      color: "#000",
                      textAlign: "center",
                      lineHeight: "100%",
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                  >
                    Support
                  </Typography>
                </Box>
                <Support currentUser={currentUser} isMobile={isMobile} />
                <Box height={20} />
                <Box
                  height={40}
                  width={"100%"}
                  style={{
                    borderRadius: 15,
                    borderColor: "#4A587C",
                    backgroundColor: "#000",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{
                      cursor: "default",
                      fontWeight: 600,
                      fontSize: 18,
                      fontFamily: "Outfit",
                      color: "#FFF",
                      textAlign: "center",
                      lineHeight: "100%",
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                  >
                    Danger Zone
                  </Typography>
                </Box>
              </Box>
            )}
            <DangerZone
              cid={currentUser.id}
              setCurrentUser={setCurrentUser}
              isMobile={isMobile}
            />
            <Box height={50} />
          </List>
        </Box>
      )}
      <Box height={23} />
      <style>{`
        /* Hide scrollbar for all elements with the default WebKit scrollbar style */
        ::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </Box>
  );
};

export default Settings;
