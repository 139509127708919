import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import LogoNoTextSVG from "src/Images/LogoNoText";
import LogoNoTextColor from "src/Images/LogoNoTextColor";
import ProfferaText from "src/Images/ProfferaText";

const NavigationScreen = ({ setLocation, isMobile }) => {
  const history = useHistory();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: isMobile ? "100vh" : "100%",
        width: isMobile ? "100vw" : "100%",
      }}
    >
      {isMobile ? (
        <Box
          style={{
            backgroundColor: "#e9ecf0",
            width: "100%",
            height: "20%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              position: "absolute",
              width: "100%",
              height: "18%",
              overflow: "hidden",
            }}
          >
            <LogoNoTextSVG
              width={500}
              height={500}
              viewBox={"50 440 476 227"}
              style={{ position: "absolute" }}
            />
          </Box>
          <Box flex={1} />
          <Box
            style={{
              zIndex: 2,
              height: 170,
              width: 170,
              backgroundColor: isMobile ? "#FCFCFC" : "#DED9ED",
              borderRadius: 1000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: -85,
            }}
          >
            <LogoNoTextColor width={120} height={120} />
          </Box>
        </Box>
      ) : (
        <Box
          style={{
            marginTop: 40,
            zIndex: 2,
            height: 170,
            width: 170,
            backgroundColor: isMobile ? "#FCFCFC" : "#DED9ED",
            borderRadius: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LogoNoTextColor width={120} height={120} />
        </Box>
      )}
      <Box height={isMobile ? 85 : 30} />
      <ProfferaText height={100} width={240} />
      <Box height={isMobile ? 80 : 60} />
      <Box
        sx={{
          backgroundColor: "#E2D4FF",
          color: "#795CB8",
          "&:hover": {
            backgroundColor: "#795CB8",
            color: "white",
          },
        }}
        style={{
          height: 50,
          width: isMobile ? "90%" : "65%",
          borderRadius: 20,
          borderWidth: 0,
          fontFamily: "Outfit",
          fontWeight: 500,
          fontSize: 16,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="button"
        onClick={() => setLocation("Login")}
      >
        {"Login"}
      </Box>
      <Box height={15} />
      <Box
        sx={{
          backgroundColor: "#1A3A63",
          color: "white",
          fontWeight: 500,
          "&:hover": {
            borderWidth: 2,
            fontWeight: 600,
            backgroundColor: "white",
            color: "#1A3A63",
          },
        }}
        style={{
          height: 50,
          width: isMobile ? "90%" : "65%",
          borderRadius: 20,
          borderColor: "#1A3A63",
          fontFamily: "Outfit",
          fontSize: 16,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="button"
        onClick={() => setLocation("Code")}
      >
        {"View offer with code"}
      </Box>
      <Box height={15} />
      <Box
        sx={{
          backgroundColor: "#FFF",
          color: "#1A3A63",
          borderWidth: 1,
          "&:hover": {
            borderWidth: 2,
            backgroundColor: "#D1D8E0",
          },
        }}
        style={{
          height: 50,
          width: isMobile ? "90%" : "65%",
          borderRadius: 20,
          borderColor: "#1A3A63",
          fontFamily: "Outfit",
          fontWeight: 500,
          fontSize: 16,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="button"
        onClick={() => history.push("/landing")}
      >
        {"Learn more"}
      </Box>
      <Box height={25} />
      <Box
        sx={{
          "&:hover": {
            backgroundColor: "#F1F1F1",
          },
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 30,
          width: isMobile ? "45%" : "45%",
          borderRadius: 20,
        }}
      >
        <Typography
          onClick={() => setLocation("Signup")}
          style={{
            cursor: "pointer",
            fontFamily: "Outfit",
            fontWeight: 500,
            color: "#848484",
          }}
        >
          Create an account?
        </Typography>
      </Box>
    </Box>
  );
};
export default NavigationScreen;
