import { Box, Typography } from "@mui/material";

const MarkableDeliverableItem = ({
  index,
  value,
  updateDeliverable,
  isDisabled,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        flex: 1,
        height: 40,
        width: "100%",
        borderRadius: 15,
        borderColor: "#1A3A63",
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 11,
        paddingBottom: 11,
        backgroundColor: value.fulfilled ? "#B0BCCB" : "#F3F5F7",
      }}
    >
      <Typography
        value={value}
        style={{ flex: 1, fontSize: 14, fontFamily: "Outfit", fontWeight: 600 }}
      >
        {value.text}
      </Typography>
      {value === "" ? (
        <Box width={36} />
      ) : (
        <Box
          onClick={() => updateDeliverable(index)}
          border={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: 23,
            paddingRight: 23,
            height: 30,
            borderRadius: 15,
            backgroundColor: value.fulfilled ? "rgba(255,255,255,0.1)" : "#FFF",
            borderColor: "#1A3A63",
          }}
        >
          <Typography
            style={{
              fontSize: 14,
              fontFamily: "Outfit",
              fontWeight: 500,
              color: value.fulfilled ? "white" : "#1A3A63",
            }}
          >
            {isDisabled
              ? "Awaiting fulfillment"
              : value.fulfilled
              ? "Undo"
              : "Mark fulfilled"}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default MarkableDeliverableItem;
